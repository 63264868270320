import React, { Fragment, Label, useContext, useEffect } from "react";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import * as ROUTES from "../../constants/routes";

import { DatePicker } from "@material-ui/pickers";
import { useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
import { object, string, array, date } from "yup";
import Moment from "moment";
import { Backdrop, Box, Button, Fade, Grid, Modal, Typography } from "@material-ui/core";
// import { Close, ExpandMore, FiberManualRecord, GetApp, InsertDriveFile, Person, Visibility } from "@material-ui/icons";
// import { ObtenerMacroprocesos } from "../../services/request/mapaProcesos/requestMacroproceso";
// import { ObtenerServicios } from "../../services/request/mapaProcesos/requestServicio";
// import { ObtenerFicha, ObtenerFichas } from "../../services/request/mapaProcesos/requestFicha";
// import { ObtenerInvolucradosInternos } from "../../services/request/mapaProcesos/requestInvolucradoInterno";
// import { ObtenerInvolucradosExternos } from "../../services/request/mapaProcesos/requestInvolucradoExterno";
// import { ObtenerProcAsocs } from "../../services/request/mapaProcesos/requestProcedimientoAsociado";
// import { ObtenerDocsAsociados } from "../../services/request/mapaProcesos/requestDocumentosAsociados";
// import { ObtenerProveedores } from "../../services/request/mapaProcesos/requestProveedoresInformacion";
// import { ObtenerEntradas } from "../../services/request/mapaProcesos/requestEntrada";
// import { ObtenerKPIs } from "../../services/request/mapaProcesos/requestKPI";
// import { ObtenerClientes } from "../../services/request/mapaProcesos/requestClientes";
// import { ObtenerEntregables } from "../../services/request/mapaProcesos/requestEntregables";
// import ProcesoAsociado from "./procesoAsociado";


import { MainContext } from "../../App";
import ListadoActualizacion from "./listadoActualizado";


function Inicio(props) {

  const {
    handleFiltroIso,
    // setMostrarBuscar,
    // handleBuscarBox,
    // text,
  } = props;

  const [openDialog, setOpenDialog] = React.useState(false);
  const notistack = useSnackbar();
  const [idProceso, setIdProceso] = React.useState(null);
  const { usuarioSesion, permisos, setFiltroIso, setMostrarBuscar } = useContext(MainContext);
  const [openModalVideo, setOpenModalVideo] = React.useState(false);
  const [videoRuta, setVideoRuta] = React.useState(null);



  const flecha = "https://storage.googleapis.com/bpm-cyd/Extras/flecha.png";
  const flechaRecta = "https://storage.googleapis.com/bpm-cyd/Extras/arrow-png-icon.png";

  const videoSGI =
    "https://storage.googleapis.com/bpm-cyd/site-files/VideoSGIThumb.jpg";



  // const { data: ficha, error: errorFicha, mutate: mutateFicha } = useSWR("ficha", (key) => ObtenerFicha(idFicha), { revalidateOnFocus: false });
  // const { data: fichas, error: errorFichas, mutate: mutateFichas } = useSWR("fichas", (key) => ObtenerFichas(), { revalidateOnFocus: false });

  // const { data: involucradosInternos, error: errorInvolucradosInternos, mutate: mutateInvolucradosInternos } = useSWR("involucrados_internos", (key) => ObtenerInvolucradosInternos(), { revalidateOnFocus: false });
  // const { data: involucradosExternos, error: errorInvoludracosExternos, mutate: mutateInvolucradosExternos } = useSWR("involucrados_externos", (key) => ObtenerInvolucradosExternos(), { revalidateOnFocus: false });
  // const { data: procedimientosAsociados, error: errorProcedimientosAsociados, mutate: mutateProcedimientosAsociados } = useSWR("procedimientos_asociados", (key) => ObtenerProcAsocs(), { revalidateOnFocus: false });
  // const { data: documentosAsociados, error: errorDocumentosAsociados, mutate: mutateDocumentosAsociados } = useSWR("documentos_asociados", (key) => ObtenerDocsAsociados(), { revalidateOnFocus: false });
  // const { data: proveedoresInformacion, error: errorProveedoresInformacion, mutate: mutateProveedoresInformacion } = useSWR("proveedores_informacion", (key) => ObtenerProveedores(), { revalidateOnFocus: false });
  // const { data: entradas, error: errorEntradas, mutate: mutateEntradas } = useSWR("entradas", (key) => ObtenerEntradas(), { revalidateOnFocus: false });
  // const { data: kpi, error: errorKpi, mutate: mutateKpi } = useSWR("kpi", (key) => ObtenerKPIs(), { revalidateOnFocus: false });
  // const { data: clientes, error: errorClientes, mutate: mutateClientes } = useSWR("clientes", (key) => ObtenerClientes(), { revalidateOnFocus: false });
  // const { data: entregables, error: errorEntregables, mutate: mutateEntregables } = useSWR("entregables", (key) => ObtenerEntregables(), { revalidateOnFocus: false });

  const handleClickFiltroIso = (value) => {
    setFiltroIso(value);
  }

  const handleCloseModalVideo = () => {
    setOpenModalVideo(false);
  };

  const handleVideoBienvenida = () => {
    setVideoRuta(
      "https://drive.google.com/file/d/11aFxPO0T3M0v6e9wAcv4h9acMceped5D/preview"
    );
    handleOpenModalVideo();
  };

  const handleOpenModalVideo = () => {
    setOpenModalVideo(true);
  };

  useEffect(() => {

    // <ListItem
    //               button
    //               component={Link}
    //               to={Routes.BENEFICIOS}
    //               selected={itemSelected === 4}
    //               onClick={() => {
    //                 setItemSelected(4);
    //                 analytics.logEvent("Menú_Lateral_Beneficios", {
    //                   usuarioCorreo: claimUser.email,
    //                   usuarioNombre: claimUser.name,
    //                   usuarioContrato: claimUser.proyecto,
    //                 });
    //               }}
    //             ></ListItem>
    // setMostrarBuscar(true);



  }, []);

  return (
    <Fragment  >
      <Grid container spacing={2} style={{ paddingTop: "10px" }} >

        {/* <Grid xs={12}
          style={{ textAlign: "center", marginBottom: "30px" }}
        >
          <Typography variant="h5">
            Te damos la bienvenida al sitio web del sistema de gestión integrado de CyD Ingeniería

          </Typography>
        </Grid> */}
        <Grid xs={12} style={{ marginBottom: "50px" }}>
          <Box style={{
            width: "100%",
            height: "100%",
            backgroundImage: `url(https://storage.googleapis.com/bpm-cyd/Extras/Sgi.png)`,
            backgroundSize: "cover",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <Grid container >
              <Grid xs={5} style={{ textAlign: "center" }}>
                <Box display="flex" 
                style={{
                  width: "100%",
                  height: "100%",
                  alignItems: "center",
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <Box>
                    <Typography variant="h3">
                      Bienvenido al
                    </Typography>
                    {/* <br /> */}
                    <Typography variant="h2">
                      <strong>Sistema de Gestión Integrado</strong>
                    </Typography>
                    <img src="https://storage.googleapis.com/bpm-cyd/Extras/Logo%20CyD%20Ingenier%C3%ADa%202021%20(Sin%20fondo).png" style={{height: "130px"}}/>
                    {/* <ListadoActualizacion /> */}
                  </Box>
                </Box>
              </Grid>
              <Grid xs={7}>
                <Grid container style={{ marginTop: "20px" }}>
                  <Grid xs={4}>
                    <Grid container>
                      <Grid xs={12} style={{ padding: "20px", textAlign: "right" }}>
                        <Button style={{ height: "80px", backgroundColor: "#1a237e", textAlign: "center", width: "100%" }}
                          // button
                          component={Link}
                          to={ROUTES.MAPAS}
                          onClick={(event) => {
                            handleClickFiltroIso({ nombre: "Sistemas de Gestión de Calidad" })

                          }}

                        >
                          <Typography variant="h6" style={{ color: "white" }}>
                            Documentos de Calidad
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid xs={12} style={{ textAlign: "right" }}>
                        <Box width="100">

                          <img src={flechaRecta} style={{
                            height: "70px",
                            transform: "rotate(0.125turn) ",
                          }} ></img>
                        </Box>

                      </Grid>
                    </Grid>

                  </Grid>
                  <Grid xs={4}>
                    <Grid container>
                      <Grid xs={12} style={{ padding: "20px", textAlign: "center" }}>
                        {/* <Paper style={{ height: "100px", backgroundColor: "#4caf50" }}> */}
                        <Button style={{ height: "80px", backgroundColor: "#4caf50", textAlign: "center", width: "100%" }}
                          component={Link}
                          to={ROUTES.MAPAS}
                          onClick={(event) => {
                            handleClickFiltroIso({ nombre: "Medio Ambiente" })

                          }}
                        >
                          <Typography variant="h6" style={{ color: "white" }}>
                            Documentos de Medio Ambiente
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid xs={12} style={{ textAlign: "center" }}>
                        <Box width="100">

                          <img src={flechaRecta} style={{
                            height: "70px",
                            transform: "rotate(0.25turn) ",
                          }} ></img>
                        </Box>

                      </Grid>
                    </Grid>

                  </Grid>
                  <Grid xs={4}>
                    <Grid container>
                      <Grid xs={12} style={{ padding: "20px", textAlign: "left" }}>
                        {/* <Paper style={{ height: "100px", backgroundColor: "#ff9800" }}> */}
                        <Button style={{ height: "80px", backgroundColor: "#ff9800", textAlign: "center", width: "100%" }}
                          component={Link}
                          to={ROUTES.MAPAS}
                          onClick={(event) => {
                            handleClickFiltroIso({ nombre: "Seguridad y Salud en el Trabajo" })

                          }}
                        >
                          <Typography variant="h6" style={{ color: "white" }}>
                            Documentos SST
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid xs={12} style={{ textAlign: "left" }}>
                        <Box width="100">

                          <img src={flechaRecta} style={{
                            height: "70px",
                            transform: "rotate(0.375turn) ",
                          }} ></img>
                        </Box>

                      </Grid>
                    </Grid>

                  </Grid>
                  <Grid xs={12} style={{ padding: "20px", textAlign: "center", marginBottom: "40px" }}>
                    <Button style={{ height: "80px", backgroundColor: "#731f1f", textAlign: "center", width: "75%" }}
                      component={Link}
                      to={ROUTES.MAPAS}
                      onClick={(event) => {
                        handleClickFiltroIso({ nombre: "Liderazgo" })

                      }}
                    >
                      <Typography variant="h5" style={{ color: "white" }}>
                        Documentos Transversales SGI
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Box>
        </Grid>


        <Grid container style={{ flexGrow: 1 }}>
          <Grid item xs={12} md={6}>
            <Grid
              direction="row"
              justify="center"
              align="center"
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Box display="flex" textAlign="center" justifyContent="center">
                <img
                  src={videoSGI}
                  alt="Presentación Comite de Cuidado"
                  width="90%"
                  style={{ border: "groove", cursor: "pointer" }}
                  onClick={handleVideoBienvenida}
                />
              </Box>
              <Box style={{ marginBottom: "2rem" }}>
                <Typography
                  gutterBottom
                  align="center"
                  variant="h5"
                  style={{ color: "#731f1f" }}
                >
                  <strong>Invitación de
                    Gerencia General</strong>
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} container>
            <Grid
              direction="row"
              justify="center"
              align="center"
              // spacing={5}
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Box display="flex" textAlign="center" justifyContent="center" style={{ height: "85%" }}>
                <iframe
                  src="https://drive.google.com/file/d/13Ln6srF5k7xDZDohy_kCcvAFnO0WgBFt/preview"
                  frameborder="0"
                  width="90%"
                  height="100%"
                  allowfullscreen="true"
                  mozallowfullscreen="true"
                  webkitallowfullscreen="true"
                ></iframe>
              </Box>
              <Box style={{ marginBottom: "2rem" }}>
                <Typography
                  gutterBottom
                  align="center"
                  variant="h5"
                  style={{ color: "#731f1f" }}
                >
                  <strong>Presentación SGI</strong>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} style={{ textAlign: "center", marginBottom: "10px" }}>
          <Button
            component="a"
            href="https://sites.google.com/cydingenieria.cl/sgi/certificados-normas"
            target="_blank"
            variant="contained" color="primary" style={{ width: "70%" }}>
            Certificados SGI
          </Button>
        </Grid>
        <Grid xs={12} style={{ textAlign: "center", marginBottom: "10px" }}>
          <Button
            component="a"
            href="https://sites.google.com/cydingenieria.cl/sgi/pol%C3%ADticas-y-manuales"
            target="_blank"
            variant="contained" color="primary" style={{ width: "70%" }}>
            Manuales y Normas SGI
          </Button>
        </Grid>
        <Grid xs={12} style={{ textAlign: "center", marginBottom: "10px" }}>
          <Button
            component="a"
            href="https://drive.google.com/file/d/1iO8Z-pW7JKr68fQTc81OlxTKELLvLGdO/view?usp=sharing"
            target="_blank"
            variant="contained" color="primary" style={{ width: "70%" }}>
            Plan Anual de Auditorías
          </Button>
        </Grid>
        <Grid xs={12} style={{ textAlign: "center", marginBottom: "10px" }}>
          <Button
            component="a"
            href="https://sites.google.com/cydingenieria.cl/sgi/riohs"
            target="_blank"
            variant="contained" color="primary" style={{ width: "70%" }}>
            RIOHS
          </Button>
        </Grid>
        <Grid xs={12} style={{ textAlign: "center", marginBottom: "10px" }}>
          <Button
            component="a"
            href="https://sites.google.com/cydingenieria.cl/sgi/incidentes-significativos"
            target="_blank"
            variant="contained" color="primary" style={{ width: "70%" }}>
            Incidentes Significativos
          </Button>
        </Grid>
        <Grid xs={12} style={{ textAlign: "center", marginBottom: "10px" }}>
          <Button
            component="a"
            href="https://sites.google.com/cydingenieria.cl/sgi/cphs"
            target="_blank"
            variant="contained" color="primary" style={{ width: "70%" }}>
            CPHS
          </Button>
        </Grid>
        <Grid xs={12} style={{ textAlign: "center", marginBottom: "50px" }}>
          <Button
            component="a"
            href="https://drive.google.com/file/d/1JvXJ_QfTCO2zOJ2PeMDGbo8F4L0JnM4Y/view"
            target="_blank"
            variant="contained" color="primary" style={{ width: "70%" }}>
            Programa SST y MA
          </Button>
        </Grid>

      </Grid>

      <Modal
        align="center"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        // className={classes.modalStyle2}
        open={openModalVideo}
        // overflow="scroll"
        onClose={handleCloseModalVideo}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalVideo}>
          <div
          // className={classes.paper}
          // style={{ minHeight: "-webkit-fill-available" }}
          >
            <Box style={{ alignSelf: "center", marginTop: "140px" }}>
              {/* <Box style={{paddingTop:"4rem"}}> */}

              <iframe
                style={{ align: "middle", alignSelf: "center" }}
                src={videoRuta}
                // position="fixed"
                frameborder="0"
                width="900px"
                height="500px"
                allowfullscreen="true"
                mozallowfullscreen="true"
                webkitallowfullscreen="true"
              ></iframe>

              {/* </Box> */}
            </Box>
            <Box
              zIndex="tooltip"
              style={{
                position: "fixed",
                width: "20%",
                left: "65%",
                top: "80%",
                height: "3rem",
                paddingBottom: "4rem",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="Primary"
                onClick={handleCloseModalVideo}
                style={{
                  fontSize: "1rem",
                  color: "#731f1f",
                  backgroundColor: "white",
                }}
              >
                <strong>Cerrar</strong>
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>

    </Fragment >
  );
}

export default Inicio;