import React from "react";
import Page from "./page";

function Index(props) {

  return (
    <Page />
  );
}

export default Index;