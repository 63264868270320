import { BACKEND } from "../../../constants/urls";
import Axios from "axios";

/**
 * Método encargado de obtener un perfil, por su ID.
 * @param {*} proveedorID ID del perfil.
 */
export async function ObtenerProveedor(proveedorID) {
  try {
    let url = `${BACKEND}/proveedor-informacion/${proveedorID}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todos los perfiles.
 */
export async function ObtenerProveedores() {
  try {
    let url = `${BACKEND}/proveedor-informacion`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de agregar un nuevo perfil.
 * @param {*} proveedor Datos del perfil.
 */
export async function AgregarProveedor(proveedor) {
  try {
    let url = `${BACKEND}/proveedor-informacion`;
    let response = await Axios.post(url, proveedor);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de actualizar un perfil.
 * @param {*} proveedor Datos actualizados del perfil.
 */
export async function ActualizarProveedor(proveedor) {
  try {
    let url = `${BACKEND}/proveedor-informacion/${proveedor._id}`;
    let response = await Axios.put(url, proveedor);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de eliminar un perfil.
 * @param {*} proveedor Datos del perfil.
 */
export async function EliminarProveedor(proveedor) {
  try {
      
    let url = `${BACKEND}/proveedor-informacion/${proveedor._id}`;
    console.log(url);
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}