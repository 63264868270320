import React, { Fragment, useContext, useEffect } from "react";
import useSWR from "swr";
import Moment from "moment";
import _ from "lodash";

import { Avatar, Box, Card, CardContent, Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import {
  Cake as CakeIcon,
  WatchLater as WatchLaterIcon,
  Restore as RestoreIcon,
  NoteAdd as NoteAddIcon,
} from "@material-ui/icons";
import { ObtenerFichas } from "../../services/request/mapaProcesos/requestFicha";
import { MainContext } from "../../App";





function CumpleanosCards(props) {

  const {
    // handleSearchServicio,
    handleBuscarBox,
    text,
    handleClickSearchClick

  } = props;
  // const { searchServicio, filtroIso, setFiltroIso, setMostrarBuscar,handleSearchServicio } = useContext(MainContext);

  const { data: fichas, error: errorFichas, mutate: mutateFichas } = useSWR("fichas", (key) => ObtenerFichas(), { revalidateOnFocus: false });
  const [valorSearch, setValorSearch] = React.useState(null);
  // const { data } = UseSWR("cumpleanos", (key) => ObtenerCumpleanos(), {
  // revalidateOnFocus: false,
  // });
  const { searchServicio, filtroIso, setFiltroIso, setMostrarBuscar } = useContext(MainContext);

  const handleClickSearch_ = (value) => {
    setMostrarBuscar(value);
  };


  useEffect(() => {

    // const sortedArray = array.sort((a, b) => Moment(a.fechas, "DD-MM").unix() - Moment(b.fechas, "DD-MM").unix());

    // console.log(fichas && Array.from(fichas.data).sort((a, b) => Moment(b.fecha_actualizacion, "DD-MM").unix() - Moment(a.fecha_actualizacion, "DD-MM").unix()));
    // console.log(fichas && fichas.data.sort((a,b) => new Moment(a.fecha_actualizacion).format('YYYYMMDD HH:mm') - new Moment(b.fecha_actualizacion).format('YYYYMMDD HH:mm')));
    console.log(fichas && fichas.data.sort((a, b) => Moment(b.fecha_actualizacion).valueOf() - Moment(a.fecha_actualizacion).valueOf()));


  }, [fichas])

  const classes = useStyles();

  return (
    <Fragment>
      {/* TITULO E ICONO */}
      <Grid
        container
        style={{

          // marginTop: "1rem",
          backgroundColor: "white",
          borderTopRightRadius: "20px",
          borderTopLeftRadius: "20px",
          // paddingTop: 8,
          // paddingBottom:8
        }}
      >
        <Grid item xs={10} style={{ alignSelf: "center" }}>
          <Typography
            variant="subtitle2"
            align="center"
            style={{ fontWeight: "bold", paddingLeft: 15, }}
          >
            Últimas Actualizaciones
          </Typography>
        </Grid>
        <Grid item xs={2} style={{ width: "30%" }}>
          <WatchLaterIcon style={{ fontSize: 30, color: "#731f1f" }} />
        </Grid>
        <Grid xs={12}>
          <Divider variant="middle" />
        </Grid>
      </Grid>

      {/* LISTADO */}
      <div className={classes.root}>

        {fichas &&
          Array.from(fichas.data).sort((a, b) => Moment(b.fecha_actualizacion).valueOf() - Moment(a.fecha_actualizacion).valueOf()).slice(0, 10).map((d, index) => (
            <Card elevation={0} className={classes.card}>
              <CardContent style={{ padding: "5px" }}>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                  <Grid item md={2} xs={2} sm={2}>
                    {Moment(d.fecha_actualizacion) > Moment(d.fecha_creacion) ? <RestoreIcon /> : <NoteAddIcon />}
                  </Grid>
                  <Grid container item xs={10} sm={10} md={10} direction="column" justify="flex-start" alignItems="flex-start" style={{ paddingLeft: "10px" }}>
                    <Box onClick={() => {
                      handleClickSearch_(d.codigo.toLocaleLowerCase())
                    }}>
                      <Typography onClick={() => {
                        console.log(d.codigo.toLocaleLowerCase());
                        handleClickSearch_(d.codigo.toLocaleLowerCase());
                      }} className={classes.typography_nombre}>{`${d.nombre}`}</Typography>
                      <Typography className={classes.typography_email}>{d.codigo}</Typography>
                      <Typography className={classes.typography_cargo}>{Moment(d.fecha_actualizacion).format("DD/MM/YYYY HH:mm")} </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            // <UserCard key={`userCard-${index}`} persona={d.persona} />
          ))}

      </div>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "20rem",
    overflowY: "scroll",
    backgroundColor: "#FFFFFF",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    paddingBottom: "20px",
  },
  fecha: {
    marginLeft: theme.spacing(2),
    color: "#731f1f",
  },
  sin_cumpleañeros: {
    marginLeft: theme.spacing(3),
    color: "#731f1f",
  },
  card: {
    flexGrow: 1,
    margin: theme.spacing(1),
    backgroundColor: "#FFFFFF",
  },
  avatar_size: {
    width: "40px",
    height: "40px",
  },
  typography_nombre: {
    fontSize: "11px",

    fontWeight: "bold",
    color: "#731f1f"
  },
  typography_email: {
    fontSize: "11px",
    color: "black"
  },
  typography_cargo: {
    fontSize: "11px",
    color: "#black"
  }
}));

export default CumpleanosCards;
