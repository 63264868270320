import { BACKEND } from "../../../constants/urls";
import Axios from "axios";

/**
 * Método encargado de obtener un perfil, por su ID.
 * @param {*} perfilID ID del perfil.
 */
export async function ObtenerKPI(kpiID) {
  try {
    let url = `${BACKEND}/kpi/${kpiID}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todos los perfiles.
 */
export async function ObtenerKPIs() {
  try {
    let url = `${BACKEND}/kpi`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de agregar un nuevo perfil.
 * @param {*} perfil Datos del perfil.
 */
export async function AgregarKPI(kpi) {
  try {
    let url = `${BACKEND}/kpi`;
    let response = await Axios.post(url, kpi);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de actualizar un perfil.
 * @param {*} perfil Datos actualizados del perfil.
 */
export async function ActualizarKPI(kpi) {
  try {
    let url = `${BACKEND}/kpi/${kpi._id}`;
    let response = await Axios.put(url, kpi);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de eliminar un perfil.
 * @param {*} perfil Datos del perfil.
 */
export async function EliminarKPI(kpi) {
  try {
      
    let url = `${BACKEND}/kpi/${kpi._id}`;
    console.log(url);
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}