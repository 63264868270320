import { BACKEND } from "../../../constants/urls";
import Axios from "axios";

/**
 * Método encargado de obtener un usuario plataforma, por su ID.
 * @param {*} usuarioPlataformaID ID del usuario plataforma.
 */
export async function ObtenerUsuarioPlataforma(usuarioPlataformaID) {
  try {
    let url = `${BACKEND}/usuarios-plataforma/${usuarioPlataformaID}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todos los usuarios plataforma.
 */
export async function ObtenerUsuariosPlataforma() {
  try {
    let url = `${BACKEND}/usuarios-plataforma`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de agregar un nuevo usuario plataforma.
 * @param {*} usuarioPlataforma Datos del usuario plataforma.
 */
export async function AgregarUsuarioPlataforma(usuarioPlataforma) {
  try {
    let url = `${BACKEND}/usuarios-plataforma`;
    let response = await Axios.post(url, usuarioPlataforma);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de actualizar un usuario plataforma.
 * @param {*} usuarioPlataforma Datos actualizados del usuario plataforma.
 */
export async function ActualizarUsuarioPlataforma(usuarioPlataforma) {
  try {
    let url = `${BACKEND}/usuarios-plataforma/${usuarioPlataforma._id}`;
    let response = await Axios.put(url, usuarioPlataforma);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de eliminar un usuario plataforma.
 * @param {*} usuarioPlataforma Datos del usuario plataforma.
 */
export async function EliminarUsuarioPlataforma(usuarioPlataforma) {
  try {
    let url = `${BACKEND}/usuarios-plataforma/${usuarioPlataforma._id}`;
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}