import React, { Fragment } from "react";
import { Box, Divider, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { FiberManualRecord, Label as LabelIcon } from "@material-ui/icons";


/**
 * Componente
 * @param {*} text Texto descriptivo del proceso.
 * @returns 
 */
export default function TituloProceso(props) {
  const {
    text,
  } = props;

  const classes = useStyle();

  return (
    <Fragment>
      <Paper style={{ backgroundColor: "lightgray", padding: "20px" }}>
        <Grid container>
          <Grid xs={12} style={{ marginBottom: "20px" }}>
            <Box style={{ width: "100%", textAlign: "center" }}>
              <Typography variant="h7" fontWeight="bold">
                Simbología
              </Typography>
            </Box>

          </Grid>
          <Grid xs={12} style={{ marginBottom: "15px" }}>
            <Box
              style={{ height: "100%" }}
              display="flex"
              alignItems="center"
              style={{ alignItems: "center", alignSelf: "center" }}>
              <LabelIcon fontSize="medium" style={{ color: "#2196f3" }} />
              <Typography variant="subtitle2" style={{fontSize:"12px"}}>
                &nbsp;Sistemas de Gestión de Calidad
              </Typography>

            </Box>
          </Grid>
          <Grid xs={12} style={{ marginBottom: "15px" }}>
            <Box
              style={{ height: "100%" }}
              display="flex"
              alignItems="center"
              style={{ alignItems: "center", alignSelf: "center" }}>
              <LabelIcon fontSize="medium" style={{ color: "#fdd835" }} />
              <Typography variant="subtitle2" style={{fontSize:"12px"}}>
                &nbsp;Seguridad y Salud en el Trabajo
              </Typography>

            </Box>
          </Grid>
          <Grid xs={12} style={{ marginBottom: "15px" }}>
            <Box
              style={{ height: "100%" }}
              display="flex"
              alignItems="center"
              style={{ alignItems: "end", alignSelf: "center" }}>
              <LabelIcon fontSize="medium" style={{ color: "#4caf50" }} />
              <Typography variant="subtitle2" style={{fontSize:"12px"}}>
                &nbsp;Medio Ambiente
              </Typography>

            </Box>
          </Grid>
          {/* <Grid xs={12}>
            <Box
              style={{ height: "100%" }}
              display="flex"
              alignItems="center">
              <LabelIcon fontSize="large" style={{ color: "#731f1f" }} />Liderazgo
            </Box>
          </Grid>

          <Grid xs={12}>
            <Box
              style={{ height: "100%" }}
              display="flex"
              alignItems="center">
              <LabelIcon fontSize="large" style={{ color: "gray" }} /> Evaluación de Desempeño
            </Box>
          </Grid> */}
          <Grid xs={12} style={{ marginBottom: "15px" }}>
            <Box
              style={{ height: "100%" }}
              display="flex"
              alignItems="center"
              style={{ alignItems: "end", alignSelf: "center" }}>
              <LabelIcon fontSize="medium" style={{ color: "#731f1f" }} />
              <Typography variant="subtitle2" style={{fontSize:"12px"}}>
                &nbsp;SGI
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Divider style={{ backgroundColor: "white" }} />
          </Grid>

          <Grid xs={12} style={{ marginTop: "15px" }}>
            <Box
              style={{ height: "100%", width: "100%" }}
              display="flex"
              alignItems="center"
              style={{ alignItems: "end", alignSelf: "center" }}>
              <FiberManualRecord fontSize="medium" style={{ color: "#76ff03" }} />
              <Typography variant="subtitle2" style={{fontSize:"12px"}}>
                &nbsp;Nuevo Documento
              </Typography>


            </Box>

          </Grid>
          <Grid xs={12} style={{ marginTop: "15px" }}>
            <Box
              style={{ height: "100%", width: "100%" }}
              display="flex"
              alignItems="center"
              style={{ alignItems: "center", alignSelf: "center" }}>
              <FiberManualRecord fontSize="medium" style={{ color: "#00e5ff" }} />
              <Typography variant="subtitle2" style={{fontSize:"12px"}}>
                &nbsp;Documento Actualizado
              </Typography>

            </Box>
          </Grid>
          {/* <Grid xs={12} style={{ marginTop: "15px" }}>
            <Box
              style={{ height: "100%", width: "100%" }}
              display="flex"
              alignItems="center"
              style={{ alignItems: "center", alignSelf: "center" }}>
              <FiberManualRecord fontSize="medium" style={{ color: "#9e9e9e" }} />
              <Typography variant="subtitle2">
                &nbsp;Sin modificaciones hace 2 meses
              </Typography>

            </Box>
          </Grid> */}
          {/* <Grid xs={4} >
            <Box
              style={{ height: "100%" }}
              display="flex"
              alignItems="center">
              <LabelIcon fontSize="large" style={{ color: "white" }} /> Sin clasificación SGI
            </Box>
          </Grid> */}
          {/* <Grid xs={4} style={{ marginTop: "20px" }}>
            <Box
              style={{ height: "100%" }}
              display="flex"
              alignItems="center">
              <FiberManualRecord fontSize="small" style={{ color: "green" }} /> Nuevo proceso

            </Box>

          </Grid>
          <Grid xs={4} style={{ marginTop: "20px" }}>
            <Box
              style={{ height: "100%" }}
              display="flex"
              alignItems="center">
              <FiberManualRecord fontSize="small" style={{ color: "#ffc107" }} /> Proceso Actualizado recientemente
            </Box>
          </Grid>
          <Grid xs={4} style={{ marginTop: "20px" }}>
            <Box
              style={{ height: "100%" }}
              display="flex"
              alignItems="center">
              <FiberManualRecord fontSize="small" style={{ color: "black" }} /> Sin modificaciones hace 2 meses

            </Box>
          </Grid> */}


        </Grid>
      </Paper>
    </Fragment>
  );
}

const useStyle = makeStyles((theme) => ({
  box: {
    backgroundColor: "#731f1f",
    borderRadius: "20px",
    height: theme.spacing(6),
    // margin: theme.spacing(2),
  },
  typography: {
    color: "white",
    fontWeight: 1000,
  }
}));