import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Page from './page';

export default function Error404 (props) {
    return (
        <Page
            
        />
    );
}