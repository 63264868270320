import { BACKEND } from "../../../constants/urls";
import Axios from "axios";

/**
 * Método encargado de obtener todos los permisos.
 */
export async function ObtenerPermisos() {
	try {
		let url = `${BACKEND}/permisos`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de agregar un nuevo permiso.
 * @param {*} permiso Datos del permiso.
 */
export async function AgregarPermiso(permiso) {
	try {
		let url = `${BACKEND}/permisos`;
		let response = await Axios.post(url, permiso);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar un permiso.
 * @param {*} permiso Datos actualizados del permiso.
 */
export async function ActualizarPermiso(permiso) {
	try {
		let url = `${BACKEND}/permisos/${permiso._id}`;
		let response = await Axios.put(url, permiso);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar un permiso.
 * @param {*} permiso Datos del permiso.
 */
export async function EliminarPermiso(permiso) {
	try {
		let url = `${BACKEND}/permisos/${permiso._id}`;
		let response = await Axios.delete(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}