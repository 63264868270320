import { BACKEND } from "../../../constants/urls";
import Axios from "axios";

/**
 * Método encargado de obtener un rol, por su ID.
 * @param {*} rolID ID del rol.
 */
export async function ObtenerRol(rolID) {
  try {
    let url = `${BACKEND}/roles/${rolID}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todos los roles.
 */
export async function ObtenerRoles() {
  try {
    let url = `${BACKEND}/roles`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de agregar un nuevo rol.
 * @param {*} rol Datos del rol.
 */
export async function AgregarRol(rol) {
  try {
    let url = `${BACKEND}/roles`;
    let response = await Axios.post(url, rol);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de actualizar un rol.
 * @param {*} rol Datos actualizados del rol.
 */
export async function ActualizarRol(rol) {
  try {
    let url = `${BACKEND}/roles/${rol._id}`;
    let response = await Axios.put(url, rol);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de eliminar un rol.
 * @param {*} rol Datos del rol.
 */
export async function EliminarRol(rol) {
  try {
    let url = `${BACKEND}/roles/${rol._id}`;
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}