import React, { Fragment, Label, useContext, useEffect } from "react";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import Moment from "moment";
import { useFormik } from "formik";
import { object, string, array } from "yup";
import { Box, Button,Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextField, Tooltip } from "@material-ui/core";
import { AssignmentReturn, Close, GetApp, Visibility } from "@material-ui/icons";
import MaterialTable from "../../../../components/materialTable";
import { ObtenerProveedores, AgregarProveedor, ActualizarProveedor, EliminarProveedor } from "../../../../services/request/mapaProcesos/requestProveedoresInformacion";
import { ObtenerEntradas } from "../../../../services/request/mapaProcesos/requestEntrada";
import { ObtenerFichas } from "../../../../services/request/mapaProcesos/requestFicha";

import { Autocomplete } from "@material-ui/lab";
import { ObtenerGerencias } from "../../../../services/request/proyectos/requestGerencias"
import DialogAsignacion from "../../../../components/DialogAsignar";

import { MainContext } from "../../../../App";


function TabPerfiles(props) {
    const { data: proveedoresInformacion, error: errorProveedor, mutate: mutateProveedores } = useSWR("proveedoresInformacion", (key) => ObtenerProveedores(), { revalidateOnFocus: false });
    const { data: entradas, error: errorEntradas, mutate: mutateEntradas } = useSWR("documentos", (key) => ObtenerEntradas(), { revalidateOnFocus: false });
    const { data: gerencias, error: errorGerencias, mutate: mutateGerencias } = useSWR("gerencias", (key) => ObtenerGerencias(), { revalidateOnFocus: false });
    const { data: fichas, error: errorFichas, mutate: mutateFichas } = useSWR("fichas", (key) => ObtenerFichas(), { revalidateOnFocus: false });


    const [openPrev, setOpenPrev] = React.useState(false);
    const notistack = useSnackbar();
    const [openAsignacion, setOpenAsignacion] = React.useState(false);
    const [_id_, set_id_] = React.useState(null);

    const { usuarioSesion } = useContext(MainContext);


    const handleAbrirAsignacion = (event, row) => {
        set_id_(row._id);
        setOpenAsignacion(true);

    }

    useEffect(() => {
        if (errorProveedor) {
            notistack.enqueueSnackbar("Error al intentar obtener los procedimientos asociados.", {
                variant: "error",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        }
        if (errorEntradas) {
            notistack.enqueueSnackbar("Error al intentar obtener los documentos.", {
                variant: "error",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        }
    }, [errorProveedor, errorEntradas]);

    const formik = useFormik({
        initialValues: {
            codigo: "",
            proveedor: "",
            _entradas_ref: [],
            gerencia: [],
        },
        validationSchema: object().shape({
            codigo: string()
                .min(3, "El cargo debe tener al menos ${min} caracteres.")
                .max(50, "El cargo debe tener a los más ${max} caracteres.")
                .required("El cargo es requerido."),
            proveedor: string()
                .min(3, "El proveedor debe tener al menos ${min} caracteres.")
                .max(50, "El proveedor debe tener a los más ${max} caracteres.")
                .required("El proveedor es requerido."),
            _entradas_ref: array()
                .of(string())
                .optional(),
            gerencia: array()
                .of(string())
                .optional(),
        }),
        onSubmit: (values, helper) => handleAceptar(values),
        enableReinitialize: true,
    });

    /**
     * Handler para agregar un nuevo perfil.
     */
    const handleAgregar = () => {
        formik.setFieldValue("tipo_", "agregar");
    }

    /**
     * Handler para actualizar un perfil.
     * @param {*} event Evento.
     * @param {*} row Datos.
     */
    const handleActualizar = (event, row) => {
        formik.setValues(row);
        formik.setFieldValue("tipo_", "actualizar");
    }

    /**
     * Handler para eliminar un perfil.
     * @param {*} event Evento.
     * @param {*} row Datos.
     */
    const handleEliminar = (event, row) => {
        formik.setValues(row);
        formik.setFieldValue("tipo_", "eliminar");
    }

    /**
     * Handler para aceptar la acción.
     * @param {*} values 
     */
    const handleAceptar = async (values) => {
        console.log(values);
        try {
            switch (values.tipo_) {
                case "agregar":
                    console.log(values);
                    await AgregarProveedor(values);
                    break;
                case "actualizar":
                    await ActualizarProveedor(values);
                    break;
                case "eliminar":
                    await EliminarProveedor(values);
                    break;
                default:
                    throw new Error("Acción no especificada.")
            }
            notistack.enqueueSnackbar("Acción realizada exitosamente.", {
                variant: "success",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        } catch (error) {
            console.error(error);
            notistack.enqueueSnackbar("Error al intentar realizar la acción.", {
                variant: "error",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        } finally {
            handleCancelar();
        }
    }

    const handleCancelar = () => {
        mutateProveedores();
        mutateEntradas();
        formik.resetForm();
    }

    const handlePrev = (event, row) => {
        formik.setValues(row);
        formik.setFieldValue("tipo_", "previsualizar");
        setOpenPrev(true);
    }

    let columns = [
        {
            title: "Nombre",
            field: "proveedor",
        }, {
            // PAra no modificar la Bd, se cambia solo el nombre de la columna
            title: "Cargo",
            field: "codigo",
            // defaultSort: 'asc'
        }, {
            title: "Gerencia",
            align: 'center',
            field: "gerencia",
            width: "12%",


            render: rowData => (
                <div>
                    {rowData.gerencia && rowData.gerencia.map(type => (
                        <Fragment>
                            {gerencias ? Array.from(gerencias).filter(p => type.includes(p.sigla)).map((d, index) => (
                                <Chip key={d.sigla}
                                    size="small"
                                    variant="default"
                                    color="primary"
                                    style={{ margin: 2 }}
                                    label={d.sigla} />
                            )) : <div></div>}
                        </Fragment>
                    ))}
                </div>
            ),
        }, {
            title: "Asignado a",
            align: "center",
            // field: "version", 
            render: rowData => (
                // <div>
                //     {rowData.gerencia.map(type => (
                <Fragment>
                    {fichas ? Array.from(fichas.data).filter(p => p._proveedoresInformacion_ref.includes(rowData._id)).map((d, index) => (
                        <div>
                            <Tooltip title={d.nombre}>
                                <Chip
                                    key={d.nombre}
                                    size="small"
                                    variant="default"
                                    color="primary"
                                    style={{ margin: 2 }}
                                    label={
                                        <div>
                                            {d.codigo}
                                        </div>
                                    } />
                            </Tooltip>
                        </div>
                    )) : <div></div>}

                </Fragment>
            ),
        }, {

            title: "Fecha Actualización",
            field: "fecha_actualizacion",
            align: 'center',
            defaultSort: "desc",
            width: "15%",

            render: (row) => Moment(row.fecha_actualizacion).format("DD/MM/YYYY HH:mm"),
        },
    ];

    let columnsDoc = [
        {
            title: "Código",
            field: "codigo",
            defaultSort: 'asc'
        }, {
            title: "Tipo",
            field: "tipo_documento",
        }, {
            title: "Nombre",
            field: "nombre",
        }, {
            title: "Link",
            field: "link",
        },
    ];

    let actions = [
        {
            tooltip: "Agregar",
            icon: "add",
            onClick: () => handleAgregar(),
            isFreeAction: true,
        }, 
        // {
        //     tooltip: "Previsualizar",
        //     icon: () => <Visibility />,
        //     onClick: handlePrev,
        // }, 
        {
            tooltip: "Editar",
            icon: "edit",
            onClick: handleActualizar,
        },

        {
            tooltip: "Asignar a Ficha",
            icon: () => <AssignmentReturn />,
            onClick: handleAbrirAsignacion,
        },
        {
            tooltip: "Eliminar",
            icon: "delete",
            onClick: handleEliminar,
        }
    ];

    let actionsDoc = [
        {
            tooltip: "Ver",
            icon: () => <Visibility />,
            // onClick: handlePrev,
        }, {
            tooltip: "Descargar",
            icon: () => <GetApp />,
            // onClick: handleActualizar,
        },
    ];

    return (
        <Fragment>
            <MaterialTable
                title="Proveedores de Información"
                is_loading={!proveedoresInformacion}
                // data={proveedoresInformacion && proveedoresInformacion.data}
                data={proveedoresInformacion && Array.from(proveedoresInformacion.data).filter(g => g.gerencia.some(j => usuarioSesion.gerencia_visualizar.includes(j))).concat(Array.from(proveedoresInformacion.data).filter(g => Array.from(g.gerencia).length == 0)) }

                columns={columns}
                actions={actions}
            />
            <Dialog open={Boolean(formik.values.tipo_)} maxWidth="md" fullWidth>
                <DialogTitle>
                    {formik.values.tipo_ === "agregar" && "Agregar Proveedor de Información"}
                    {formik.values.tipo_ === "actualizar" && "Actualizar Proveedor de Información"}
                    {formik.values.tipo_ === "eliminar" && "Eliminar Proveedor de Información"}
                    {formik.values.tipo_ === "previsualizar" && "Previsualización de Proveedor de Información"}
                </DialogTitle>
                <DialogContent dividers>
                    {formik.values.tipo_ == "actualizar" || formik.values.tipo_ == "agregar" || formik.values.tipo_ == "eliminar" ?
                        (
                            <div>
                                <DialogContentText>Formulario con información del Proveedor de Información.</DialogContentText>
                                <Grid container spacing={2}>

                                    <Grid item xs={6}>
                                        <TextField
                                            label="Proveedor"
                                            name="proveedor"
                                            value={formik.values.proveedor}
                                            onChange={formik.handleChange}
                                            helperText={formik.errors.proveedor}
                                            error={Boolean(formik.errors.proveedor)}
                                            variant="outlined"
                                            fullWidth
                                            disabled={formik.values.tipo_ === "eliminar"}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            label="Cargo"
                                            name="codigo"
                                            value={formik.values.codigo}
                                            onChange={formik.handleChange}
                                            helperText={formik.errors.codigo}
                                            error={Boolean(formik.errors.codigo)}
                                            variant="outlined"
                                            fullWidth
                                            disabled={formik.values.tipo_ === "eliminar"}
                                        />
                                    </Grid>


                                    <Grid item xs={12}>
                                        <Autocomplete
                                            multiple
                                            options={entradas ? entradas.data : []}
                                            value={entradas ? Array.from(entradas.data).filter(p => formik.values._entradas_ref.includes(p._id)) : []}
                                            getOptionLabel={(entradas) => entradas.entrada.nombre}
                                            disabled={formik.values.tipo_ === "eliminar"}
                                            onChange={(event, value) => {
                                                formik.setFieldValue("_entradas_ref", value.map(v => v._id));
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    label="Entradas"
                                                    variant="outlined"
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>

                                        <Autocomplete
                                            multiple
                                            options={gerencias ? gerencias : []}
                                            value={formik.values.gerencia && gerencias ? Array.from(gerencias).filter(p => formik.values.gerencia.includes(p.sigla)) : []}
                                            getOptionLabel={(gerencia) => gerencia.sigla + " - " + gerencia.nombre}
                                            disabled={formik.values.tipo_ === "eliminar"}
                                            onChange={(event, value) => {
                                                formik.setFieldValue("gerencia", value.map(v => v.sigla));
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    label="Gerencias"
                                                    variant="outlined"
                                                    {...params}
                                                />
                                            )}
                                        />

                                    </Grid>
                                </Grid>
                            </div>
                        ) : (
                            <div>
                                <Grid container spacing={2}>
                                    asd
                                </Grid>
                            </div>)}

                </DialogContent>
                {formik.values.tipo_ === "actualizar" || formik.values.tipo_ == "agregar" || formik.values.tipo_ == "eliminar" ?
                    (
                        <DialogActions>
                            <Button onClick={handleCancelar} variant="outlined" color="primary">
                                Cancelar
                            </Button>
                            <Button onClick={formik.submitForm} variant="contained" color="primary">
                                Aceptar
                            </Button>
                        </DialogActions>
                    ) : (
                        <DialogActions>
                            <Button onClick={handleCancelar} variant="outlined" color="primary">
                                Cerrar
                            </Button>

                        </DialogActions>
                    )
                }
            </Dialog>

            <Dialog open={openAsignacion} fullWidth>
                <DialogAsignacion _id_={_id_} titulo="ProveedorInformacion" setOpenAsignacion={setOpenAsignacion} mutate={mutateFichas}/>
            </Dialog>


        </Fragment>
    );
}

export default TabPerfiles;