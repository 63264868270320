import Axios from "axios";
import { PORTAFOLIO } from "../../../constants/urls";

/**
 * Método encargado de obtener todas las gerencias desde API Portafolio.
 */
export async function ObtenerGerencias() {
  try {
    let url = `${PORTAFOLIO}/gerencias`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}