import React from "react";
import Page from "./page";

function Index(props) {

  const {
    handleDialogClose,
    macroproceso,
    tipo_proceso,
    idFicha,
  } = props;

  return (
    <Page handleDialogClose={handleDialogClose} macroproceso={macroproceso} tipo_proceso={tipo_proceso} idFicha={idFicha} />
  );
}

export default Index;