import React, { Fragment, Label, useContext, useEffect } from "react";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import Moment from "moment";
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Divider, Tooltip, Card, CardContent, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, TextField, makeStyles } from "@material-ui/core";
import { Assignment, Close, Description, ExpandMore, FiberManualRecord, GetApp, Label as LabelIcon, ViewModule, Visibility, } from "@material-ui/icons";
import {
  Cake as CakeIcon,
  WatchLater as WatchLaterIcon,
  Restore as RestoreIcon,
  NoteAdd as NoteAddIcon,
} from "@material-ui/icons";

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';


import { ObtenerMacroprocesos } from "../../../../services/request/mapaProcesos/requestMacroproceso";
import { ObtenerProcAsocs } from "../../../../services/request/mapaProcesos/requestProcedimientoAsociado";
import { ObtenerEntradas } from "../../../../services/request/mapaProcesos/requestEntrada";
import { ObtenerProveedores } from "../../../../services/request/mapaProcesos/requestProveedoresInformacion";
import { ObtenerClientes } from "../../../../services/request/mapaProcesos/requestClientes";
import { ObtenerEntregables } from "../../../../services/request/mapaProcesos/requestEntregables";
import { ObtenerDocsAsociados } from "../../../../services/request/mapaProcesos/requestDocumentosAsociados";
import Simbologia from "./../../../../components/Simbologia";






import { ObtenerServicios } from "../../../../services/request/mapaProcesos/requestServicio";
import { ObtenerFichas } from "../../../../services/request/mapaProcesos/requestFicha";
import { ObtenerGerencias } from "../../../../services/request/proyectos/requestGerencias"
import { GetContratos } from "../../../../services/request/portafolio/contratos";
import { MainContext } from "../../../../App";


import Ficha from "../../../ficha";
import { Autocomplete } from "@material-ui/lab";


import { ObtenerEtiquetas } from "../../../../services/request/mapaProcesos/requestEtiqueta";

export default function TabServicios(props) {

  const {
    usuarioSesion,
    handleSearchServicio,
    
    // searchServicio,
  } = props;


  const { data: macroprocesos, error: errorMacroprocesos, mutate: mutateMacroprocesos } = useSWR("macroprocesos", (key) => ObtenerMacroprocesos(), { revalidateOnFocus: false });
  const { data: servicios, error: errorServicios, mutate: mutateServicios } = useSWR("servicios", (key) => ObtenerServicios(), { revalidateOnFocus: false });
  // const { data: servicio, error: errorServicio, mutate: mutateServicio } = useSWR("servicios", (key) => ObtenerServicio(_idServicio), { revalidateOnFocus: false });
  const { data: fichas, error: errorFichas, mutate: mutateFichas } = useSWR("fichas", (key) => ObtenerFichas(), { revalidateOnFocus: false });
  const { data: gerencias, error: errorGerencias, mutate: mutateGerencias } = useSWR("gerencias", (key) => ObtenerGerencias(), { revalidateOnFocus: false });
  const { data: contratos, error: errorContratos, mutate: mutateContratos } = useSWR("contratos", (key) => GetContratos(), { revalidateOnFocus: false });
  const { data: procedimientosAsociados, error: errorPerfiles, mutate: mutatePerfiles } = useSWR("procedimientosAsociados", (key) => ObtenerProcAsocs(), { revalidateOnFocus: false });
  const { data: entradas, error: errorEntradas, mutate: mutateEntradas } = useSWR("entradas", (key) => ObtenerEntradas(), { revalidateOnFocus: false });
  const { data: proveedorInformacion, error: errorProveedorInformacion, mutate: mutateProveedorInformacion } = useSWR("proveedoresInformacion", (key) => ObtenerProveedores(), { revalidateOnFocus: false });
  const { data: clientes, error: errorClientes, mutate: mutateClientes } = useSWR("clientes", (key) => ObtenerClientes(), { revalidateOnFocus: false });
  const { data: entregables, error: errorEntregables, mutate: mutateEntregables } = useSWR("entregables", (key) => ObtenerEntregables(), { revalidateOnFocus: false });
  const { data: etiquetas, error: errorEtiquetas, mutate: mutateEtiquetas } = useSWR("etiquetas", (key) => ObtenerEtiquetas(), { revalidateOnFocus: false });
  const { data: documentosAsociados, error: errorDocumentosAsociados, mutate: mutateDocumentosAsociados } = useSWR("documentosAsociados", (key) => ObtenerDocsAsociados(), { revalidateOnFocus: false });






  const { searchServicio, filtroIso, setFiltroIso, setMostrarBuscar, 
    handleCheckProcesos, checkProcesos,
    handleCheckArchivos, checkArchivos,
    handleCheckContratos, checkContratos,
    handleCheckEtiquetas, checkEtiquetas,


   } = useContext(MainContext);

  const [acordionEstrategico, setAcordionEstrategico] = React.useState(false);
  const [acordionOperativo, setAcordionOperativo] = React.useState(true);
  const [acordionSoporte, setAcordionSoporte] = React.useState(false);

  const [expanded, setExpanded] = React.useState(false);
  const [state, setState] = React.useState({
    archivos: false,
    procesos: true,
    contratos: false,
    etiquetas: false,
  });

  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogEntradas, setOpenDialogEntradas] = React.useState(false);
  const [openDialogSalidas, setOpenDialogSalidas] = React.useState(false);

  const notistack = useSnackbar();
  const [macroproceso_name, setMacroproceso_name] = React.useState(null);
  const [tipo_proceso, setTipo_proceso] = React.useState(null);
  const [idFicha, setIdFicha] = React.useState(null);
  const [servicioSeleccionado, setServicioSeleccionado] = React.useState(null);
  const [gerenciaSeleccionada, setGerenciaSeleccionada] = React.useState(null);
  const [listaMacroprocesos, setListaMacroprocesos] = React.useState([]);
  const [listaServicios, setListaServicios] = React.useState([]);
  // const [_idServicio, set_idServicio] = React.useState("");
  const [nombreServicio, setNombreServicio] = React.useState(null);
  const [normativaIso, setNormativaIso] = React.useState(null);

  const classes = useStyles();

  let normativaIso_ = [{ nombre: "Sistemas de Gestión de Calidad" }, { nombre: "Seguridad y Salud en el Trabajo" }, { nombre: "Medio Ambiente" },
  //  { nombre: "Liderazgo" }, { nombre: "Evaluación de Desempeño" },
  { nombre: "SGI" }];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeCheck = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const abrirPopUpFicha = (tipoProceso, macroproceso, idFicha) => {

    setMacroproceso_name(macroproceso);
    setTipo_proceso(tipoProceso);
    setIdFicha(idFicha);
    handleDialogOpen();
  }

  function handleDialogClose() {
    setOpenDialog(false);
  }

  const handleClickSearchClick = (value) => {
    console.log(value);
    handleSearchServicio(value);
  };


  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  function handleDialogEntradasClose() {
    setOpenDialogEntradas(false);
  }

  const handleDialogEntradasOpen = () => {
    setOpenDialogEntradas(true);
  };

  function handleDialogSalidasClose() {
    setOpenDialogSalidas(false);
  }

  const handleDialogSalidasOpen = () => {
    setOpenDialogSalidas(true);
  };

  const seleccionGerencia = (_idGerencia) => {

    setListaServicios([]);
    if (_idGerencia != null) {
      setListaServicios(servicios ? Array.from(servicios.data).filter(p => p.gerencia.includes(_idGerencia)) : []);
    }
  };

  const seleccionServicio = (_idServicio) => {
    if (_idServicio != null) {
      setListaServicios(servicios ? Array.from(servicios.data).filter(p => p.gerencia.includes(gerenciaSeleccionada.sigla) && p._id.includes(_idServicio._id)) : []);
    } else {
      seleccionGerencia(gerenciaSeleccionada.sigla);
    }
  };

  useEffect(() => {
    if (errorMacroprocesos) {
      notistack.enqueueSnackbar("Error al intentar obtener los Macroprocesos.", {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
      });
    }

    if (errorServicios) {
      notistack.enqueueSnackbar("Error al intentar obtener los Servicios.", {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
      });
    }

    if (errorFichas) {
      notistack.enqueueSnackbar("Error al intentar obtener las fichas.", {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
      });
    }

  }, [errorMacroprocesos, errorServicios, errorFichas]);

  useEffect(() => {
    if (usuarioSesion && gerencias && contratos && servicios) {
      setGerenciaSeleccionada(Array.from(gerencias).find(p => p.sigla.includes(usuarioSesion.gerencia)));
      setNombreServicio(Array.from(servicios.data).find(p => p.nombre.includes(usuarioSesion.contrato_ref)));
    }
  }, [gerencias, usuarioSesion, contratos, servicios])


  useEffect(() => {

    if (searchServicio != null) {
      setNombreServicio(null);
      setServicioSeleccionado("");
      setNormativaIso(null);
      setGerenciaSeleccionada(null);
    } else {
      // setMostrarBuscar(false);
    }


  }, [searchServicio])

  useEffect(() => {

    if (filtroIso != null) {
      setNormativaIso(filtroIso);
    }
  }, [])

  useEffect(() => {

    if (nombreServicio != null && gerenciaSeleccionada != null) {

      setListaServicios(servicios ? Array.from(servicios.data).filter(p => p.gerencia.includes(gerenciaSeleccionada.sigla) && p._id.includes(nombreServicio._id)) : [])
      setServicioSeleccionado(nombreServicio.nombre);
      console.log("NADA NULL");
      // setNormativaIso(filtroIso);
    } else if (nombreServicio == null && gerenciaSeleccionada != null) {
      setListaServicios(servicios ? Array.from(servicios.data).filter(p => p.gerencia.includes(gerenciaSeleccionada.sigla)) : []);
      setServicioSeleccionado(gerenciaSeleccionada.sigla);

      // sdfsdfsdfsdfsdf
      setNombreServicio(null);

      console.log(gerenciaSeleccionada);
      console.log("NOMBRE SERVICIO NULL");


    } else if (gerenciaSeleccionada == null) {
      console.log("Gerencia NULL");
      setNombreServicio(null);
      setServicioSeleccionado("");
      // setNormativaIso(null);

    } else {
      console.log("NADA");
      setListaServicios([]);
      setNombreServicio(null);
      setServicioSeleccionado("");
    }
  }, [nombreServicio, gerenciaSeleccionada, normativaIso])

  return (
    <Fragment>

      <Grid container spacing={2}>
        <Grid xs={12} style={{ backgroundColor: "#eeeeee", marginBottom: "20px" }}>
          <Grid container style={{ marginBottom: "20px", marginTop: "15px" }}>

            <Grid xs={4} style={{ textAlign: "center" }}>
              <Autocomplete

                style={{ paddingRight: "20px", paddingLeft: "20px", borderColor: "red" }}
                // options={gerencias ? gerencias : []}
                options={gerencias ? Array.from(gerencias).filter(g => usuarioSesion.gerencia_visualizar.includes(g.sigla)) : []}
                size="small"

                value={gerenciaSeleccionada}
                getOptionLabel={(gerencia) => gerencia.sigla + " - " + gerencia.nombre}
                disabled={searchServicio != null}
                onChange={(event, value) => {
                  console.log(value);
                  setGerenciaSeleccionada(value);
                  seleccionGerencia(value && value._id);
                  setNombreServicio(null);

                }}
                renderInput={(params) => (
                  <TextField
                    className="inputRounded"
                    style={{ borderRadius: "50px", backgroundColor: "white" }}
                    label="Gerencias"
                    variant="outlined"
                    // style={{ backgroundColor: "white" }}
                    {...params}

                  />
                )}
              />

            </Grid>
            <Grid xs={5} style={{ textAlign: "left" }}>
              <Autocomplete
                style={{ paddingRight: "20px" }}
                options={servicios && gerenciaSeleccionada ? servicios.data.filter(p => p.gerencia.includes(gerenciaSeleccionada.sigla)) : []}
                value={nombreServicio}
                getOptionLabel={(servicios) => `${servicios.nombre}`}
                disabled={searchServicio != null}
                size="small"
                onChange={(event, value) => {
                  console.log(value ? value.nombre : "");
                  setNombreServicio(value && value);
                  setServicioSeleccionado(value ? value.nombre : "");
                  seleccionServicio(value && value);
                }}
                renderInput={(params) => (
                  <TextField
                    label="Contrato"
                    size="small"
                    className="inputRounded"
                    style={{
                      borderRadius: "50px", backgroundColor: "white",
                      size: "small"
                    }}
                    variant="outlined"
                    size="small"
                    {...params}
                  />
                )}
              />
            </Grid>

            <Grid xs={3} style={{ textAlign: "left" }}>
              <Autocomplete
                style={{ marginRight: "20px", borderRadius: "50px" }}
                options={normativaIso_}
                value={normativaIso}
                getOptionLabel={(servicios) => servicios.nombre}
                disabled={searchServicio != null}
                size="small"

                onChange={(event, value) => {
                  console.log(value);
                  // setNombreServicio(value && value);
                  // setServicioSeleccionado(value ? value.nombre : "");
                  // seleccionServicio(value && value);
                  // if (value && value.nombre == "Seguridad y Salud en el Trabajo") {
                  //   setNormativaIso({ nombre: "Seguridad y Salud en el Trabajo" });
                  // } else {
                    setNormativaIso(value && value);
                  // }
                }}
                renderInput={(params) => (
                  <TextField
                    label="Clasificación SGI"
                    className="inputRounded"
                    style={{ borderRadius: "50px", backgroundColor: "white" }}
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Grid>

          </Grid>

        </Grid>



        {searchServicio == null ? (
          <Grid container>

            <Grid xs={2}>
              {/* <ListadoActualizacion handleClickSearchClick={handleClickSearchClick}/> */}
              {/* TITULO E ICONO */}
              <Grid
                container
                style={{

                  // marginTop: "1rem",
                  backgroundColor: "white",
                  borderTopRightRadius: "20px",
                  borderTopLeftRadius: "20px",
                  // paddingTop: 8,
                  // paddingBottom:8
                }}
              >
                <Grid item xs={10} style={{ alignSelf: "center" }}>
                  <Typography
                    variant="subtitle2"
                    align="center"
                    style={{ fontWeight: "bold", paddingLeft: 15, }}
                  >
                    Últimas Actualizaciones
                  </Typography>
                </Grid>
                <Grid item xs={2} style={{ width: "30%" }}>
                  <WatchLaterIcon style={{ fontSize: 30, color: "#731f1f" }} />
                </Grid>
                <Grid xs={12}>
                  <Divider variant="middle" />
                </Grid>
              </Grid>

              {/* LISTADO */}
              <div className={classes.root}>

                {fichas &&
                  Array.from(fichas.data)
                    .filter(g => g.gerencia.some(j => usuarioSesion.gerencia_visualizar.includes(j)))
                    .sort((a, b) => Moment(b.fecha_actualizacion).valueOf() - Moment(a.fecha_actualizacion).valueOf()).slice(0, 10).map((d, index) => (
                      <Card elevation={0} className={classes.card}
                        onClick={abrirPopUpFicha.bind(null, (() => {
                          if (macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(d._id)).length > 0) {
                            return macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(d._id))[0].tipo_proceso;
                          } else {
                            return "No asignado"
                          }
                        })
                          ,
                          (() => {
                            if (macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(d._id)).length > 0) {
                              return macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(d._id))[0].nombre;
                            } else {
                              return "No asignado"
                            }
                          })
                          , d._id)} style={{ cursor: "pointer" }}>
                        <CardContent style={{ padding: "5px" }}>
                          <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                            <Grid item md={2} xs={2} sm={2}>
                              {Moment(d.fecha_actualizacion) > Moment(d.fecha_creacion) ? <RestoreIcon /> : <NoteAddIcon />}
                            </Grid>
                            <Grid container item xs={10} sm={10} md={10} direction="column" justify="flex-start" alignItems="flex-start" style={{ paddingLeft: "10px" }}>
                              <Box >
                                <Typography className={classes.typography_nombre}>{`${d.nombre}`}</Typography>
                                <Typography className={classes.typography_email}>{d.codigo}</Typography>
                                <Typography className={classes.typography_cargo}>{Moment(d.fecha_actualizacion).format("DD/MM/YYYY HH:mm")} </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>

                      // <UserCard key={`userCard-${index}`} persona={d.persona} />
                    ))}

              </div>
              <div style={{ height: "20px" }} />
              <Simbologia
              />
            </Grid>
            <Grid xs={10}>
              <Grid container>
                <Grid xs={12} style={{ textAlign: "center", marginBottom: "10px" }}>
                  <Typography variant="h5">
                    Mapa de procesos de {servicioSeleccionado}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <Box style={{ height: "100%", width: "100%", paddingLeft: "10px" }}>
                    <Button variant="contained" style={{
                      width: "80%", height: "104%", backgroundColor: "lightgray",
                      clipPath: "polygon(80% 0%,100% 10%,80% 20% , 80% 80%, 100% 90%, 80% 100%, 0 100%, 0 0%)"

                    }} onClick={handleDialogEntradasOpen}>
                      {/* <Typography fontWeight="bold" variant="caption" style={{ color: "#731f1f" }}> */}
                      <Typography fontWeight="bold" variant="h6" style={{ transform: "rotate(270deg)", color: "#731f1f", paddingBottom: "10px" }}>
                        Entradas
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={10}>
                  <Grid container>
                    {/* ESTRATÉGICOS */}
                    <Grid xs={12} style={{ textAlign: "center", marginBottom: "15px" }}>
                      <Accordion
                        onChange={(e, expanded) => {
                          if (expanded) {
                            setAcordionEstrategico(true);
                          } else {
                            setAcordionEstrategico(false);
                          }
                        }}
                        // style={{ borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px" }}
                        style={{
                          borderRadius: "15px", backgroundColor: "lightgray", height: "120%",
                          clipPath: "polygon(100% 0%,100% 80%, 97% calc(80% + 14px), 94% 80%, 0% 80% , 0 0)"
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMore style={{ color: "white" }} />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{
                            backgroundColor: "lightgray",
                            borderRadius: "15px"
                            //  borderTopLeftRadius: "15px", borderTopRightRadius: "15px" 
                          }}
                        >
                          <ViewModule fontSize="large" style={{ color: "#f28933", marginRight: "10px" }} />
                          <Typography variant="h5" fontWeight="bold" style={{ color: "white" }}>

                            Estratégicos
                            {/* Estratégicos */}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{
                          backgroundColor: "lightgray",
                          //  borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px",
                          borderRadius: "15px",
                          padding: "0px", paddingLeft: "10px", paddingRight: "20px", paddingBottom: "30px",
                        }}>

                          <Grid container style={{ width: "100%" }}>
                            {listaServicios && Array.from(listaServicios).map((d, index) => (
                              <Fragment>
                                {servicios && macroprocesos && Array.from(macroprocesos.data).filter(p => d._macroprocesos_ref.includes(p._id) && p.tipo_proceso == "Estratégico").map((d, index) => (

                                  // <div>
                                  //   {d.tipo_proceso == "Estratégico" ? (
                                  <Grid item xs={4} style={{ maxWidth: "100%", padding: "5px" }}>

                                    <Accordion style={{ borderRadius: "15px" }}>
                                      <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{ backgroundColor: "#f28933", borderRadius: "15px", color: "white" }}
                                      >
                                        <Typography fontWeight="bold">{d.nombre}</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Grid container style={{ width: "100%" }}>
                                          {fichas && Array.from(fichas.data).filter(p => d._fichas_ref.includes(p._id)
                                            && (normativaIso ? p.normativa_iso == normativaIso.nombre : d._fichas_ref.includes(p._id))).map((f, index) => (


                                              <Grid item xs={12} style={{ maxWidth: "100%", marginBottom: "7px" }}>
                                                <Grid container >
                                                  <Grid item xs={1}>
                                                    <Box
                                                      style={{ height: "100%" }}
                                                      display="flex"
                                                      alignItems="center">
                                                      {Moment(f.fecha_actualizacion) > Moment(f.fecha_creacion)
                                                        &&
                                                        Moment(f.fecha_actualizacion) > Moment(f.fecha_creacion).add(1, "week")
                                                        ?
                                                        Moment(f.fecha_actualizacion).add(2, "months") < Moment() ?
                                                          <Tooltip title="Modif. > 2 meses">
                                                            <FiberManualRecord fontSize="small" style={{ color: "#9e9e9e" }} />
                                                          </Tooltip>
                                                          :
                                                          <Tooltip title="Documento Actualizado">
                                                            <FiberManualRecord fontSize="small" style={{ color: "#00e5ff" }} />
                                                          </Tooltip>
                                                        :
                                                        <Tooltip title="Nuevo Documento">
                                                          <FiberManualRecord fontSize="small" style={{ color: "#76ff03" }} />
                                                        </Tooltip>
                                                      }

                                                    </Box>
                                                  </Grid>
                                                  <Grid item xs={10} style={{ paddingLeft: "3px", alignItems: "left" }}>
                                                    <Typography variant="subtitle2" display="flex" alignItems="left" onClick={abrirPopUpFicha.bind(null, "Estratégico", d.nombre, f._id)} style={{ cursor: "pointer", color: "#731f1f" }}>

                                                      <strong>{f.codigo}</strong>

                                                      {/* <FiberManualRecord fontSize="small" style={{ marginRight: "8px" }} /> {f.nombre} */}
                                                    </Typography>
                                                    <Typography display="flex" alignItems="left" onClick={abrirPopUpFicha.bind(null, "Estratégico", d.nombre, f._id)} style={{ cursor: "pointer" }}>
                                                      {/* {Moment(d.fecha_actualizacion) > Moment(d.fecha_creacion) ? <RestoreIcon /> : <NoteAddIcon />} */}
                                                      {f.nombre}

                                                      {/* <FiberManualRecord fontSize="small" style={{ marginRight: "8px" }} /> {f.nombre} */}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item xs={1}>
                                                    <Box
                                                      style={{ height: "100%" }}
                                                      display="flex"
                                                      alignItems="center">
                                                      {f.normativa_iso == "Sistemas de Gestión de Calidad" ?
                                                        <Tooltip title="Sistemas de Gestión de Calidad">
                                                          <LabelIcon fontSize="small" style={{ color: "#2196f3" }} />
                                                        </Tooltip>
                                                        :
                                                        f.normativa_iso == "Seguridad y Salud en el Trabajo" ?
                                                          <Tooltip title="Seguridad y Salud en el Trabajo">

                                                            <LabelIcon fontSize="small" style={{ color: "#fdd835" }} />
                                                          </Tooltip>
                                                          :
                                                          f.normativa_iso == "Medio Ambiente" ?
                                                            <Tooltip title="Medio Ambiente">
                                                              <LabelIcon fontSize="small" style={{ color: "#4caf50" }} />
                                                            </Tooltip>
                                                            :
                                                            // f.normativa_iso == "Liderazgo" ?
                                                            //   <Tooltip title="Liderazgo">
                                                            //     <LabelIcon fontSize="small" style={{ color: "#731f1f" }} />
                                                            //   </Tooltip>
                                                            //   :
                                                            //   f.normativa_iso == "Evaluación de Desempeño" ?
                                                            //     <Tooltip title="Evaluación de Desempeño">
                                                            //       <LabelIcon fontSize="small" style={{ color: "gray" }} />
                                                            //     </Tooltip>
                                                            //     :
                                                            f.normativa_iso == "SGI" ?
                                                              <Tooltip title="SGI">
                                                                <LabelIcon fontSize="small" style={{ color: "#731f1f" }} />
                                                              </Tooltip>
                                                              :
                                                              <Tooltip title="Sin clasificación SGI">
                                                                <LabelIcon fontSize="small" style={{ color: "white" }} />
                                                              </Tooltip>


                                                      }

                                                    </Box>
                                                  </Grid>

                                                </Grid>



                                              </Grid>
                                            ))}

                                        </Grid>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Grid>
                                ))}
                              </Fragment>
                            ))}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid xs={12} style={{ textAlign: "center", marginTop: "10px", marginBottom: "15px" }}>
                      <Accordion
                        expanded={acordionOperativo}
                        style={{
                          backgroundColor: "lightgray", height: "100%",
                          clipPath: "polygon(98% 0%, 100% 50%,98% 100%, 100% 100%, 0% 100%, 0% 0%)",
                          // padding:"0px"
                        }}
                        onChange={(e, expanded) => {
                          if (expanded) {
                            setAcordionOperativo(true);
                          } else {
                            setAcordionOperativo(false);
                          }
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMore style={{ color: "white" }} />}

                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{ backgroundColor: "lightgray", borderRadius: "15px" }}
                        >
                          <ViewModule fontSize="large" style={{ color: "#86b25f", marginRight: "10px" }} />
                          <Typography variant="h5" fontWeight="bold" style={{ color: "white" }}>
                            Operativo
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ backgroundColor: "lightgray", borderRadius: "15px", padding: "0px", paddingLeft: "10px", paddingRight: "20px", paddingBottom: "30px" }}>
                          <Grid container style={{ width: "100%" }}>
                            {listaServicios && Array.from(listaServicios).map((d, index) => (
                              <Fragment>
                                {servicios && macroprocesos && Array.from(macroprocesos.data).filter(p => d._macroprocesos_ref.includes(p._id) && p.tipo_proceso == "Operativo").map((d, index) => (

                                  // <div>
                                  //   {d.tipo_proceso == "Estratégico" ? (
                                  <Grid item xs={4} style={{ maxWidth: "100%", padding: "5px" }}>

                                    <Accordion style={{ borderRadius: "15px" }}>
                                      <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{ backgroundColor: "#86b25f", borderRadius: "15px", color: "white" }}
                                      >
                                        <Typography fontWeight="bold">{d.nombre}</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Grid container style={{ width: "100%" }}>
                                          {fichas && Array.from(fichas.data).filter(p => d._fichas_ref.includes(p._id)
                                            && (normativaIso ? p.normativa_iso == normativaIso.nombre : d._fichas_ref.includes(p._id))).map((f, index) => (

                                              // <div>
                                              //   {d.tipo_proceso == "Estratégico" ? (
                                              <Grid item xs={12} style={{ maxWidth: "100%", marginBottom: "7px" }}>
                                                <Grid container >
                                                  <Grid item xs={1}>
                                                    <Box
                                                      style={{ height: "100%" }}
                                                      display="flex"
                                                      alignItems="center">
                                                      {Moment(f.fecha_actualizacion) > Moment(f.fecha_creacion)
                                                        &&
                                                        Moment(f.fecha_actualizacion) > Moment(f.fecha_creacion).add(1, "week")
                                                        ?
                                                        Moment(f.fecha_actualizacion).add(2, "months") < Moment() ?
                                                          <Tooltip title="Modif. > 2 meses">

                                                            <FiberManualRecord fontSize="small" style={{ color: "#9e9e9e" }} />
                                                          </Tooltip>
                                                          :
                                                          <Tooltip title="Documento Actualizado">
                                                            <FiberManualRecord fontSize="small" style={{ color: "#00e5ff" }} />
                                                          </Tooltip>
                                                        :
                                                        <Tooltip title="Nuevo Documento">
                                                          <FiberManualRecord fontSize="small" style={{ color: "#76ff03" }} />
                                                        </Tooltip>
                                                      }

                                                    </Box>
                                                  </Grid>
                                                  <Grid item xs={10} style={{ paddingLeft: "3px", alignItems: "left" }}>
                                                    <Typography variant="subtitle2" display="flex" alignItems="left" onClick={abrirPopUpFicha.bind(null, "Operativo", d.nombre, f._id)} style={{ cursor: "pointer", color: "#731f1f" }}>

                                                      <strong>{f.codigo}</strong>

                                                      {/* <FiberManualRecord fontSize="small" style={{ marginRight: "8px" }} /> {f.nombre} */}
                                                    </Typography>
                                                    <Typography display="flex" alignItems="left" onClick={abrirPopUpFicha.bind(null, "Operativo", d.nombre, f._id)} style={{ cursor: "pointer" }}>
                                                      {/* {Moment(d.fecha_actualizacion) > Moment(d.fecha_creacion) ? <RestoreIcon /> : <NoteAddIcon />} */}
                                                      {f.nombre}

                                                      {/* <FiberManualRecord fontSize="small" style={{ marginRight: "8px" }} /> {f.nombre} */}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid item xs={1}>
                                                    <Box
                                                      style={{ height: "100%" }}
                                                      display="flex"
                                                      alignItems="center">
                                                      {f.normativa_iso == "Sistemas de Gestión de Calidad" ?
                                                        <Tooltip title="Sistemas de Gestión de Calidad">
                                                          <LabelIcon fontSize="small" style={{ color: "#2196f3" }} />
                                                        </Tooltip>
                                                        :
                                                        f.normativa_iso == "Seguridad y Salud en el Trabajo" ?
                                                          <Tooltip title="Seguridad y Salud en el Trabajo">

                                                            <LabelIcon fontSize="small" style={{ color: "#fdd835" }} />
                                                          </Tooltip>
                                                          :
                                                          f.normativa_iso == "Medio Ambiente" ?
                                                            <Tooltip title="Medio Ambiente">
                                                              <LabelIcon fontSize="small" style={{ color: "#4caf50" }} />
                                                            </Tooltip>
                                                            :
                                                            // f.normativa_iso == "Liderazgo" ?
                                                            //   <Tooltip title="Liderazgo">
                                                            //     <LabelIcon fontSize="small" style={{ color: "#731f1f" }} />
                                                            //   </Tooltip>
                                                            //   :
                                                            //   f.normativa_iso == "Evaluación de Desempeño" ?
                                                            //     <Tooltip title="Evaluación de Desempeño">
                                                            //       <LabelIcon fontSize="small" style={{ color: "gray" }} />
                                                            //     </Tooltip>
                                                            //     :
                                                            f.normativa_iso == "SGI" ?
                                                              <Tooltip title="SGI">
                                                                <LabelIcon fontSize="small" style={{ color: "#731f1f" }} />
                                                              </Tooltip>
                                                              :
                                                              <Tooltip title="Sin clasificación SGI">
                                                                <LabelIcon fontSize="small" style={{ color: "white" }} />
                                                              </Tooltip>


                                                      }

                                                    </Box>
                                                  </Grid>

                                                </Grid>



                                              </Grid>
                                            ))}
                                        </Grid>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Grid>
                                ))}
                              </Fragment>
                            ))}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                    <Grid xs={12} style={{ textAlign: "center" }}>
                      <Accordion
                        style={{
                          borderRadius: "15px", backgroundColor: "lightgray", height: "100%",
                          clipPath: "polygon(94% 15px, 97% 0%, 100% 15px, 100% 100%,0% 100%, 0% 15px)"
                        }}
                        onChange={(e, expanded) => {
                          if (expanded) {
                            setAcordionSoporte(true);
                          } else {
                            setAcordionSoporte(false);
                          }
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMore style={{ color: "white" }} />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{
                            backgroundColor: "lightgray", borderRadius: "15px", paddingTop: "10px"

                          }}
                        >
                          <ViewModule fontSize="large" style={{ color: "#fddf5d", marginRight: "10px" }} />
                          <Typography variant="h5" fontWeight="bold" style={{ color: "white", }}>
                            Soporte
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ backgroundColor: "lightgray", borderRadius: "15px", padding: "0px", paddingLeft: "10px", paddingRight: "20px", paddingBottom: "30px" }}>
                          <Grid container style={{ width: "100%" }}>
                            {listaServicios && Array.from(listaServicios).map((d, index) => (
                              <Fragment>
                                {servicios && macroprocesos && Array.from(macroprocesos.data).filter(p => d._macroprocesos_ref.includes(p._id) && p.tipo_proceso == "Soporte").map((d, index) => (

                                  // <div>
                                  //   {d.tipo_proceso == "Estratégico" ? (
                                  <Grid item xs={4} style={{ maxWidth: "100%", padding: "5px" }}>

                                    <Accordion style={{ borderRadius: "15px" }}>
                                      <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{ backgroundColor: "#fddf5d", borderRadius: "15px", color: "black" }}
                                      >
                                        <Typography fontWeight="bold">{d.nombre}</Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Grid container style={{ width: "100%" }}>
                                          {fichas && Array.from(fichas.data).filter(p => d._fichas_ref.includes(p._id)
                                            && (normativaIso ? p.normativa_iso == normativaIso.nombre : d._fichas_ref.includes(p._id))).map((f, index) => (

                                              <Grid item xs={12} style={{ maxWidth: "100%", marginBottom: "7px" }}>
                                                <Grid container >
                                                  <Grid item xs={1}>
                                                    <Box
                                                      style={{ height: "100%" }}
                                                      display="flex"
                                                      alignItems="center">
                                                      {Moment(f.fecha_actualizacion) > Moment(f.fecha_creacion)
                                                        &&
                                                        Moment(f.fecha_actualizacion) > Moment(f.fecha_creacion).add(1, "week")
                                                        ?
                                                        Moment(f.fecha_actualizacion).add(2, "months") < Moment() ?
                                                          <Tooltip title="Modif. > 2 meses">
                                                            <FiberManualRecord fontSize="small" style={{ color: "#9e9e9e" }} />
                                                          </Tooltip>
                                                          :
                                                          <Tooltip title="Documento Actualizado">
                                                            <FiberManualRecord fontSize="small" style={{ color: "#00e5ff" }} />
                                                          </Tooltip>
                                                        :
                                                        <Tooltip title="Nuevo Documento">
                                                          <FiberManualRecord fontSize="small" style={{ color: "#76ff03" }} />
                                                        </Tooltip>
                                                      }

                                                    </Box>
                                                  </Grid>
                                                  <Grid item xs={10} style={{ paddingLeft: "3px", alignItems: "left" }}>
                                                    <Typography variant="subtitle2" display="flex" alignItems="left" onClick={abrirPopUpFicha.bind(null, "Soporte", d.nombre, f._id)} style={{ cursor: "pointer", color: "#731f1f" }}>

                                                      <strong>{f.codigo}</strong>

                                                      {/* <FiberManualRecord fontSize="small" style={{ marginRight: "8px" }} /> {f.nombre} */}
                                                    </Typography>
                                                    <Typography display="flex" alignItems="left" onClick={abrirPopUpFicha.bind(null, "Soporte", d.nombre, f._id)} style={{ cursor: "pointer" }}>
                                                      {/* {Moment(d.fecha_actualizacion) > Moment(d.fecha_creacion) ? <RestoreIcon /> : <NoteAddIcon />} */}
                                                      {f.nombre}

                                                    </Typography>
                                                  </Grid>
                                                  <Grid item xs={1}>
                                                    <Box
                                                      style={{ height: "100%" }}
                                                      display="flex"
                                                      alignItems="center">
                                                      {f.normativa_iso == "Sistemas de Gestión de Calidad" ?
                                                        <Tooltip title="Sistemas de Gestión de Calidad">
                                                          <LabelIcon fontSize="small" style={{ color: "#2196f3" }} />
                                                        </Tooltip>
                                                        :
                                                        f.normativa_iso == "Seguridad y Salud en el Trabajo" ?
                                                          <Tooltip title="Seguridad y Salud en el Trabajo">

                                                            <LabelIcon fontSize="small" style={{ color: "#fdd835" }} />
                                                          </Tooltip>
                                                          :
                                                          f.normativa_iso == "Medio Ambiente" ?
                                                            <Tooltip title="Medio Ambiente">
                                                              <LabelIcon fontSize="small" style={{ color: "#4caf50" }} />
                                                            </Tooltip>
                                                            :
                                                            // f.normativa_iso == "Liderazgo" ?
                                                            //   <Tooltip title="Liderazgo">
                                                            //     <LabelIcon fontSize="small" style={{ color: "#731f1f" }} />
                                                            //   </Tooltip>
                                                            //   :
                                                            //   f.normativa_iso == "Evaluación de Desempeño" ?
                                                            //     <Tooltip title="Evaluación de Desempeño">
                                                            //       <LabelIcon fontSize="small" style={{ color: "gray" }} />
                                                            //     </Tooltip>
                                                            //     :
                                                            f.normativa_iso == "SGI" ?
                                                              <Tooltip title="SGI">
                                                                <LabelIcon fontSize="small" style={{ color: "#731f1f" }} />
                                                              </Tooltip>
                                                              :
                                                              <Tooltip title="Sin clasificación SGI">
                                                                <LabelIcon fontSize="small" style={{ color: "white" }} />
                                                              </Tooltip>
                                                      }

                                                    </Box>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            ))}
                                        </Grid>
                                      </AccordionDetails>
                                    </Accordion>
                                  </Grid>
                                ))}
                              </Fragment>
                            ))}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <Box style={{ height: "115%", width: "100%", paddingLeft: "8px" }}>
                    <Button variant="contained" style={{
                      width: "80%", height: "100%", backgroundColor: "lightgray",
                      clipPath: "polygon(80% 0%,100% 10%,80% 20% , 80% 90%, 40% calc(90% + 20px) , 0 90%, 0 0%)"

                    }} onClick={handleDialogSalidasOpen}>
                      <Typography fontWeight="bold" variant="h6" style={{ transform: "rotate(270deg)", color: "#731f1f", paddingBottom: "10px" }}>
                        {/* <Typography fontWeight="bold" variant="caption" style={{ color: "#731f1f" }}> */}

                        Salidas
                      </Typography>
                    </Button>
                  </Box>
                </Grid>

                <Grid item xs={12} style={{ textAlign: "center", height: "80px", marginTop: "20px", padding: "10px" }}>
                  <Box
                    // triangulo="polygon(100% 0%, 100% 70%, 50% 90%, 50% 90%, 0 70%, 0 0)"
                    clipPath="polygon(100% 0%, 100% 70%, 50% 90%, 50% 90%, 0 70%, 0 0)"
                    display="flex" alignItems="center" justifyContent="center" style={{
                      height: "100%", width: "100%", backgroundColor: "gray",
                      clipPath: "polygon(100% 20%, 100% 100%, 100% 100%, 100% 100%, 0 100%, 0 20%, 3% 0, 6% 20%)"
                    }}>
                    <Typography variant="h5" fontWeight="bold" style={{ color: "white", paddingTop: "10px" }}>
                      Mejora Continua
                    </Typography>
                  </Box>

                </Grid>


                {/* SIMBOLOGIA */}
                {/* <Grid item xs={12} style={{ textAlign: "center", marginTop: "20px", padding: "10px" }}>
                  <Simbologia
                  />
                </Grid> */}
              </Grid>
            </Grid>


          </Grid>
        ) : (
          <Box style={{ width: "100%", marginRight: "20px" }}>
            <Grid container>
              <Grid item xs={12}>
                <Box style={{
                  height: "100%",
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                  <Typography style={{ marginRight: "10px" }}>
                    Buscar en: &nbsp;
                  </Typography>
                  <FormGroup row>

                    <FormControlLabel
                      // control={<Checkbox checked={state.procesos} onChange={handleChangeCheck} name="procesos" />}
                      control={<Checkbox
                        checked={checkProcesos}
                        onChange={
                          (event) => {
                          handleCheckProcesos();
                          }
                        }
                        name="procesos" />}

                      label="Procesos"
                    />
                    <FormControlLabel
                      control={<Checkbox 
                        checked={checkArchivos}
                        onChange={
                          (event) => {
                          handleCheckArchivos();
                          }
                        }
                        name="archivos" />}
                      label="Archivos"
                    />
                    <FormControlLabel
                      control={<Checkbox 
                        checked={checkContratos}
                        onChange={
                          (event) => {
                          handleCheckContratos();
                          }
                        }
                      name="contratos" />}
                      label="Contratos"
                    />
                    <FormControlLabel
                      control={<Checkbox 
                        checked={checkEtiquetas}
                        onChange={
                          (event) => {
                          handleCheckEtiquetas();
                          }
                        }
                      name="etiquetas" />}
                      label="Etiquetas"
                    />


                  </FormGroup>
                </Box>
              </Grid>
            </Grid>



            {/* BUSQUEDA DE PROCESOS */}
            {checkProcesos &&
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="h5" style={{ marginLeft: "20px", color: "#731f1f" }}>
                    <strong> Procesos </strong>

                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Typography variant="h6" style={{ marginLeft: "20px", color: "#731f1f" }}>
                    Encontrados: {Array.from(fichas.data).filter(p => (p.nombre.toLowerCase().includes(searchServicio) || p.codigo.toLowerCase().includes(searchServicio))).length}



                  </Typography>
                </Grid>
              </Grid>
            }
            {checkProcesos &&
              <Fragment>
                {fichas && checkProcesos && searchServicio && Array.from(fichas.data).filter(p => (p.nombre.toLowerCase().includes(searchServicio) || p.codigo.toLowerCase().includes(searchServicio))).map((d, index) => (
                  <Box style={{ width: "100%" }}>
                    <Paper variant="outlined" style={{
                      margin: "10px", width: "100%", padding: "10px",
                      backgroundColor:
                        "#f5f5f5"
                      , marginRight: "20px"
                    }}>
                      <Grid container>
                        <Grid xs={9}>
                          <Typography variant="h6">
                            <strong>Nombre Proceso: </strong> {d.nombre}
                          </Typography>
                          <Typography variant="h7">
                            <strong>Código: </strong> {d.codigo}
                          </Typography>
                          <br />
                          <Typography variant="h7" >
                            {macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(d._id)).map((j, index) => (
                              <div>
                                <strong>Tipo: {index == 0 && j.tipo_proceso}</strong>
                              </div>
                            ))}
                          </Typography>
                          <Typography variant="h7"
                            style={{
                              color: d.normativa_iso == "Sistemas de Gestión de Calidad" ? "#0069c0"
                                :
                                d.normativa_iso == "Seguridad y Salud en el Trabajo" ? "#c6a700"
                                  :
                                  d.normativa_iso == "Medio Ambiente" ? "#087f23"
                                    :
                                    d.normativa_iso == "SGI" ? "#731f1f"
                                      :
                                      "black"
                            }}>
                            <strong>Normativa ISO: </strong> {d.normativa_iso}
                          </Typography>
                          <br />
                          <Typography variant="h7">
                            <strong>Responsable: </strong> {d.responsable}
                          </Typography>
                          <br />
                          <Typography variant="h7">
                            <strong>Fecha de Actualización: </strong> {Moment(d.fecha_actualizacion).format("DD/MM/YYYY HH:mm")}
                          </Typography>


                        </Grid>
                        <Grid xs={3}>
                          <Box display="flex" onClick={abrirPopUpFicha.bind(null, (() => {
                            if (macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(d._id)).length > 0) {
                              return macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(d._id))[0].tipo_proceso;
                            } else {
                              return "No asignado"
                            }
                          })
                            ,
                            (() => {
                              if (macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(d._id)).length > 0) {
                                return macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(d._id))[0].nombre;
                              } else {
                                return "No asignado"
                              }
                            })
                            , d._id)} style={{
                              alignItems: "center", justifyContent: "center", cursor: "pointer", color:
                                macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(d._id))[0]?.tipo_proceso == "Estratégico" ? "#f28933"
                                  :
                                  macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(d._id))[0]?.tipo_proceso == "Operativo" ? "#86b25f"
                                    :
                                    macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(d._id))[0]?.tipo_proceso == "Soporte" ? "#fddf5d"
                                      :
                                      "gray"
                            }}>
                            <Assignment fontSize="large" style={{ marginRight: "8px" }} />
                            <Typography display="flex" alignItems="center" style={{ color: "black" }} >
                              Ver Ficha
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>

                    </Paper>
                  </Box>
                ))}
              </Fragment>
            }

            {/* MOSTRAR RESULTADOS DE ARCHIVOS */}
            {checkArchivos &&
              <Grid container>
                <Grid item xs={6}>
                  <Typography variant="h5" style={{ marginLeft: "20px", color: "#731f1f" }}>
                    <strong> Archivos </strong>

                  </Typography>
                </Grid>
              </Grid>
            }
            {checkArchivos &&
              <Fragment>
                <Paper variant="outlined" style={{
                  margin: "10px", width: "98%", padding: "10px",
                  backgroundColor:
                    "#fafafa"
                  , marginRight: "20px"
                }}>
                  {/* DOCUMENTOS ASOCIADOS */}
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="h6" style={{ color: "#731f1f" }}>
                        <strong> Documentos Asociados: </strong>

                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                      <Typography variant="h6" style={{ marginLeft: "20px", color: "#731f1f" }}>
                        Encontrados: {documentosAsociados && Array.from(documentosAsociados.data)
                          .filter(p => (p.documento && p.documento.nombre.toLowerCase().includes(searchServicio)))
                          .length}



                      </Typography>
                    </Grid>
                  </Grid>
                  {documentosAsociados && checkArchivos && searchServicio && Array.from(documentosAsociados.data)
                    .filter(p => (p.documento && p.documento.nombre.toLowerCase().includes(searchServicio)))
                    .map((d, index) => (
                      <Box style={{ width: "100%", marginLeft: "10px" }}>
                        {/* <Paper variant="outlined" style={{
                        margin: "10px", width: "97%", padding: "10px",
                        backgroundColor:
                          "#f5f5f5"
                        , marginRight: "20px"
                      }}> */}
                        <Grid container>

                          <Grid xs={9}>
                            <Typography variant="h6">
                              <strong>{index + 1}. &nbsp;Nombre: </strong> {d.documento.nombre}
                            </Typography>
                          </Grid>
                          <Grid xs={3}>
                            <Box style={{
                              height: "100%",
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}>
                              <Button
                                href={d.documento.url}
                                target="_blank"
                                style={{
                                  color: "black",
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                <Description fontSize="medium" style={{ marginRight: "8px", color: "gray" }} />
                                <Typography display="flex" alignItems="center" style={{ color: "black" }} >
                                  Ver Archivo
                                </Typography>
                              </Button>
                            </Box>
                          </Grid>
                          <Grid xs={12} style={{ paddingLeft: "10px" }}>
                            <Typography>
                              <i>Encontrado en:</i>
                            </Typography>
                          </Grid>
                          <Grid xs={12}>

                            {/* -------------------- */}

                            {fichas && searchServicio && Array.from(fichas.data).filter(p => (p._documentosAsociados_ref.includes(d._id))).map((f, index) => (
                              <Box style={{ width: "100%" }}>
                                <Paper variant="outlined" style={{
                                  marginBottom: "10px", marginRight: "10px", marginLeft: "10px", width: "96%", padding: "10px",
                                  backgroundColor:
                                    "#f5f5f5"
                                  , marginRight: "20px"
                                }}>
                                  <Grid container>
                                    <Grid xs={9}>
                                      <Typography variant="h6">
                                        <strong>Nombre Proceso: </strong> {f.nombre}
                                      </Typography>
                                      <Typography variant="h7">
                                        <strong>Código: </strong> {f.codigo}
                                      </Typography>
                                      <br />
                                      <Typography variant="h7" >
                                        {macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(f._id)).map((j, index) => (
                                          <div>
                                            <strong>Tipo: {index == 0 && j.tipo_proceso}</strong>
                                          </div>
                                        ))}
                                      </Typography>
                                      <Typography variant="h7"
                                        style={{
                                          color: f.normativa_iso == "Sistemas de Gestión de Calidad" ? "#0069c0"
                                            :
                                            f.normativa_iso == "Seguridad y Salud en el Trabajo" ? "#c6a700"
                                              :
                                              f.normativa_iso == "Medio Ambiente" ? "#087f23"
                                                :
                                                f.normativa_iso == "SGI" ? "#731f1f"
                                                  :
                                                  "black"
                                        }}>
                                        <strong>Normativa ISO: </strong> {f.normativa_iso}
                                      </Typography>
                                      <br />
                                      <Typography variant="h7">
                                        <strong>Responsable: </strong> {f.responsable}
                                      </Typography>
                                      <br />
                                      <Typography variant="h7">
                                        <strong>Fecha de Actualización: </strong> {Moment(f.fecha_actualizacion).format("DD/MM/YYYY HH:mm")}
                                      </Typography>


                                    </Grid>
                                    <Grid xs={3}>
                                      <Box display="flex" onClick={abrirPopUpFicha.bind(null, (() => {
                                        if (macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(f._id)).length > 0) {
                                          return macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(f._id))[0].tipo_proceso;
                                        } else {
                                          return "No asignado"
                                        }
                                      })
                                        ,
                                        (() => {
                                          if (macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(f._id)).length > 0) {
                                            return macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(f._id))[0].nombre;
                                          } else {
                                            return "No asignado"
                                          }
                                        })
                                        , f._id)} style={{
                                          alignItems: "center", justifyContent: "center", cursor: "pointer", color:
                                            macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(f._id))[0]?.tipo_proceso == "Estratégico" ? "#f28933"
                                              :
                                              macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(f._id))[0]?.tipo_proceso == "Operativo" ? "#86b25f"
                                                :
                                                macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(f._id))[0]?.tipo_proceso == "Soporte" ? "#fddf5d"
                                                  :
                                                  "gray"
                                        }}>
                                        <Assignment fontSize="large" style={{ marginRight: "8px" }} />
                                        <Typography display="flex" alignItems="center" style={{ color: "black" }} >
                                          Ver Ficha
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>

                                </Paper>
                              </Box>
                            ))}

                          </Grid>

                        </Grid>


                        {/* </Paper> */}
                        <Divider style={{ marginTop: "10px", marginBottom: "10px", backgroundColor: "#731f1f", width: "98%" }} />
                      </Box>

                    ))}
                  <Divider style={{ marginTop: "10px", marginBottom: "10px", backgroundColor: "black" }} />


                  {/* PROVEEDORES DE INFORMACIÓN/ENTRADAS                  */}
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="h6" style={{ color: "#731f1f" }}>
                        <strong> Entradas (Proveedores de Información): </strong>

                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                      <Typography variant="h6" style={{ marginLeft: "20px", color: "#731f1f" }}>
                        Encontrados: {entradas && Array.from(entradas.data)
                          .filter(p => (p.entrada && p.entrada.nombre.toLowerCase().includes(searchServicio)))
                          .length}



                      </Typography>
                    </Grid>
                  </Grid>
                  {entradas && checkArchivos && searchServicio && Array.from(entradas.data)
                    .filter(p => (p.entrada && p.entrada.nombre.toLowerCase().includes(searchServicio)))
                    .map((d, index) => (
                      <Box style={{ width: "100%", marginLeft: "10px" }}>
                        <Grid container>
                          <Grid xs={9}>
                            <Typography variant="h6">
                              <strong>{index + 1}. &nbsp;Nombre: </strong> {d.entrada.nombre}
                            </Typography>
                          </Grid>
                          <Grid xs={3}>
                            <Box style={{
                              height: "100%",
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}>
                              <Button
                                href={d.entrada.url}
                                target="_blank"
                                style={{
                                  color: "black",
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                <Description fontSize="medium" style={{ marginRight: "8px", color: "gray" }} />
                                <Typography display="flex" alignItems="center" style={{ color: "black" }} >
                                  Ver Archivo
                                </Typography>
                              </Button>
                            </Box>
                          </Grid>
                          <Grid xs={12} style={{ paddingLeft: "10px" }}>
                            <Typography>
                              <i>Encontrado en:</i>
                            </Typography>
                          </Grid>
                          <Grid xs={12}>
                            {proveedorInformacion && searchServicio && Array.from(proveedorInformacion.data).filter(p => (p._entradas_ref.includes(d._id))).map((f, index) => (
                              <Box style={{ width: "100%" }}>
                                <Paper variant="outlined" style={{
                                  marginBottom: "10px", marginRight: "10px", marginLeft: "10px", width: "96%", padding: "10px",
                                  backgroundColor:
                                    "#f5f5f5"
                                  , marginRight: "20px"
                                }}>
                                  <Grid container>
                                    <Grid xs={9}>
                                      <Typography variant="h7">
                                        <strong>Nombre Proveedor: </strong> {f.proveedor}
                                      </Typography>
                                      <br />
                                      <Typography variant="h7">
                                        <strong>Cargo: </strong> {f.codigo}
                                      </Typography>

                                    </Grid>
                                    <Grid xs={12} style={{ paddingLeft: "10px", marginTop: "10px" }}>
                                      <Typography>
                                        <i>Proveedor de información asignado a:</i>
                                      </Typography>
                                    </Grid>

                                    <Grid xs={12}>

                                      {/* -------------------- */}

                                      {fichas && searchServicio && Array.from(fichas.data).filter(p => (p._proveedoresInformacion_ref.includes(f._id))).map((k, index) => (
                                        <Box style={{ width: "100%" }}>
                                          <Paper variant="outlined" style={{
                                            marginBottom: "10px", marginRight: "10px", marginLeft: "10px", width: "96%", padding: "10px",
                                            backgroundColor:
                                              "white"
                                            , marginRight: "20px"
                                          }}>
                                            <Grid container>
                                              <Grid xs={9}>
                                                <Typography variant="h6">
                                                  <strong>Nombre Proceso: </strong> {k.nombre}
                                                </Typography>
                                                <Typography variant="h7">
                                                  <strong>Código: </strong> {k.codigo}
                                                </Typography>
                                                <br />
                                                <Typography variant="h7" >
                                                  {macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(k._id)).map((j, index) => (
                                                    <div>
                                                      <strong>Tipo: {index == 0 && j.tipo_proceso}</strong>
                                                    </div>
                                                  ))}
                                                </Typography>
                                                <Typography variant="h7"
                                                  style={{
                                                    color: k.normativa_iso == "Sistemas de Gestión de Calidad" ? "#0069c0"
                                                      :
                                                      k.normativa_iso == "Seguridad y Salud en el Trabajo" ? "#c6a700"
                                                        :
                                                        k.normativa_iso == "Medio Ambiente" ? "#087f23"
                                                          :
                                                          k.normativa_iso == "SGI" ? "#731f1f"
                                                            :
                                                            "black"
                                                  }}>
                                                  <strong>Normativa ISO: </strong> {k.normativa_iso}
                                                </Typography>
                                                <br />
                                                <Typography variant="h7">
                                                  <strong>Responsable: </strong> {k.responsable}
                                                </Typography>
                                                <br />
                                                <Typography variant="h7">
                                                  <strong>Fecha de Actualización: </strong> {Moment(k.fecha_actualizacion).format("DD/MM/YYYY HH:mm")}
                                                </Typography>


                                              </Grid>
                                              <Grid xs={3}>
                                                <Box display="flex" onClick={abrirPopUpFicha.bind(null, (() => {
                                                  if (macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(k._id)).length > 0) {
                                                    return macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(k._id))[0].tipo_proceso;
                                                  } else {
                                                    return "No asignado"
                                                  }
                                                })
                                                  ,
                                                  (() => {
                                                    if (macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(k._id)).length > 0) {
                                                      return macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(k._id))[0].nombre;
                                                    } else {
                                                      return "No asignado"
                                                    }
                                                  })
                                                  , f._id)} style={{
                                                    alignItems: "center", justifyContent: "right", cursor: "pointer", color:
                                                      macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(k._id))[0]?.tipo_proceso == "Estratégico" ? "#f28933"
                                                        :
                                                        macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(k._id))[0]?.tipo_proceso == "Operativo" ? "#86b25f"
                                                          :
                                                          macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(k._id))[0]?.tipo_proceso == "Soporte" ? "#fddf5d"
                                                            :
                                                            "gray"
                                                  }}>
                                                  <Assignment fontSize="large" style={{ marginRight: "8px" }} />
                                                  <Typography display="flex" alignItems="right" style={{ color: "black" }} >
                                                    Ver Ficha
                                                  </Typography>
                                                </Box>
                                              </Grid>
                                            </Grid>

                                          </Paper>
                                        </Box>
                                      ))}

                                    </Grid>

                                  </Grid>

                                </Paper>
                              </Box>
                            ))}

                          </Grid>

                        </Grid>


                        {/* </Paper> */}
                        <Divider style={{ marginTop: "10px", marginBottom: "10px", backgroundColor: "#731f1f", width: "98%" }} />
                      </Box>

                    ))}
                  <Divider style={{ marginTop: "10px", marginBottom: "10px", backgroundColor: "black" }} />

                  {/* CLIENTES ENTREGABLES */}

                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="h6" style={{ color: "#731f1f" }}>
                        <strong> Entregables (Clientes): </strong>

                      </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                      <Typography variant="h6" style={{ marginLeft: "20px", color: "#731f1f" }}>
                        Encontrados: {entregables && Array.from(entregables.data)
                          .filter(p => (p.documento && p.documento.nombre.toLowerCase().includes(searchServicio)))
                          .length}



                      </Typography>
                    </Grid>
                  </Grid>
                  {entregables && checkArchivos && searchServicio && Array.from(entregables.data)
                    .filter(p => (p.documento && p.documento.nombre.toLowerCase().includes(searchServicio)))
                    .map((d, index) => (
                      <Box style={{ width: "100%", marginLeft: "10px" }}>
                        <Grid container>
                          <Grid xs={9}>
                            <Typography variant="h6">
                              <strong>{index + 1}. &nbsp;Nombre: </strong> {d.documento.nombre}
                            </Typography>
                          </Grid>
                          <Grid xs={3}>
                            <Box style={{
                              height: "100%",
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}>
                              <Button
                                href={d.documento.url}
                                target="_blank"
                                style={{
                                  color: "black",
                                  textDecoration: "none",
                                  textTransform: "none",
                                }}
                              >
                                <Description fontSize="medium" style={{ marginRight: "8px", color: "gray" }} />
                                <Typography display="flex" alignItems="center" style={{ color: "black" }} >
                                  Ver Archivo
                                </Typography>
                              </Button>
                            </Box>
                          </Grid>
                          <Grid xs={12} style={{ paddingLeft: "10px" }}>
                            <Typography>
                              <i>Encontrado en:</i>
                            </Typography>
                          </Grid>
                          <Grid xs={12}>
                            {clientes && searchServicio && Array.from(clientes.data).filter(p => (p._entregable_ref.includes(d._id))).map((f, index) => (
                              <Box style={{ width: "100%" }}>
                                <Paper variant="outlined" style={{
                                  marginBottom: "10px", marginRight: "10px", marginLeft: "10px", width: "96%", padding: "10px",
                                  backgroundColor:
                                    "#f5f5f5"
                                  , marginRight: "20px"
                                }}>
                                  <Grid container>
                                    <Grid xs={9}>
                                      <Typography variant="h7">
                                        <strong>Nombre Cliente: </strong> {f.cliente}
                                      </Typography>
                                      <br />
                                      <Typography variant="h7">
                                        <strong>Cargo: </strong> {f.codigo}
                                      </Typography>

                                    </Grid>
                                    <Grid xs={12} style={{ paddingLeft: "10px", marginTop: "10px" }}>
                                      <Typography>
                                        <i>Cliente asignado a:</i>
                                      </Typography>
                                    </Grid>

                                    <Grid xs={12}>

                                      {/* -------------------- */}

                                      {fichas && searchServicio && Array.from(fichas.data).filter(p => (p._entregableCliente_ref.includes(f._id))).map((k, index) => (
                                        <Box style={{ width: "100%" }}>
                                          <Paper variant="outlined" style={{
                                            marginBottom: "10px", marginRight: "10px", marginLeft: "10px", width: "96%", padding: "10px",
                                            backgroundColor:
                                              "white"
                                            , marginRight: "20px"
                                          }}>
                                            <Grid container>
                                              <Grid xs={9}>
                                                <Typography variant="h6">
                                                  <strong>Nombre Proceso: </strong> {k.nombre}
                                                </Typography>
                                                <Typography variant="h7">
                                                  <strong>Código: </strong> {k.codigo}
                                                </Typography>
                                                <br />
                                                <Typography variant="h7" >
                                                  {macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(k._id)).map((j, index) => (
                                                    <div>
                                                      <strong>Tipo: {index == 0 && j.tipo_proceso}</strong>
                                                    </div>
                                                  ))}
                                                </Typography>
                                                <Typography variant="h7"
                                                  style={{
                                                    color: k.normativa_iso == "Sistemas de Gestión de Calidad" ? "#0069c0"
                                                      :
                                                      k.normativa_iso == "Seguridad y Salud en el Trabajo" ? "#c6a700"
                                                        :
                                                        k.normativa_iso == "Medio Ambiente" ? "#087f23"
                                                          :
                                                          k.normativa_iso == "SGI" ? "#731f1f"
                                                            :
                                                            "black"
                                                  }}>
                                                  <strong>Normativa ISO: </strong> {k.normativa_iso}
                                                </Typography>
                                                <br />
                                                <Typography variant="h7">
                                                  <strong>Responsable: </strong> {k.responsable}
                                                </Typography>
                                                <br />
                                                <Typography variant="h7">
                                                  <strong>Fecha de Actualización: </strong> {Moment(k.fecha_actualizacion).format("DD/MM/YYYY HH:mm")}
                                                </Typography>


                                              </Grid>
                                              <Grid xs={3}>
                                                <Box display="flex" onClick={abrirPopUpFicha.bind(null, (() => {
                                                  if (macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(k._id)).length > 0) {
                                                    return macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(k._id))[0].tipo_proceso;
                                                  } else {
                                                    return "No asignado"
                                                  }
                                                })
                                                  ,
                                                  (() => {
                                                    if (macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(k._id)).length > 0) {
                                                      return macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(k._id))[0].nombre;
                                                    } else {
                                                      return "No asignado"
                                                    }
                                                  })
                                                  , f._id)} style={{
                                                    alignItems: "center", justifyContent: "right", cursor: "pointer", color:
                                                      macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(k._id))[0]?.tipo_proceso == "Estratégico" ? "#f28933"
                                                        :
                                                        macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(k._id))[0]?.tipo_proceso == "Operativo" ? "#86b25f"
                                                          :
                                                          macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(k._id))[0]?.tipo_proceso == "Soporte" ? "#fddf5d"
                                                            :
                                                            "gray"
                                                  }}>
                                                  <Assignment fontSize="large" style={{ marginRight: "8px" }} />
                                                  <Typography display="flex" alignItems="right" style={{ color: "black" }} >
                                                    Ver Ficha
                                                  </Typography>
                                                </Box>
                                              </Grid>
                                            </Grid>

                                          </Paper>
                                        </Box>
                                      ))}

                                    </Grid>

                                  </Grid>

                                </Paper>
                              </Box>
                            ))}

                          </Grid>

                        </Grid>


                        {/* </Paper> */}
                        <Divider style={{ marginTop: "10px", marginBottom: "10px", backgroundColor: "#731f1f", width: "98%" }} />
                      </Box>

                    ))}

                </Paper>
              </Fragment>
            }

            {/* BUSQEUDA DE CONTRATOS */}
            {checkContratos &&
              <Grid container style={{ marginBottom: "10px", marginTop: "25px" }}>
                <Grid item xs={6}>
                  <Typography variant="h5" style={{ marginLeft: "20px", color: "#731f1f" }}>
                    <strong> Contratos </strong>



                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Typography variant="h6" style={{ marginLeft: "20px", color: "#731f1f" }}>
                    Encontrados: {servicios && searchServicio && Array.from(servicios.data).filter(p => p.nombre.toLowerCase().includes(searchServicio)).length}



                  </Typography>
                </Grid>
              </Grid>
            }
            {checkContratos &&
              <Fragment>
                {servicios && checkContratos && searchServicio && Array.from(servicios.data).filter(p => p.nombre.toLowerCase().includes(searchServicio)).map((d, index) => (

                  <Fragment>
                    <Accordion
                      expanded={expanded === `panel_${index}`}
                      onChange={handleChange(`panel_${index}`)}

                      style={{ marginBottom: "5px", borderRadius: "20px", marginLeft: "20px", backgroundColor: "#eceff1" }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      // style={{backgroundColor:"#731f1f"}}
                      >
                        <Typography
                          gutterBottom
                          variant="body1"
                          color="#731f1f"
                        >
                          <strong>Nombre del contrato: {d.nombre} </strong>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ backgroundColor: "#fafafa" }}
                      >
                        <div>
                          {macroprocesos && searchServicio && Array.from(macroprocesos.data).filter(p => d._macroprocesos_ref.includes(p._id)).map((j, index) => (

                            <Grid container style={{ marginBottom: "25px" }}>
                              <Grid item xs={12} >
                                <Typography variant="h6" style={{ marginLeft: "5px", color: "#731f1f" }}>
                                  Macroproceso: <strong>{j.nombre} </strong> <i>[{j.tipo_proceso}] </i>
                                </Typography>
                              </Grid>


                              {/* <Box style={{ width: "100%" }}> */}
                              {fichas && searchServicio && Array.from(fichas.data).filter(k => j._fichas_ref.includes(k._id)).map((m, index) => (
                                <Grid item xs={12}>
                                  <Paper variant="outlined" style={{ margin: "10px", width: "95%", padding: "10px", backgroundColor: "#f5f5f5", marginLeft: "20px" }}>
                                    <Grid container>
                                      <Grid xs={9}>
                                        <Typography variant="h6">
                                          <strong>Nombre Proceso: </strong> {m.nombre}
                                        </Typography>
                                        <Typography variant="h7">
                                          <strong>Código: </strong> {m.codigo}
                                        </Typography>
                                        <br />
                                        <Typography variant="h7" style={{
                                          color: m.normativa_iso == "Sistemas de Gestión de Calidad" ? "#0069c0"
                                            :
                                            m.normativa_iso == "Seguridad y Salud en el Trabajo" ? "#c6a700"
                                              :
                                              m.normativa_iso == "Medio Ambiente" ? "#087f23"
                                                :
                                                m.normativa_iso == "SGI" ? "#731f1f"
                                                  :
                                                  "black"
                                        }}>
                                          <strong>Normativa ISO: </strong> {m.normativa_iso}
                                        </Typography>
                                        <br />
                                        <Typography variant="h7">
                                          <strong>Responsable: </strong> {m.responsable}
                                        </Typography>
                                        <br />
                                        <Typography variant="h7">
                                          <strong>Fecha de Actualización: </strong> {Moment(m.fecha_actualizacion).format("DD/MM/YYYY HH:mm")}
                                        </Typography>


                                      </Grid>
                                      <Grid xs={3}>

                                        <Box display="flex" onClick={abrirPopUpFicha.bind(null, (() => {
                                          if (macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(m._id)).length > 0) {
                                            return macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(m._id))[0].tipo_proceso;
                                          } else {
                                            return "No asignado"
                                          }
                                        })
                                          ,
                                          (() => {
                                            if (macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(m._id)).length > 0) {
                                              return macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(m._id))[0].nombre;
                                            } else {
                                              return "No asignado"
                                            }
                                          })
                                          , d._id)} style={{
                                            alignItems: "center", justifyContent: "center", cursor: "pointer", color:
                                              macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(m._id))[0]?.tipo_proceso == "Estratégico" ? "#f28933"
                                                :
                                                macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(m._id))[0]?.tipo_proceso == "Operativo" ? "#86b25f"
                                                  :
                                                  macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(m._id))[0]?.tipo_proceso == "Soporte" ? "#fddf5d"
                                                    :
                                                    "gray"
                                          }}>
                                          <Assignment fontSize="large" style={{ marginRight: "8px" }} />
                                          <Typography display="flex" alignItems="center" style={{ color: "black" }} >
                                            Ver Ficha
                                          </Typography>
                                        </Box>

                                      </Grid>
                                    </Grid>

                                  </Paper>
                                </Grid>
                              ))}
                              {/* </Box> */}


                            </Grid>
                          ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    {/* <Typography variant="h6" style={{ marginLeft: "20px" }}>
                  <strong>Nombre del contrato: {d.nombre} </strong>
                </Typography> */}

                  </Fragment>
                ))}
              </Fragment>
            }

            {/* BUSQUEDA DE ETIQUETAS */}
            {checkEtiquetas &&
              <Grid container style={{ marginTop: "25px" }}>
                <Grid item xs={12}>
                  <Typography variant="h5" style={{ marginLeft: "20px", color: "#731f1f" }}>
                    <strong> Etiquetas: {searchServicio}... </strong>



                  </Typography>
                </Grid>
              </Grid>
            }
            {checkEtiquetas &&
              <Fragment>
                {fichas && checkEtiquetas && Array.from(fichas.data).map((d, index) => (
                  <Fragment>
                    {etiquetas && Array.from(etiquetas.data).filter(f => d.etiquetas_ref && d.etiquetas_ref.includes(f._id)).map((k, index) =>
                      <Fragment>
                        {k.nombre && k.nombre.toLowerCase().includes(searchServicio) ? (
                          <Fragment>
                            <Grid item xs={12}>
                              <Paper variant="outlined" style={{ margin: "10px", width: "95%", padding: "10px", backgroundColor: "#f5f5f5", marginRight: "20px" }}>
                                <Grid container>
                                  <Grid xs={9}>
                                    <Typography variant="h6">
                                      <strong>Nombre Proceso: </strong> {d.nombre}
                                    </Typography>
                                    <Typography variant="h7">
                                      <strong>Código: </strong> {d.codigo}
                                    </Typography>
                                    <br />
                                    <Typography variant="h7" >
                                      {macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(d._id)).map((j, index) => (
                                        <div>
                                          <strong>Tipo:</strong> {index == 0 && j.tipo_proceso}
                                        </div>
                                      ))}
                                    </Typography>
                                    <Typography variant="h7" style={{
                                      color: d.normativa_iso == "Sistemas de Gestión de Calidad" ? "#0069c0"
                                        :
                                        d.normativa_iso == "Seguridad y Salud en el Trabajo" ? "#c6a700"
                                          :
                                          d.normativa_iso == "Medio Ambiente" ? "#087f23"
                                            :
                                            d.normativa_iso == "SGI" ? "#731f1f"
                                              :
                                              "black"
                                    }}>
                                      <strong>Normativa ISO: </strong> {d.normativa_iso}
                                    </Typography>
                                    <br />
                                    <Typography variant="h7">
                                      <strong>Responsable: </strong> {d.responsable}
                                    </Typography>
                                    <br />
                                    <Typography variant="h7">
                                      <strong>Fecha de Actualización: </strong> {Moment(d.fecha_actualizacion).format("DD/MM/YYYY HH:mm")}
                                    </Typography>


                                  </Grid>
                                  <Grid xs={3}>

                                    <Box display="flex" onClick={abrirPopUpFicha.bind(null, (() => {
                                      if (macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(d._id)).length > 0) {
                                        return macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(d._id))[0].tipo_proceso;
                                      } else {
                                        return "No asignado"
                                      }
                                    })
                                      ,
                                      (() => {
                                        if (macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(d._id)).length > 0) {
                                          return macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(d._id))[0].nombre;
                                        } else {
                                          return "No asignado"
                                        }
                                      })
                                      , d._id)} style={{
                                        alignItems: "center", justifyContent: "center", cursor: "pointer", color:
                                          macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(d._id))[0]?.tipo_proceso == "Estratégico" ? "#f28933"
                                            :
                                            macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(d._id))[0]?.tipo_proceso == "Operativo" ? "#86b25f"
                                              :
                                              macroprocesos && fichas && Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(d._id))[0]?.tipo_proceso == "Soporte" ? "#fddf5d"
                                                :
                                                "gray"
                                      }}>
                                      <Assignment fontSize="large" style={{ marginRight: "8px" }} />
                                      <Typography display="flex" alignItems="center" style={{ color: "black" }} >
                                        Ver Ficha
                                      </Typography>
                                    </Box>

                                  </Grid>
                                </Grid>
                              </Paper>
                            </Grid>
                          </Fragment>
                        ) : (
                          <Grid container style={{ marginTop: "25px" }}>
                            <Grid item xs={12}>
                            </Grid>
                          </Grid>
                        )
                        }

                      </Fragment>
                    )}
                  </Fragment>
                ))}
              </Fragment>
            }
          </Box>

        )}


      </Grid >

      <Dialog fullScreen open={openDialog} onClose={handleDialogClose} maxWidth="xl" fullWidth >
        {/* <DialogTitle>
          asdasdasdasdasd
        </DialogTitle> */}
        
        <DialogContent dividers style={{ backgroundColor: "lightgray" }}>

          <Ficha handleDialogClose={handleDialogClose} macroproceso={macroproceso_name} tipo_proceso={tipo_proceso} idFicha={idFicha} />


        </DialogContent>
        {/* <DialogActions style={{ backgroundColor: "lightgray" }}>
          <Button onClick={handleDialogClose} variant="outlined" color="primary">
            Cerrar
          </Button>
        </DialogActions> */}
      </Dialog>

      <Dialog open={openDialogEntradas} maxWidth="xl" fullWidth >
        <DialogTitle>
          <Typography variant="h4">
            Entradas
          </Typography>
        </DialogTitle>
        <DialogContent dividers>

          {/* <Ficha macroproceso={macroproceso_name} tipo_proceso={tipo_proceso} idFicha={idFicha} /> */}
          <Grid container style={{ width: "100%" }}>
            {listaServicios && Array.from(listaServicios).map((d, index) => (
              <Fragment>
                <Typography variant="h5">
                  Estratégico
                </Typography>
                {servicios && macroprocesos && Array.from(macroprocesos.data).filter(p => servicios.data[0]._macroprocesos_ref.includes(p._id) && p.tipo_proceso == "Estratégico").map((d, index) => (

                  // <div>
                  //   {d.tipo_proceso == "Estratégico" ? (
                  <Grid item xs={12} style={{ maxWidth: "100%", padding: "5px" }}>


                    <Accordion style={{ borderRadius: "15px" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ backgroundColor: "lightgray", borderRadius: "15px" }}
                      >
                        <Typography fontWeight="bold">{d.nombre}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container style={{ width: "100%" }}>
                          {fichas && Array.from(fichas.data).filter(p => d._fichas_ref.includes(p._id)
                            && (normativaIso ? p.normativa_iso == normativaIso.nombre : d._fichas_ref.includes(p._id))).map((f, index) => (

                              // <div>
                              //   {d.tipo_proceso == "Estratégico" ? (
                              <Grid item xs={12} style={{ maxWidth: "100%", marginBottom: "7px" }}>

                                <Accordion variant="outlined">
                                  <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    style={{ backgroundColor: "white" }}

                                  >
                                    <Grid container >

                                      <Grid item xs={12} style={{ paddingLeft: "3px", alignItems: "left" }}>
                                        <Typography variant="subtitle2" display="flex" alignItems="left" style={{ cursor: "pointer", color: "#731f1f" }}>

                                          {f.codigo} | {f.nombre}

                                          {/* <FiberManualRecord fontSize="small" style={{ marginRight: "8px" }} /> {f.nombre} */}
                                        </Typography>
                                      </Grid>

                                    </Grid>
                                  </AccordionSummary>
                                  <AccordionDetails>

                                    <Fragment>
                                      <Grid container>
                                        {proveedorInformacion && Array.from(proveedorInformacion.data).filter(h => f._proveedoresInformacion_ref.includes(h._id)).map((g, index) => (
                                          <Grid item xs={12}>
                                            <Typography>
                                              {index + 1}. Proveedor de Información: [{g.codigo}] {g.proveedor}
                                              <br />
                                            </Typography>
                                            {entradas && Array.from(entradas.data).filter(j => g._entradas_ref.includes(j._id)).map((k, index) => (

                                              <Grid item xs={12}>
                                                <Button
                                                  // component={Link}
                                                  href={k.entrada.url}
                                                  target="_blank"
                                                  style={{
                                                    color: "black",
                                                    textDecoration: "none",
                                                    textTransform: "none",
                                                    paddingLeft: "20px"
                                                  }}
                                                >
                                                  Entrada n°{index + 1}:<strong> {k.entrada.nombre}</strong>
                                                </Button>
                                              </Grid>
                                            ))}
                                          </Grid>
                                        ))}
                                      </Grid>

                                    </Fragment>



                                  </AccordionDetails>
                                </Accordion>




                              </Grid>
                            ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}

                {/* Operativo */}
                <Typography variant="h5" style={{ marginTop: "15px" }}>
                  Operativo
                </Typography>
                {servicios && macroprocesos && Array.from(macroprocesos.data).filter(p => servicios.data[0]._macroprocesos_ref.includes(p._id) && p.tipo_proceso == "Operativo").map((d, index) => (

                  // <div>
                  //   {d.tipo_proceso == "Estratégico" ? (
                  <Grid item xs={12} style={{ maxWidth: "100%", padding: "5px" }}>


                    <Accordion style={{ borderRadius: "15px" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ backgroundColor: "lightgray", borderRadius: "15px" }}
                      >
                        <Typography fontWeight="bold">{d.nombre}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container style={{ width: "100%" }}>
                          {fichas && Array.from(fichas.data).filter(p => d._fichas_ref.includes(p._id)
                            && (normativaIso ? p.normativa_iso == normativaIso.nombre : d._fichas_ref.includes(p._id))).map((f, index) => (

                              // <div>
                              //   {d.tipo_proceso == "Estratégico" ? (
                              <Grid item xs={12} style={{ maxWidth: "100%", marginBottom: "7px" }}>

                                <Accordion variant="outlined">
                                  <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    style={{ backgroundColor: "white" }}

                                  >
                                    <Grid container >

                                      <Grid item xs={12} style={{ paddingLeft: "3px", alignItems: "left" }}>
                                        <Typography variant="subtitle2" display="flex" alignItems="left" style={{ cursor: "pointer", color: "#731f1f" }}>

                                          {f.codigo} | {f.nombre}

                                          {/* <FiberManualRecord fontSize="small" style={{ marginRight: "8px" }} /> {f.nombre} */}
                                        </Typography>
                                      </Grid>

                                    </Grid>
                                  </AccordionSummary>
                                  <AccordionDetails>

                                    <Fragment>
                                      <Grid container>
                                        {proveedorInformacion && Array.from(proveedorInformacion.data).filter(h => f._proveedoresInformacion_ref.includes(h._id)).map((g, index) => (
                                          <Grid item xs={12}>
                                            <Typography>
                                              {index + 1}. Proveedor de Información: [{g.codigo}] {g.proveedor}
                                              <br />
                                            </Typography>
                                            {entradas && Array.from(entradas.data).filter(j => g._entradas_ref.includes(j._id)).map((k, index) => (

                                              <Grid item xs={12}>
                                                <Button
                                                  // component={Link}
                                                  href={k.entrada.url}
                                                  target="_blank"
                                                  style={{
                                                    color: "black",
                                                    textDecoration: "none",
                                                    textTransform: "none",
                                                    paddingLeft: "20px"
                                                  }}
                                                >
                                                  Entrada n°{index + 1}:<strong> {k.entrada.nombre}</strong>
                                                </Button>
                                              </Grid>
                                            ))}
                                          </Grid>
                                        ))}
                                      </Grid>

                                    </Fragment>



                                  </AccordionDetails>
                                </Accordion>




                              </Grid>
                            ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}

                {/* Soporte */}
                <Typography variant="h5" style={{ marginTop: "15px" }}>
                  Soporte
                </Typography>
                {servicios && macroprocesos && Array.from(macroprocesos.data).filter(p => servicios.data[0]._macroprocesos_ref.includes(p._id) && p.tipo_proceso == "Soporte").map((d, index) => (

                  // <div>
                  //   {d.tipo_proceso == "Estratégico" ? (
                  <Grid item xs={12} style={{ maxWidth: "100%", padding: "5px" }}>


                    <Accordion style={{ borderRadius: "15px" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ backgroundColor: "lightgray", borderRadius: "15px" }}
                      >
                        <Typography fontWeight="bold">{d.nombre}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container style={{ width: "100%" }}>
                          {fichas && Array.from(fichas.data).filter(p => d._fichas_ref.includes(p._id)
                            && (normativaIso ? p.normativa_iso == normativaIso.nombre : d._fichas_ref.includes(p._id))).map((f, index) => (

                              // <div>
                              //   {d.tipo_proceso == "Estratégico" ? (
                              <Grid item xs={12} style={{ maxWidth: "100%", marginBottom: "7px" }}>

                                <Accordion variant="outlined">
                                  <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    style={{ backgroundColor: "white" }}

                                  >
                                    <Grid container >

                                      <Grid item xs={12} style={{ paddingLeft: "3px", alignItems: "left" }}>
                                        <Typography variant="subtitle2" display="flex" alignItems="left" style={{ cursor: "pointer", color: "#731f1f" }}>

                                          {f.codigo} | {f.nombre}

                                          {/* <FiberManualRecord fontSize="small" style={{ marginRight: "8px" }} /> {f.nombre} */}
                                        </Typography>
                                      </Grid>

                                    </Grid>
                                  </AccordionSummary>
                                  <AccordionDetails>

                                    <Fragment>
                                      <Grid container>
                                        {proveedorInformacion && Array.from(proveedorInformacion.data).filter(h => f._proveedoresInformacion_ref.includes(h._id)).map((g, index) => (
                                          <Grid item xs={12}>
                                            <Typography>
                                              {index + 1}. Proveedor de Información: [{g.codigo}] {g.proveedor}
                                              <br />
                                            </Typography>
                                            {entradas && Array.from(entradas.data).filter(j => g._entradas_ref.includes(j._id)).map((k, index) => (

                                              <Grid item xs={12}>
                                                <Button
                                                  // component={Link}
                                                  href={k.entrada.url}
                                                  target="_blank"
                                                  style={{
                                                    color: "black",
                                                    textDecoration: "none",
                                                    textTransform: "none",
                                                    paddingLeft: "20px"
                                                  }}
                                                >
                                                  Entrada n°{index + 1}: <strong> {k.entrada.nombre}</strong>
                                                </Button>
                                              </Grid>
                                            ))}
                                          </Grid>
                                        ))}
                                      </Grid>

                                    </Fragment>



                                  </AccordionDetails>
                                </Accordion>




                              </Grid>
                            ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}

              </Fragment>
            ))}
          </Grid>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogEntradasClose} variant="outlined" color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialogSalidas} maxWidth="xl" fullWidth >
        <DialogTitle>
          <Typography variant="h4">
            Salidas
          </Typography>
        </DialogTitle>
        <DialogContent dividers>

          {/* <Ficha macroproceso={macroproceso_name} tipo_proceso={tipo_proceso} idFicha={idFicha} /> */}
          <Grid container style={{ width: "100%" }}>
            {listaServicios && Array.from(listaServicios).map((d, index) => (
              <Fragment>
                <Typography variant="h5">
                  Estratégico
                </Typography>
                {servicios && macroprocesos && Array.from(macroprocesos.data).filter(p => servicios.data[0]._macroprocesos_ref.includes(p._id) && p.tipo_proceso == "Estratégico").map((d, index) => (

                  // <div>
                  //   {d.tipo_proceso == "Estratégico" ? (
                  <Grid item xs={12} style={{ maxWidth: "100%", padding: "5px" }}>


                    <Accordion style={{ borderRadius: "15px" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ backgroundColor: "lightgray", borderRadius: "15px" }}
                      >
                        <Typography fontWeight="bold">{d.nombre}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container style={{ width: "100%" }}>
                          {fichas && Array.from(fichas.data).filter(p => d._fichas_ref.includes(p._id)
                            && (normativaIso ? p.normativa_iso == normativaIso.nombre : d._fichas_ref.includes(p._id))).map((f, index) => (

                              // <div>
                              //   {d.tipo_proceso == "Estratégico" ? (
                              <Grid item xs={12} style={{ maxWidth: "100%", marginBottom: "7px" }}>

                                <Accordion variant="outlined">
                                  <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    style={{ backgroundColor: "white" }}

                                  >
                                    <Grid container >

                                      <Grid item xs={12} style={{ paddingLeft: "3px", alignItems: "left" }}>
                                        <Typography variant="subtitle2" display="flex" alignItems="left" style={{ cursor: "pointer", color: "#731f1f" }}>

                                          {f.codigo} | {f.nombre}

                                          {/* <FiberManualRecord fontSize="small" style={{ marginRight: "8px" }} /> {f.nombre} */}
                                        </Typography>
                                      </Grid>

                                    </Grid>
                                  </AccordionSummary>
                                  <AccordionDetails>

                                    <Fragment>
                                      <Grid container>
                                        {clientes && Array.from(clientes.data).filter(h => f._entregableCliente_ref.includes(h._id)).map((g, index) => (
                                          <Grid item xs={12}>
                                            <Typography>
                                              {index + 1}. Cliente: [{g.codigo}] {g.cliente}
                                              <br />
                                            </Typography>
                                            {entregables && Array.from(entregables.data).filter(j => g._entregable_ref.includes(j._id)).map((k, index) => (

                                              <Grid item xs={12}>
                                                <Button
                                                  // component={Link}
                                                  href={k.documento.url}
                                                  target="_blank"
                                                  style={{
                                                    color: "black",
                                                    textDecoration: "none",
                                                    textTransform: "none",
                                                    paddingLeft: "20px"
                                                  }}
                                                >
                                                  Entregable n°{index + 1}:<strong> {k.documento.nombre}</strong>
                                                </Button>
                                              </Grid>
                                            ))}
                                          </Grid>
                                        ))}
                                      </Grid>

                                    </Fragment>



                                  </AccordionDetails>
                                </Accordion>




                              </Grid>
                            ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}

                {/* Operativo */}
                <Typography variant="h5" style={{ marginTop: "15px" }}>
                  Operativo
                </Typography>
                {servicios && macroprocesos && Array.from(macroprocesos.data).filter(p => servicios.data[0]._macroprocesos_ref.includes(p._id) && p.tipo_proceso == "Operativo").map((d, index) => (

                  // <div>
                  //   {d.tipo_proceso == "Estratégico" ? (
                  <Grid item xs={12} style={{ maxWidth: "100%", padding: "5px" }}>


                    <Accordion style={{ borderRadius: "15px" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ backgroundColor: "lightgray", borderRadius: "15px" }}
                      >
                        <Typography fontWeight="bold">{d.nombre}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container style={{ width: "100%" }}>
                          {fichas && Array.from(fichas.data).filter(p => d._fichas_ref.includes(p._id)
                            && (normativaIso ? p.normativa_iso == normativaIso.nombre : d._fichas_ref.includes(p._id))).map((f, index) => (

                              // <div>
                              //   {d.tipo_proceso == "Estratégico" ? (
                              <Grid item xs={12} style={{ maxWidth: "100%", marginBottom: "7px" }}>

                                <Accordion variant="outlined">
                                  <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    style={{ backgroundColor: "white" }}

                                  >
                                    <Grid container >

                                      <Grid item xs={12} style={{ paddingLeft: "3px", alignItems: "left" }}>
                                        <Typography variant="subtitle2" display="flex" alignItems="left" style={{ cursor: "pointer", color: "#731f1f" }}>

                                          {f.codigo} | {f.nombre}

                                          {/* <FiberManualRecord fontSize="small" style={{ marginRight: "8px" }} /> {f.nombre} */}
                                        </Typography>
                                      </Grid>

                                    </Grid>
                                  </AccordionSummary>
                                  <AccordionDetails>

                                    <Fragment>
                                      <Grid container>
                                        {clientes && Array.from(clientes.data).filter(h => f._entregableCliente_ref.includes(h._id)).map((g, index) => (
                                          <Grid item xs={12}>
                                            <Typography>
                                              {index + 1}. Cliente: [{g.codigo}] {g.cliente}
                                              <br />
                                            </Typography>
                                            {entregables && Array.from(entregables.data).filter(j => g._entregable_ref.includes(j._id)).map((k, index) => (

                                              <Grid item xs={12}>
                                                <Button
                                                  // component={Link}
                                                  href={k.documento.url}
                                                  target="_blank"
                                                  style={{
                                                    color: "black",
                                                    textDecoration: "none",
                                                    textTransform: "none",
                                                    paddingLeft: "20px"
                                                  }}
                                                >
                                                  Entregable n°{index + 1}:<strong> {k.documento.nombre}</strong>
                                                </Button>
                                              </Grid>
                                            ))}
                                          </Grid>
                                        ))}
                                      </Grid>

                                    </Fragment>



                                  </AccordionDetails>
                                </Accordion>




                              </Grid>
                            ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}

                {/* Soporte */}
                <Typography variant="h5" style={{ marginTop: "15px" }}>
                  Soporte
                </Typography>
                {servicios && macroprocesos && Array.from(macroprocesos.data).filter(p => servicios.data[0]._macroprocesos_ref.includes(p._id) && p.tipo_proceso == "Soporte").map((d, index) => (

                  // <div>
                  //   {d.tipo_proceso == "Estratégico" ? (
                  <Grid item xs={12} style={{ maxWidth: "100%", padding: "5px" }}>


                    <Accordion style={{ borderRadius: "15px" }}>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ backgroundColor: "lightgray", borderRadius: "15px" }}
                      >
                        <Typography fontWeight="bold">{d.nombre}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container style={{ width: "100%" }}>
                          {fichas && Array.from(fichas.data).filter(p => d._fichas_ref.includes(p._id)
                            && (normativaIso ? p.normativa_iso == normativaIso.nombre : d._fichas_ref.includes(p._id))).map((f, index) => (

                              // <div>
                              //   {d.tipo_proceso == "Estratégico" ? (
                              <Grid item xs={12} style={{ maxWidth: "100%", marginBottom: "7px" }}>

                                <Accordion variant="outlined">
                                  <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    style={{ backgroundColor: "white" }}

                                  >
                                    <Grid container >

                                      <Grid item xs={12} style={{ paddingLeft: "3px", alignItems: "left" }}>
                                        <Typography variant="subtitle2" display="flex" alignItems="left" style={{ cursor: "pointer", color: "#731f1f" }}>

                                          {f.codigo} | {f.nombre}

                                          {/* <FiberManualRecord fontSize="small" style={{ marginRight: "8px" }} /> {f.nombre} */}
                                        </Typography>
                                      </Grid>

                                    </Grid>
                                  </AccordionSummary>
                                  <AccordionDetails>

                                    <Fragment>
                                      <Grid container>
                                        {clientes && Array.from(clientes.data).filter(h => f._entregableCliente_ref.includes(h._id)).map((g, index) => (
                                          <Grid item xs={12}>
                                            <Typography>
                                              {index + 1}. Cliente: [{g.codigo}] {g.cliente}
                                              <br />
                                            </Typography>
                                            {entregables && Array.from(entregables.data).filter(j => g._entregable_ref.includes(j._id)).map((k, index) => (

                                              <Grid item xs={12}>
                                                <Button
                                                  // component={Link}
                                                  href={k.documento.url}
                                                  target="_blank"
                                                  style={{
                                                    color: "black",
                                                    textDecoration: "none",
                                                    textTransform: "none",
                                                    paddingLeft: "20px"
                                                  }}
                                                >
                                                  Entregable n°{index + 1}:<strong> {k.documento.nombre}</strong>
                                                </Button>
                                              </Grid>
                                            ))}
                                          </Grid>
                                        ))}
                                      </Grid>

                                    </Fragment>



                                  </AccordionDetails>
                                </Accordion>




                              </Grid>
                            ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}

              </Fragment>
            ))}
          </Grid>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogSalidasClose} variant="outlined" color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>


    </Fragment >
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "20rem",
    overflowY: "scroll",
    backgroundColor: "#FFFFFF",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    paddingBottom: "20px",
  },
  fecha: {
    marginLeft: theme.spacing(2),
    color: "#731f1f",
  },
  sin_cumpleañeros: {
    marginLeft: theme.spacing(3),
    color: "#731f1f",
  },
  card: {
    flexGrow: 1,
    margin: theme.spacing(1),
    backgroundColor: "#FFFFFF",
  },
  avatar_size: {
    width: "40px",
    height: "40px",
  },
  typography_nombre: {
    fontSize: "11px",

    fontWeight: "bold",
    color: "#731f1f"
  },
  typography_email: {
    fontSize: "11px",
    color: "black"
  },
  typography_cargo: {
    fontSize: "11px",
    color: "#black"
  }
}));

// export default TabServicios;