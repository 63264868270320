import { BACKEND } from "../../../constants/urls";
import Axios from "axios";

/**
 * Método encargado de obtener un perfil, por su ID.
 * @param {*} entregableID ID del perfil.
 */
export async function ObtenerEntregable(entregableID) {
  try {
    let url = `${BACKEND}/entregable/${entregableID}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todos los perfiles.
 */
export async function ObtenerEntregables() {
  try {
    console.log("AAAAAAAAAAAAAAAAAAAAAA");
    let url = `${BACKEND}/entregable`;
    let response = await Axios.get(url);
    console.log(response);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de agregar un nuevo perfil.
 * @param {*} entregable Datos del perfil.
 */
export async function AgregarEntregable(entregable) {
  try {
    let url = `${BACKEND}/entregable`;
    let response = await Axios.post(url, entregable);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de actualizar un perfil.
 * @param {*} entregable Datos actualizados del perfil.
 */
export async function ActualizarEntregable(entregable) {
  try {
    let url = `${BACKEND}/entregable/${entregable._id}`;
    let response = await Axios.put(url, entregable);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de eliminar un perfil.
 * @param {*} entregable Datos del perfil.
 */
export async function EliminarEntregable(entregable) {
  try {
      
    let url = `${BACKEND}/entregable/${entregable._id}`;
    console.log(url);
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}