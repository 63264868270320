import React, { Fragment, Label, useContext, useEffect } from "react";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import Moment from "moment";
import { useFormik } from "formik";
import { object, string, array } from "yup";
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputLabel, FormControl, Grid, IconButton, Select, TextField, Checkbox, FormControlLabel, Switch } from "@material-ui/core";
import { Close, GetApp, Visibility } from "@material-ui/icons";
import MaterialTable from "../../../../components/materialTable";
import { ObtenerMacroprocesos } from "../../../../services/request/mapaProcesos/requestMacroproceso";
import { ObtenerServicios, AgregarServicio, ActualizarServicio, EliminarServicio } from "../../../../services/request/mapaProcesos/requestServicio";
import { Autocomplete } from "@material-ui/lab";
import { ObtenerGerencias } from "../../../../services/request/proyectos/requestGerencias";
import { GetContratos } from "../../../../services/request/portafolio/contratos";
import { MainContext } from "../../../../App";



function TabPerfiles(props) {
    const { data: macroprocesos, error: errorMacroprocesos, mutate: mutateMacroprocesos } = useSWR("macroprocesos", (key) => ObtenerMacroprocesos(), { revalidateOnFocus: false });
    const { data: servicios, error: errorServicios, mutate: mutateServicios } = useSWR("servicios", (key) => ObtenerServicios(), { revalidateOnFocus: false });
    const { data: gerencias, error: errorGerencias, mutate: mutateGerencias } = useSWR("gerencias", (key) => ObtenerGerencias(), { revalidateOnFocus: false });
    const { data: contratos, error: errorContratos, mutate: mutateContratos } = useSWR("contratos", (key) => GetContratos(), { revalidateOnFocus: false });
    const [stateCheck, setStateCheck] = React.useState(false);
    const [autocompleteContrato, setAutoCompleteContrato] = React.useState();

    const [openPrev, setOpenPrev] = React.useState(false);
    const notistack = useSnackbar();

    const { usuarioSesion } = useContext(MainContext);


    useEffect(() => {
        if (errorMacroprocesos) {
            notistack.enqueueSnackbar("Error al intentar obtener los procedimientos asociados.", {
                variant: "error",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        }
        if (errorServicios) {
            notistack.enqueueSnackbar("Error al intentar obtener los Servicios.", {
                variant: "error",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        }
    }, [errorMacroprocesos, errorServicios]);

    const formik = useFormik({
        initialValues: {
            nombre: "",
            codigo: "",
            _macroprocesos_ref: [],
            gerencia: [],
        },
        validationSchema: object().shape({
            nombre: string()
                .min(3, "El nombre debe tener al menos ${min} caracteres.")
                .max(150, "El nombre debe tener a los más ${max} caracteres.")
                .required("El nombre es requerido."),
            codigo: string()
                .min(3, "El nombre debe tener al menos ${min} caracteres.")
                .max(150, "El nombre debe tener a los más ${max} caracteres."),
                // .required("El nombre es requerido."),
            _macroprocesos_ref: array()
                .of(string())
                .optional(),

            gerencia: array()
                .of(string())
                .optional(),

        }),
        onSubmit: (values, helper) => handleAceptar(values),
        enableReinitialize: true,
    });

    /**
     * Handler para agregar un nuevo perfil.
     */
    const handleAgregar = () => {
        formik.setFieldValue("tipo_", "agregar");
    }

    /**
     * Handler para actualizar un perfil.
     * @param {*} event Evento.
     * @param {*} row Datos.
     */
    const handleActualizar = (event, row) => {
        // console.log(Boolean(!Array.from(contratos).find(p => row.nombre.includes(p.nombre))));
        formik.setValues(row);
        setStateCheck(contratos && Boolean(!Array.from(contratos).find(p => row.nombre.includes(p.nombre))));
        setAutoCompleteContrato(contratos && Array.from(contratos).find(p => row.nombre.includes(p.nombre)));
        formik.setFieldValue("tipo_", "actualizar");
    }

    /**
     * Handler para eliminar un perfil.
     * @param {*} event Evento.
     * @param {*} row Datos.
     */
    const handleEliminar = (event, row) => {
        formik.setValues(row);
        setStateCheck(Boolean(!Array.from(contratos).find(p => row.nombre.includes(p.nombre))));
        setAutoCompleteContrato(Array.from(contratos).find(p => row.nombre.includes(p.nombre)));
        formik.setFieldValue("tipo_", "eliminar");
    }

    /**
     * Handler para aceptar la acción.
     * @param {*} values 
     */
    const handleAceptar = async (values) => {
        try {
            switch (values.tipo_) {
                case "agregar":
                    console.log(values);
                    await AgregarServicio(values);
                    break;
                case "actualizar":
                    await ActualizarServicio(values);
                    break;
                case "eliminar":
                    await EliminarServicio(values);
                    break;
                default:
                    throw new Error("Acción no especificada.")
            }
            notistack.enqueueSnackbar("Acción realizada exitosamente.", {
                variant: "success",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        } catch (error) {
            console.error(error);
            notistack.enqueueSnackbar("Error al intentar realizar la acción.", {
                variant: "error",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        } finally {
            handleCancelar();
        }
    }

    const handleCancelar = () => {
        mutateMacroprocesos();
        mutateServicios();
        formik.resetForm();
    }

    const handlePrev = (event, row) => {
        formik.setValues(row);
        formik.setFieldValue("tipo_", "previsualizar");
        setOpenPrev(true);
    }

    let columns = [
        {
            title: "Nombre",
            field: "nombre",
            // defaultSort: 'asc'
        }, {
            title: "Gerencia",
            align: 'center',
            field: "gerencia",

            width: "20%",
            render: rowData => (
                <div>
                    {rowData.gerencia.map(type => (
                        <Fragment>
                            {gerencias ? Array.from(gerencias).filter(p => type.includes(p.sigla)).map((d, index) => (
                                <Chip key={d.sigla}
                                    size="small"
                                    variant="default"
                                    color="primary"
                                    style={{ margin: 2 }}
                                    label={d.sigla} />
                            )) : <div></div>}
                        </Fragment>
                    ))}
                </div>
            ),
        }
        , {
            title: "Fecha Actualización",
            field: "fecha_actualizacion",
            align: 'center',
            defaultSort: "desc",
            width: "20%",
            render: (row) => Moment(row.fecha_actualizacion).format("DD/MM/YYYY HH:mm"),
        },
    ];


    let actions = [
        {
            tooltip: "Agregar",
            icon: "add",
            onClick: () => handleAgregar(),
            isFreeAction: true,
        },
        // {
        //     tooltip: "Previsualizar",
        //     icon: () => <Visibility />,
        //     onClick: handlePrev,
        // }, 
        {
            tooltip: "Editar",
            icon: "edit",
            onClick: handleActualizar,
        }, {
            tooltip: "Eliminar",
            icon: "delete",
            onClick: handleEliminar,
        }
    ];


    const handleChange = (event) => {
        // this.refs[`autocomplete`].setState({searchText:''});
        setStateCheck(event.target.checked);
    };


    return (
        <Fragment>
            <MaterialTable
                title="Contratos"
                is_loading={!servicios}
                data={servicios && Array.from(servicios.data).filter(g => g.gerencia.some(j => usuarioSesion.gerencia_visualizar.includes(j))).concat(Array.from(servicios.data).filter(g => Array.from(g.gerencia).length == 0))}
                // data={servicios ? Array.from(servicios.data).filter(g => usuarioSesion.gerencia_visualizar.includes(g.gerencia)) : []}
                // Array.from(gerencias).filter(g => usuarioSesion.gerencia_visualizar.includes(g.sigla))
                columns={columns}
                actions={actions}
            />
            <Dialog open={Boolean(formik.values.tipo_)} maxWidth="md" fullWidth>
                <DialogTitle>
                    {formik.values.tipo_ === "agregar" && "Agregar Contrato"}
                    {formik.values.tipo_ === "actualizar" && "Actualizar Contrato"}
                    {formik.values.tipo_ === "eliminar" && "Eliminar Contrato"}
                    {formik.values.tipo_ === "previsualizar" && "Previsualización de Contrato"}
                </DialogTitle>
                <DialogContent dividers>
                    {formik.values.tipo_ == "actualizar" || formik.values.tipo_ == "agregar" || formik.values.tipo_ == "eliminar" ?
                        (
                            <div>
                                <DialogContentText>Formulario con información del Contrato.</DialogContentText>
                                <Grid container spacing={2}>
                                    <Grid item xs={9}>

                                        <Autocomplete
                                            // multiple
                                            options={contratos ? contratos : []}
                                            // value={contratos ? stateCheck ? "" : Array.from(contratos).find(p => formik.values.nombre.includes(p.nombre)) : []}
                                            value={autocompleteContrato}
                                            getOptionLabel={(contrato) => {
                                                if (stateCheck) {
                                                    return ""
                                                } else {
                                                    if (contrato != null) {
                                                        return contrato.codigo + " - " + contrato.nombre;
                                                    } else {
                                                        return "";
                                                    }
                                                }
                                            }
                                            }

                                            disabled={formik.values.tipo_ === "eliminar" || stateCheck == true}
                                            onChange={(event, value) => {
                                                console.log(value);
                                                setAutoCompleteContrato(value);
                                                // formik.setValues
                                                if (value != null) {
                                                    formik.setFieldValue("nombre", `${value.codigo} - ${value.nombre}`);
                                                    // formik.setFieldValue("codigo", value.codigo);
                                                }

                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    label="Contrato"
                                                    variant="outlined"
                                                    {...params}
                                                />
                                            )}
                                        />

                                    </Grid>

                                    <Grid item xs={3}>

                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={stateCheck}
                                                    onChange={handleChange}
                                                    name="checkedA"
                                                    disabled={formik.values.tipo_ === "eliminar"}
                                                />}
                                            label="Nuevo Contrato"
                                        />

                                    </Grid>
                                    {stateCheck &&
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Nombre del Contrato"
                                                name="nombre"
                                                value={formik.values.nombre}
                                                onChange={formik.handleChange}
                                                helperText={formik.errors.nombre}
                                                error={Boolean(formik.errors.nombre)}
                                                variant="outlined"
                                                fullWidth
                                                disabled={formik.values.tipo_ === "eliminar"}
                                            />
                                        </Grid>

                                    }
                                    {/* <Grid item xs={12}>
                                        <TextField
                                            label="Nombre"
                                            name="nombre"
                                            value={formik.values.nombre}
                                            onChange={formik.handleChange}
                                            helperText={formik.errors.nombre}
                                            error={Boolean(formik.errors.nombre)}
                                            variant="outlined"
                                            fullWidth
                                            disabled={formik.values.tipo_ === "eliminar"}
                                        />
                                    </Grid> */}
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            multiple
                                            options={macroprocesos ? macroprocesos.data : []}
                                            value={macroprocesos ? Array.from(macroprocesos.data).filter(p => formik.values._macroprocesos_ref.includes(p._id)) : []}
                                            getOptionLabel={(macroprocesos) => macroprocesos.nombre + " - " + macroprocesos.tipo_proceso}
                                            disabled={formik.values.tipo_ === "eliminar"}
                                            onChange={(event, value) => {
                                                formik.setFieldValue("_macroprocesos_ref", value.map(v => v._id));
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    label="Macroprocesos"
                                                    variant="outlined"
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>

                                        <Autocomplete
                                            multiple
                                            options={gerencias ? gerencias : []}
                                            value={gerencias ? Array.from(gerencias).filter(p => formik.values.gerencia.includes(p.sigla)) : []}
                                            getOptionLabel={(gerencia) => gerencia.sigla + " - " + gerencia.nombre}
                                            disabled={formik.values.tipo_ === "eliminar"}
                                            onChange={(event, value) => {
                                                formik.setFieldValue("gerencia", value.map(v => v.sigla));
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    label="Gerencias"
                                                    variant="outlined"
                                                    {...params}
                                                />
                                            )}
                                        />

                                    </Grid>
                                </Grid>
                            </div>
                        ) : (
                            <div>
                                <Grid container spacing={2}>
                                    EN CONSTRUCCIÓN
                                </Grid>
                            </div>)}

                </DialogContent>
                {formik.values.tipo_ === "actualizar" || formik.values.tipo_ == "agregar" || formik.values.tipo_ == "eliminar" ?
                    (
                        <DialogActions>
                            <Button onClick={handleCancelar} variant="outlined" color="primary">
                                Cancelar
                            </Button>
                            <Button onClick={formik.submitForm} variant="contained" color="primary">
                                Aceptar
                            </Button>
                        </DialogActions>
                    ) : (
                        <DialogActions>
                            <Button onClick={handleCancelar} variant="outlined" color="primary">
                                Cerrar
                            </Button>

                        </DialogActions>
                    )
                }
            </Dialog>


        </Fragment>
    );
}

export default TabPerfiles;