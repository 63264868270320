import { BACKEND } from "../../../constants/urls";
import Axios from "axios";

/**
 * Método encargado de obtener un perfil, por su ID.
 * @param {*} macroprocesoID ID del perfil.
 */
export async function ObtenerMacroproceso(macroprocesoID) {
  try {
    let url = `${BACKEND}/macroproceso/${macroprocesoID}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}   

/**
 * Método encargado de obtener todos los perfiles.
 */
export async function ObtenerMacroprocesos() {
  try {
    let url = `${BACKEND}/macroproceso`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de agregar un nuevo perfil.
 * @param {*} macroproceso Datos del perfil.
 */
export async function AgregarMacroproceso(macroproceso) {
  try {
    let url = `${BACKEND}/macroproceso`;
    let response = await Axios.post(url, macroproceso);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de actualizar un perfil.
 * @param {*} macroproceso Datos actualizados del perfil.
 */
export async function ActualizarMacroproceso(macroproceso) {
  try {
    let url = `${BACKEND}/macroproceso/${macroproceso._id}`;
    let response = await Axios.put(url, macroproceso);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de eliminar un perfil.
 * @param {*} macroproceso Datos del perfil.
 */
export async function EliminarMacroproceso(macroproceso) {
  try {
      
    let url = `${BACKEND}/macroproceso/${macroproceso._id}`;
    console.log(url);
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}