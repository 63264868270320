import { BACKEND } from "../../../constants/urls";
import Axios from "axios";

/**
 * Método encargado de obtener un perfil, por su ID.
 * @param {*} servicioID ID del perfil.
 */
export async function ObtenerServicio(servicioID) {
  try {
    let url = `${BACKEND}/servicio/${servicioID}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todos los perfiles.
 */
export async function ObtenerServicios() {
  try {
    let url = `${BACKEND}/servicio`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de agregar un nuevo perfil.
 * @param {*} servicio Datos del perfil.
 */
export async function AgregarServicio(servicio) {
  try {
    let url = `${BACKEND}/servicio`;
    console.log(servicio);
    let response = await Axios.post(url, servicio);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de actualizar un perfil.
 * @param {*} servicio Datos actualizados del perfil.
 */
export async function ActualizarServicio(servicio) {
  try {
    let url = `${BACKEND}/servicio/${servicio._id}`;
    let response = await Axios.put(url, servicio);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de eliminar un perfil.
 * @param {*} servicio Datos del perfil.
 */
export async function EliminarServicio(servicio) {
  try {
    let url = `${BACKEND}/servicio/${servicio._id}`;
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}