import React, { Fragment, useContext, useEffect } from "react";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import { array, object, string } from "yup";
import Moment from "moment";
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextField, Tooltip } from "@material-ui/core";
import { AssignmentReturn, Close } from "@material-ui/icons";
import MaterialTable from "../../../../components/materialTable";
import { ObtenerInvolucradosInternos, AgregarInvolucradoInterno, ActualizarInvolucradoInterno, EliminarInvolucradoInterno } from "../../../../services/request/mapaProcesos/requestInvolucradoInterno";
import { Autocomplete } from "@material-ui/lab";
import { ObtenerGerencias } from "../../../../services/request/proyectos/requestGerencias";
import { ObtenerFichas } from "../../../../services/request/mapaProcesos/requestFicha";

import { MainContext } from "../../../../App";

import DialogAsignacion from "../../../../components/DialogAsignar";

function AsociadoInterno(props) {

    const { data, error, mutate } = useSWR("involucrados_Internos", (key) => ObtenerInvolucradosInternos(), { revalidateOnFocus: false });
    const { data: gerencias, error: errorGerencias, mutate: mutateGerencias } = useSWR("gerencias", (key) => ObtenerGerencias(), { revalidateOnFocus: false });
    const { data: fichas, error: errorFichas, mutate: mutateFichas } = useSWR("fichas", (key) => ObtenerFichas(), { revalidateOnFocus: false });
    const { usuarioSesion } = useContext(MainContext);

    const notistack = useSnackbar();

    const [openAsignacion, setOpenAsignacion] = React.useState(false);
    const [_id_, set_id_] = React.useState(null);

    const handleAbrirAsignacion = (event, row) => {
        set_id_(row._id);
        setOpenAsignacion(true);

    }

    useEffect(() => {
        if (error) {
            notistack.enqueueSnackbar("Error al intentar obtener los permisos.", {
                variant: "error",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        }
    }, [error]);

    const formik = useFormik({
        initialValues: {
            cargo: "",
            descripcion: "",
            gerencia: "",
        },
        validationSchema: object().shape({
            cargo: string()
                .min(3, "El cargo debe tener al menos ${min} caracteres.")
                .max(550, "El cargo debe tener a los más ${max} caracteres.")
                .required("El cargo es requerido."),
            descripcion: string()
                .min(3, "La descripcion debe tener al menos ${min} caracteres."),
            // .max(550, "La descripcion debe tener a los más ${max} caracteres."),
            // .required("La descripción es requerida."),
            gerencia: array()
                .of(string())
                .optional(),

        }),
        onSubmit: (values, helper) => handleAceptar(values),
        enableReinitialize: true,
    });

    /**
     * Handler para agregar un nuevo rol.
     */
    const handleAgregar = () => {
        formik.setFieldValue("tipo", "agregar");
    }

    /**
     * Handler para actualizar un rol.
     * @param {*} event Evento.
     * @param {*} row Datos.
     */
    const handleActualizar = (event, row) => {
        formik.setValues(row);
        formik.setFieldValue("tipo", "actualizar");
    }

    /**
     * Handler para eliminar un rol.
     * @param {*} event Evento.
     * @param {*} row Datos.
     */
    const handleEliminar = (event, row) => {
        formik.setValues(row);
        formik.setFieldValue("tipo", "eliminar");
    }

    /**
     * Handler para aceptar la acción.
     * @param {*} values 
     */
    const handleAceptar = async (values) => {
        try {
            switch (values.tipo) {
                case "agregar":
                    await AgregarInvolucradoInterno(values);
                    break;
                case "actualizar":
                    await ActualizarInvolucradoInterno(values);
                    break;
                case "eliminar":
                    await EliminarInvolucradoInterno(values);
                    break;
                default:
                    throw new Error("Acción no especificada.")
            }
            notistack.enqueueSnackbar("Acción realizada exitosamente.", {
                variant: "success",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        } catch (error) {
            console.error(error);
            notistack.enqueueSnackbar("Error al intentar realizar la acción.", {
                variant: "error",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        } finally {
            handleCancelar();
        }
    }

    const handleCancelar = () => {
        mutate();
        formik.resetForm();
    }

    let columns = [
        {
            title: "Cargo",
            field: "cargo",

            // defaultSort: 'asc'
        }, {
            title: "Descripción",
            field: "descripcion",
        }, {
            title: "Gerencia",
            align: 'center',
            field: "gerencia",
            width: "12%",


            render: rowData => (
                <div>
                    {rowData.gerencia && rowData.gerencia.map(type => (
                        <Fragment>
                            {gerencias ? Array.from(gerencias).filter(p => type.includes(p.sigla)).map((d, index) => (
                                <Chip key={d.sigla}
                                    size="small"
                                    variant="default"
                                    color="primary"
                                    style={{ margin: 2 }}
                                    label={d.sigla} />
                            )) : <div></div>}
                        </Fragment>
                    ))}
                </div>
            ),

        }, {
            title: "Asignado a",
            align: "center",
            // field: "version", 
            render: rowData => (
                // <div>
                //     {rowData.gerencia.map(type => (
                <Fragment>
                    {fichas ? Array.from(fichas.data).filter(p => p._involucrado_asociado_interno_ref.includes(rowData._id)).map((d, index) => (
                        <div>
                            <Tooltip title={d.nombre}>
                                <Chip
                                    key={d.nombre}
                                    size="small"
                                    variant="default"
                                    color="primary"
                                    style={{ margin: 2 }}
                                    label={
                                        <div>
                                            {d.codigo}
                                        </div>
                                    } />
                            </Tooltip>
                        </div>
                    )) : <div></div>}

                </Fragment>
            ),
        }, {
            title: "Fecha Actualización",
            field: "fecha_actualizacion",
            width: "15%",
            
            align: 'center',
            defaultSort: "desc",
            render: (row) => Moment(row.fecha_actualizacion).format("DD/MM/YYYY HH:mm"),
        },


    ];

    let actions = [
        {
            tooltip: "Agregar",
            icon: "add",
            onClick: () => handleAgregar(),
            isFreeAction: true,
        }, {
            tooltip: "Editar",
            icon: "edit",
            onClick: handleActualizar,
        },

        {
            tooltip: "Asignar a Ficha",
            icon: () => <AssignmentReturn />,
            onClick: handleAbrirAsignacion,
        },
        {
            tooltip: "Eliminar",
            icon: "delete",
            onClick: handleEliminar,

        }
    ];

    return (
        <Fragment>
            <MaterialTable
                title="Involucrados Asociados Internos"
                is_loading={!data}
                // data={data && data.data}
                data={data && Array.from(data.data).filter(g => g.gerencia.some(j => usuarioSesion.gerencia_visualizar.includes(j)))
                    .concat(Array.from(data.data).filter(g => Array.from(g.gerencia).length == 0))
                    .concat(Array.from(data.data).filter(g => g.gerencia[0] == "")) }



                columns={columns}
                actions={actions}
            />
            <Dialog open={Boolean(formik.values.tipo)} maxWidth="md" fullWidth>
                <DialogTitle>
                    {formik.values.tipo === "actualizar" && "Actualizar Asociado Interno"}
                    {formik.values.tipo === "agregar" && "Agregar Asociado Interno"}
                    {formik.values.tipo === "eliminar" && "Eliminar Asociado Interno"}
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>Formulario con información del Asociado Interno.</DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                label="Cargo"
                                name="cargo"
                                value={formik.values.cargo}
                                onChange={formik.handleChange}
                                helperText={formik.errors.cargo}
                                error={Boolean(formik.errors.cargo)}
                                variant="outlined"
                                fullWidth
                                disabled={formik.values.tipo === "eliminar"}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Descripción"
                                name="descripcion"
                                value={formik.values.descripcion}
                                onChange={formik.handleChange}
                                helperText={formik.errors.descripcion}
                                error={Boolean(formik.errors.descripcion)}
                                variant="outlined"
                                fullWidth
                                disabled={formik.values.tipo === "eliminar"}
                            />
                        </Grid>

                        <Grid item xs={12}>

                            <Autocomplete
                                multiple
                                options={gerencias ? gerencias : []}
                                value={formik.values.gerencia && gerencias ? Array.from(gerencias).filter(p => formik.values.gerencia.includes(p.sigla)) : []}
                                getOptionLabel={(gerencia) => gerencia.sigla + " - " + gerencia.nombre}
                                disabled={formik.values.tipo_ === "eliminar"}
                                onChange={(event, value) => {
                                    formik.setFieldValue("gerencia", value.map(v => v.sigla));
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        label="Gerencias"
                                        variant="outlined"
                                        {...params}
                                    />
                                )}
                            />

                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelar} variant="outlined" color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={formik.submitForm} variant="contained" color="primary">
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAsignacion} fullWidth>
                <DialogAsignacion _id_={_id_} titulo="InvolucradoInterno" setOpenAsignacion={setOpenAsignacion} mutate={mutateFichas} />
            </Dialog>
        </Fragment>
    );
}

export default AsociadoInterno;