import React, { Fragment, Label, useContext, useEffect } from "react";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { DatePicker } from "@material-ui/pickers";
import { useFormik } from "formik";
import { object, string, array, date } from "yup";
import Moment from "moment";
import { Box, Button, Chip, Dialog, Typography, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputLabel, Paper, Select, TextField, Tooltip } from "@material-ui/core";
import { AssignmentReturn, Check, Close, FileCopy, GetApp, Link, Visibility } from "@material-ui/icons";
import MaterialTable from "../../../components/materialTable";
import { ObtenerFichas, AgregarFicha, ActualizarFicha, EliminarFicha } from "../../../services/request/mapaProcesos/requestFicha";
import { ObtenerProcAsocs } from "../../../services/request/mapaProcesos/requestProcedimientoAsociado";
import { ObtenerKPIs } from "../../../services/request/mapaProcesos/requestKPI";
import { ObtenerInvolucradosExternos } from "../../../services/request/mapaProcesos/requestInvolucradoExterno";
import { ObtenerInvolucradosInternos } from "../../../services/request/mapaProcesos/requestInvolucradoInterno";
import { ObtenerProveedores } from "../../../services/request/mapaProcesos/requestProveedoresInformacion";
import { ObtenerProcesosAsociados } from "../../../services/request/mapaProcesos/requestProcesoAsociado";
import { ObtenerDocsAsociados } from "../../../services/request/mapaProcesos/requestDocumentosAsociados";
import { ObtenerEntradas } from "../../../services/request/mapaProcesos/requestEntrada";
import { ObtenerClientes } from "../../../services/request/mapaProcesos/requestClientes";
import { ObtenerEntregables } from "../../../services/request/mapaProcesos/requestEntregables";
import { ObtenerGerencias } from "../../../services/request/proyectos/requestGerencias";
import { ObtenerMacroprocesos } from "../../../services/request/mapaProcesos/requestMacroproceso";
import { ObtenerServicios } from "../../../services/request/mapaProcesos/requestServicio";
import { ObtenerEtiquetas, AgregarEtiqueta } from "../../../services/request/mapaProcesos/requestEtiqueta";



import { uploadFileToCloudStorage } from "../../../services/fileUpload";
import FileInput from "../../../components/fileInput";

// import { Autocomplete } from "@material-ui/lab";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';


import DialogAsignacion from "../../../components/DialogAsignar";

import { MainContext } from "../../../App";


function TabPerfiles(props) {
    const { data: fichas, error: errorFichas, mutate: mutateFichas } = useSWR("fichas", (key) => ObtenerFichas(), { revalidateOnFocus: false });
    const { data: procedimientosAsociados, error: errorProcAsoc, mutate: mutateProcAsoc } = useSWR("procedimientos_asociados", (key) => ObtenerProcAsocs(), { revalidateOnFocus: false });
    const { data: kpi, error: errorkpi, mutate: mutatekpi } = useSWR("kpi", (key) => ObtenerKPIs(), { revalidateOnFocus: false });
    const { data: involucradosExternos, error: errorExternos, mutate: mutateExternos } = useSWR("involucrados_externos", (key) => ObtenerInvolucradosExternos(), { revalidateOnFocus: false });
    const { data: involucradosInternos, error: errorInternos, mutate: mutateInternos } = useSWR("involucrados_internos", (key) => ObtenerInvolucradosInternos(), { revalidateOnFocus: false });
    const { data: proveedoresInformacion, error: errorProveedores, mutate: mutateProveedores } = useSWR("proveedores_informacion", (key) => ObtenerProveedores(), { revalidateOnFocus: false });
    const { data: entradas, error: errorEntradas, mutate: mutateEntradas } = useSWR("entradas", (key) => ObtenerEntradas(), { revalidateOnFocus: false });
    const { data: procesosAsociados, error: errorProcesos, mutate: mutateProcesos } = useSWR("procesos_asociados", (key) => ObtenerProcesosAsociados(), { revalidateOnFocus: false });
    const { data: clientes, error: errorClientes, mutate: mutateClientes } = useSWR("clientes", (key) => ObtenerClientes(), { revalidateOnFocus: false });
    const { data: entregables, error: errorEntregables, mutate: mutateEntregables } = useSWR("entregables", (key) => ObtenerEntregables(), { revalidateOnFocus: false });
    const { data: gerencias, error: errorGerencias, mutate: mutateGerencias } = useSWR("gerencias", (key) => ObtenerGerencias(), { revalidateOnFocus: false });
    const { data: macroprocesos, error: errorMacroprocesos, mutate: mutateMacroprocesos } = useSWR("macroprocesos", (key) => ObtenerMacroprocesos(), { revalidateOnFocus: false });
    const { data: servicios, error: errorServicios, mutate: mutateServicios } = useSWR("servicios", (key) => ObtenerServicios(), { revalidateOnFocus: false });
    const { data: etiquetas, error: errorEtiquetas, mutate: mutateEtiquetas } = useSWR("etiquetas", (key) => ObtenerEtiquetas(), { revalidateOnFocus: false });



    const { usuarioSesion } = useContext(MainContext);




    const { data: documentosAsociados, error: errorDocumentos, mutate: mutateDocumentos } = useSWR("documentos_asociados", (key) => ObtenerDocsAsociados(), { revalidateOnFocus: false });

    const [openPrev, setOpenPrev] = React.useState(false);
    const [value, setValue] = React.useState(Moment());
    const [valueEtiqueta, setValueEtiqueta] = React.useState(null);
    const [openAsignacion, setOpenAsignacion] = React.useState(false);
    const [_id_, set_id_] = React.useState(null);
    const notistack = useSnackbar();

    const filter = createFilterOptions();


    const handleAbrirAsignacion = (event, row) => {
        set_id_(row._id);
        setOpenAsignacion(true);

    }

    useEffect(() => {
        if (errorFichas) {
            notistack.enqueueSnackbar("Error al intentar obtener las fichas.", {
                variant: "error",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        }
        if (errorProcAsoc) {
            notistack.enqueueSnackbar("Error al intentar obtener las fichas.", {
                variant: "error",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        }
    }, [errorFichas, errorProcAsoc, errorkpi, errorExternos, errorInternos, errorProveedores, errorProcesos, errorDocumentos]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            nombre: "",
            etiquetas_ref: [],
            link_documento: "",
            fichaPDF: null,
            file_ficha: null,
            normativa_iso: "",
            codigo: "",
            version: "",
            flujograma: null,
            file: null,
            responsable: "",
            objetivo: "",
            alcance: "",
            fecha_actualizacion: null,
            _procedimientosAsociados_ref: [],
            _documentosAsociados_ref: [],
            _involucrado_asociado_interno_ref: [],
            _involucrado_asociado_externo_ref: [],
            _proveedoresInformacion_ref: [],
            _procesosAsociados_ref: [],
            objetivo_kpi: "",
            _kpi_ref: [],
            _entregableCliente_ref: [],
            gerencia: []
        },
        validationSchema: object().shape({
            nombre: string()
                .min(3, "El nombre debe tener al menos ${min} caracteres.")
                .max(150, "El nombre debe tener a los más ${max} caracteres.")
                .required("El nombre es requerido."),
            etiquetas_ref: array()
                .of(string())
                .optional(),
            file_ficha: object()
                .nullable()
                .optional(),
            normativa_iso: object()
                .nullable()
                .optional(),
            fichaPDF: object()
                .nullable()
                .optional(),
            codigo: string()
                .min(2, "El código debe tener al menos ${min} caracteres.")
                .max(50, "El código debe tener a los más ${max} caracteres.")
                .required("El código es requerida."),
            version: string()
                .min(1, "La versión debe tener al menos ${min} caracteres.")
                .max(50, "La versión debe tener a los más ${max} caracteres.")
                .required("La versión es requerida."),
            file: object()
                .nullable()
                .optional(),
            flujograma: object()
                .nullable()
                .optional(),
            // .required("La descripción es requerida."),
            responsable: string()
                .min(3, "El responsable debe tener al menos ${min} caracteres.")
                .max(100, "El responsable debe tener a los más ${max} caracteres."),
            // .required("El responsable es requerido."),
            objetivo: string()
                .min(3, "El objetivo debe tener al menos ${min} caracteres."),
            // .max(1500, "El objetivo debe tener a los más ${max} caracteres."),
            // .required("El objetivo es requerida."),
            alcance: string()
                .min(3, "El alcance debe tener al menos ${min} caracteres."),
            // .max(1500, "El alcance debe tener a los más ${max} caracteres."),
            // .required("El alcance es requerida."),
            // fecha_actualizacion: date().nullable().required("La fecha es requerida."),.optional(),
            fecha_actualizacion: date().nullable().optional(),

            _procedimientosAsociados_ref: array()
                .of(string())
                .optional(),
            _documentosAsociados_ref: array()
                .of(string())
                .optional(),
            _involucrado_asociado_interno_ref: array()
                .of(string())
                .optional(),
            _involucrado_asociado_externo_ref: array()
                .of(string())
                .optional(),
            _proveedoresInformacion_ref: array()
                .of(string())
                .optional(),
            _procesosAsociados_ref: array()
                .of(string())
                .optional(),
            objetivo_kpi: string()
                .min(3, "El objetivo debe tener al menos ${min} caracteres.")
                .max(300, "El objetivo debe tener a los más ${max} caracteres.")
                .optional(),
            _kpi_ref: array()
                .of(string())
                .optional(),
            _entregableCliente_ref: array()
                .of(string())
                .optional(),
            gerencia: array()
                .of(string())
                .optional(),

        }),
        onSubmit: async function (values, helper) {
            values.fecha_actualizacion = value;

            if (values.file) {
                notistack.enqueueSnackbar("Subiendo flujograma a la nube...", {
                    anchorOrigin: {
                        horizontal: "center",
                        vertical: "bottom"
                    },
                });
                const archivo1 = await uploadFileToCloudStorage(values.file, `${values.codigo}/flujograma`, values.file.name);
                values.flujograma = archivo1;
            }

            if (values.file_ficha) {
                notistack.enqueueSnackbar("Subiendo PDF de ficha a la nube...", {
                    anchorOrigin: {
                        horizontal: "center",
                        vertical: "bottom"
                    },
                });
                const archivo = await uploadFileToCloudStorage(values.file_ficha, `${values.codigo.replace(/[^a-zA-Z 0-9.]+/g, '')}/Ficha_PDF`, values.file_ficha.name.replace(/[^-A-Za-z0-9]+/g, '-').toLowerCase());
                values.fichaPDF = archivo;
            }

            console.log(values);
            handleAceptar(values);

        },
        enableReinitialize: true,
    });

    /**
     * Handler para agregar un nuevo perfil.
     */
    const handleAgregar = () => {
        formik.setFieldValue("tipo_", "agregar");
    }

    /**
     * Handler para actualizar un perfil.
     * @param {*} event Evento.
     * @param {*} row Datos.
     */
    const handleActualizar = (event, row) => {
        formik.setValues(row);
        formik.setFieldValue("tipo_", "actualizar");
    }

    /**
     * Handler para eliminar un perfil.
     * @param {*} event Evento.
     * @param {*} row Datos.
     */
    const handleEliminar = (event, row) => {
        formik.setValues(row);
        formik.setFieldValue("tipo_", "eliminar");
    }

    /**
     * Handler para duplicar un perfil.
     * @param {*} event Evento.
     * @param {*} row Datos.
     */

    const handleDuplicar = (event, row) => {


        console.log(row);
        formik.setValues(row);
        formik.setFieldValue("codigo", row.codigo + "_copia")
        formik.setFieldValue("tipo_", "duplicar");
    }

    const handleEtiqueta = async (values) => {
        console.log(values);
        let etiquetaNueva = values.pop();
        let respuesta;
        try {
            respuesta = await AgregarEtiqueta(etiquetaNueva);
            console.log(respuesta);
            values.push(respuesta.data);
            console.log(values);
            notistack.enqueueSnackbar("Etiqueta agregada exitosamente.", {
                variant: "success",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        } catch (error) {
            console.error(error);
            notistack.enqueueSnackbar("Error al intentar realizar la acción.", {
                variant: "error",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        } finally {
            formik.setFieldValue("etiquetas_ref", values.map(v => v._id));
            mutateEtiquetas();
        }
        return values;
    }

    /**
     * Handler para aceptar la acción.
     * @param {*} values 
     */
    const handleAceptar = async (values) => {
        console.log(values);
        try {
            switch (values.tipo_) {
                case "agregar":
                    await AgregarFicha(values);
                    break;
                case "actualizar":
                    await ActualizarFicha(values);
                    break;
                case "eliminar":
                    await EliminarFicha(values);
                    break;
                case "duplicar":
                    delete values.fecha_actualizacion;
                    delete values.fecha_creacion;
                    delete values.tableData;
                    delete values.__v;
                    delete values._id;
                    await AgregarFicha(values);
                    break;
                default:
                    throw new Error("Acción no especificada.")
            }
            notistack.enqueueSnackbar("Acción realizada exitosamente.", {
                variant: "success",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        } catch (error) {
            console.error(error);
            notistack.enqueueSnackbar("Error al intentar realizar la acción.", {
                variant: "error",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        } finally {
            handleCancelar();
        }
    }

    const handleCancelar = () => {
        mutateFichas();
        mutateProcAsoc();
        mutatekpi();
        mutateExternos();
        mutateInternos();
        mutateProveedores();
        mutateProcesos();
        mutateDocumentos();
        formik.resetForm();
    }

    const handlePrev = (event, row) => {
        formik.setValues(row);
        formik.setFieldValue("tipo_", "previsualizar");
        setOpenPrev(true);
    }

    let columns = [
        {
            title: "Código",
            field: "codigo",
        }, {
            title: "Nombre",
            field: "nombre",
        }, {
            title: "Versión",
            field: "version",

        }, {
            title: "Gerencia",
            align: 'center',
            field: "gerencia",

            render: rowData => (
                <div>
                    {rowData.gerencia && rowData.gerencia.map(type => (
                        <Fragment>
                            {gerencias ? Array.from(gerencias).filter(p => type.includes(p.sigla)).map((d, index) => (
                                <Chip key={d.sigla}
                                    size="small"
                                    variant="default"
                                    color="primary"
                                    style={{ margin: 2 }}
                                    label={d.sigla} />
                            )) : <div></div>}
                        </Fragment>
                    ))}
                </div>
            ),
        }, {
            title: "Asignado a",
            align: "center",

            // field: "version", 
            render: rowData => (
                // <div>
                //     {rowData.gerencia.map(type => (
                <Fragment>
                    {macroprocesos ? Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(rowData._id)).map((d, index) => (
                        <div>
                            <Tooltip title={
                                <div>
                                    Ficha asignada a los siguientes macroprocesos:
                                    {servicios && Array.from(servicios.data).filter(p => p._macroprocesos_ref.includes(d._id)).map((p, index) => (
                                        <Box display="flex" alignItems="center" style={{ height: "100%" }}>
                                            <Check fontSize="small" /> {p.nombre}
                                        </Box>
                                    ))}
                                </div>
                            }>
                                <Chip
                                    key={d.nombre}
                                    size="small"
                                    variant="default"
                                    color="primary"
                                    style={{ margin: 2 }}
                                    label={d.nombre} />
                            </Tooltip>
                        </div>
                    )) : <div></div>}

                </Fragment>
            ),
        }, {
            title: "Responsable",
            field: "responsable",
        }, {
            title: "Fecha Actualización",
            field: "fecha_actualizacion",
            align: 'center',
            defaultSort: "desc",
            render: (row) => Moment(row.fecha_actualizacion).format("DD/MM/YYYY HH:mm"),
        },
        // {
        //     title: "Alcance",
        //     field: "alcance",
        // }, {
        //     title: "Objetivo",
        //     field: "objetivo",
        // }
    ];

    let columnsProcAsoc = [
        {
            title: "Código",
            field: "codigo",
            defaultSort: 'asc'
        }, {
            title: "Tipo",
            field: "tipo_documento",
        }, {
            title: "Nombre",
            field: "nombre",
        }, {
            title: "Área",
            field: "area",
        },
    ];

    let actions = [
        {
            tooltip: "Agregar",
            icon: "add",
            onClick: () => handleAgregar(),
            isFreeAction: true,
        },
        // {
        //     tooltip: "Previsualizar",
        //     icon: () => <Visibility />,
        //     onClick: handlePrev,
        // },
        {
            tooltip: "Editar",
            icon: "edit",
            onClick: handleActualizar,
        },
        {
            tooltip: "Asignar a Macroproceso",
            icon: () => <AssignmentReturn />,
            onClick: handleAbrirAsignacion,
        },
        {
            tooltip: "Duplicar",
            icon: () => <FileCopy />,
            onClick: handleDuplicar,
        }, {
            tooltip: "Eliminar",
            icon: "delete",
            onClick: handleEliminar,
        }
    ];

    let actionsProcAsoc = [
        {
            tooltip: "Ver",
            icon: () => <Visibility />,
            // onClick: handlePrev,
        }, {
            tooltip: "Descargar",
            icon: () => <GetApp />,
            // onClick: handleActualizar,
        },
    ];



    return (
        <Fragment>
            <MaterialTable
                title="Fichas"
                is_loading={!fichas}
                // data={fichas && fichas.data}
                data={fichas && Array.from(fichas.data)
                    .filter(g => g.gerencia.some(j => usuarioSesion.gerencia_visualizar.includes(j))).concat(Array.from(fichas.data).filter(g => Array.from(g.gerencia).length == 0))
                }

                columns={columns}
                actions={actions}
            />
            <Dialog open={Boolean(formik.values.tipo_)} maxWidth="md" fullWidth  >
                <DialogTitle>
                    {formik.values.tipo_ === "agregar" && "Agregar Ficha"}
                    {formik.values.tipo_ === "actualizar" && "Actualizar Ficha"}
                    {formik.values.tipo_ === "duplicar" && "Duplicar Ficha"}
                    {formik.values.tipo_ === "eliminar" && "Eliminar Ficha"}
                    {formik.values.tipo_ === "previsualizar" && "Previsualización de Ficha"}
                </DialogTitle>
                <DialogContent dividers style={{ backgroundColor: "lightgray" }}
                >
                    {formik.values.tipo_ == "actualizar" || formik.values.tipo_ == "agregar" || formik.values.tipo_ == "eliminar" || formik.values.tipo_ == "duplicar" ?
                        (
                            <div>
                                <DialogContentText>Formulario con información de la Ficha.</DialogContentText>
                                <Grid container >

                                    <Grid item xs={12}>
                                        <Paper variant="outlined" style={{ padding: "5px", marginBottom: "5px" }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography fontWeight="bold" variant="h6" style={{ color: "#731f1f" }}>
                                                        <strong>Sección: Información general</strong>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        label="Código"
                                                        name="codigo"
                                                        value={formik.values.codigo}
                                                        onChange={formik.handleChange}
                                                        helperText={formik.errors.codigo}
                                                        error={Boolean(formik.errors.codigo)}
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled={formik.values.tipo_ === "eliminar"}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        label="Versión"
                                                        name="version"
                                                        value={formik.values.version}
                                                        onChange={formik.handleChange}
                                                        helperText={formik.errors.version}
                                                        error={Boolean(formik.errors.version)}
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled={formik.values.tipo_ === "eliminar"}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <FormControl>
                                                        <InputLabel>Normativa ISO</InputLabel>
                                                        <Select
                                                            native
                                                            value={formik.values.normativa_iso}
                                                            onChange={formik.handleChange}
                                                            helperText={formik.errors.normativa_iso}
                                                            error={Boolean(formik.errors.normativa_iso)}
                                                            inputProps={{
                                                                name: 'normativa_iso',
                                                                id: 'age-native-simple',
                                                            }}
                                                            disabled={formik.values.tipo_ === "eliminar" || formik.values.tipo_ === "duplicar"}
                                                        >
                                                            <option aria-label="None" value="" />
                                                            <option value={"Sistemas de Gestión de Calidad"}>Sistemas de Gestión de Calidad</option>
                                                            <option value={"Seguridad y Salud en el Trabajo"}>Seguridad y Salud en el Trabajo</option>

                                                            <option value={"Medio Ambiente"}>Medio Ambiente</option>
                                                            {/* <option value={"Liderazgo"}>Liderazgo</option>
                                                            <option value={"Evaluación de Desempeño"}>Evaluación de Desempeño</option> */}
                                                            <option value={"SGI"}>SGI</option>


                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <TextField
                                                        label="Nombre"
                                                        name="nombre"
                                                        value={formik.values.nombre}
                                                        onChange={formik.handleChange}
                                                        helperText={formik.errors.nombre}
                                                        error={Boolean(formik.errors.nombre)}
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled={formik.values.tipo_ === "eliminar" || formik.values.tipo_ === "duplicar"}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Autocomplete

                                                        multiple
                                                        options={etiquetas ? Array.from(etiquetas.data).filter(p => usuarioSesion.gerencia_visualizar.includes(p.gerencia[0])) : []}
                                                        value={formik.values.etiquetas_ref && etiquetas ? Array.from(etiquetas.data).filter(p => formik.values.etiquetas_ref.includes(p._id)

                                                        ) : []}
                                                        getOptionLabel={(gerencia) => {
                                                            if (gerencia.inputValue) {
                                                                return gerencia.inputValue;
                                                            }
                                                            return `${gerencia.nombre} - ${gerencia.gerencia[0]}`;
                                                        }}
                                                        disabled={formik.values.tipo_ === "eliminar" || formik.values.tipo_ === "duplicar"}
                                                        onChange={(event, value) => {

                                                            // console.log(value.length > 0 && value[value.length - 1].nombre.toLowerCase());
                                                            // console.log(Array.from(gerencias.data));

                                                            // console.log(value.length > 0 && Array.from(etiquetas.data).filter(p => value[value.length - 1].nombre.toLowerCase() == p.nombre.toLowerCase()).length);

                                                            if (value.length > 0 && Array.from(etiquetas.data).filter(p => value[value.length - 1].nombre.toLowerCase() == p.nombre.toLowerCase()).length) {
                                                                console.log("asdasdasdasd");
                                                                formik.setFieldValue("etiquetas_ref", value.map(v => v._id));
                                                            } else if (value.length == 0) {
                                                                console.log("1231232131");
                                                                formik.setFieldValue("etiquetas_ref", []);
                                                            } else {

                                                                // console.log(etiquetaNueva);
                                                                let response = handleEtiqueta(value);
                                                                //     console.log("Verdaderamente no existe");
                                                                // console.log(etiquetaNueva.nombre);
                                                                mutateEtiquetas();
                                                                // console.log(Array.from(etiquetas.data))
                                                                console.log(response);

                                                            }
                                                        }}

                                                        filterOptions={(options, params) => {
                                                            const filtered = filter(options, params);

                                                            const { inputValue } = params;
                                                            // // Suggest the creation of a new value
                                                            const isExisting = options.some((option) => inputValue === option.nombre);
                                                            if (inputValue !== '' && !isExisting) {
                                                                console.log("no existe")
                                                                filtered.push({
                                                                    inputValue: `Agregar etiqueta: "${inputValue}"`,
                                                                    nombre: inputValue,
                                                                    gerencia: [usuarioSesion.gerencia],
                                                                });
                                                            }
                                                            return filtered;


                                                        }}

                                                        renderInput={(params) => (
                                                            <TextField
                                                                label="Etiquetas"
                                                                variant="outlined"
                                                                {...params}
                                                            />
                                                        )}

                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Paper variant="outlined" style={{ padding: "5px", marginBottom: "5px" }}>
                                                        <Grid container spacing={2}>

                                                            <Grid item xs={12}>
                                                                <Typography fontWeight="bold" variant="h6" style={{ color: "#731f1f" }}>
                                                                    Sección: Visualización en gerencias
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>

                                                                <Autocomplete
                                                                    multiple
                                                                    options={gerencias ? gerencias : []}
                                                                    value={formik.values.gerencia && gerencias ? Array.from(gerencias).filter(p => formik.values.gerencia.includes(p.sigla)) : []}
                                                                    getOptionLabel={(gerencia) => gerencia.sigla + " - " + gerencia.nombre}
                                                                    disabled={formik.values.tipo_ === "eliminar" || formik.values.tipo_ === "duplicar"}
                                                                    onChange={(event, value) => {
                                                                        formik.setFieldValue("gerencia", value.map(v => v.sigla));
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField
                                                                            label="Gerencias"
                                                                            variant="outlined"
                                                                            {...params}
                                                                        />
                                                                    )}
                                                                />

                                                            </Grid>
                                                        </Grid>
                                                    </Paper>
                                                </Grid>



                                                <Grid item xs={8}>
                                                    <TextField
                                                        label="Responsable del Proceso"
                                                        name="responsable"
                                                        value={formik.values.responsable}
                                                        onChange={formik.handleChange}
                                                        helperText={formik.errors.responsable}
                                                        error={Boolean(formik.errors.responsable)}

                                                        variant="outlined"
                                                        fullWidth
                                                        disabled={formik.values.tipo_ === "eliminar" || formik.values.tipo_ === "duplicar"}
                                                    />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <DatePicker
                                                        disableFuture
                                                        format="dd/MM/yyyy HH:mm"
                                                        label="Fecha de Actualización"
                                                        value={formik.values.fecha_actualizacion ? formik.values.fecha_actualizacion : value}
                                                        onChange={(date) => {
                                                            formik.setFieldValue("fecha_actualizacion", date);
                                                            console.log(date);
                                                            setValue(date);
                                                        }}
                                                        disabled="true"
                                                        okLabel="Aceptar"
                                                        cancelLabel="Cancelar"
                                                        inputVariant="outlined"

                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <TextField
                                                        label="Objetivo"
                                                        name="objetivo"
                                                        value={formik.values.objetivo}
                                                        onChange={formik.handleChange}
                                                        helperText={formik.errors.objetivo}
                                                        error={Boolean(formik.errors.objetivo)}
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled={formik.values.tipo_ === "eliminar" || formik.values.tipo_ === "duplicar"}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        label="Alcance"
                                                        name="alcance"
                                                        value={formik.values.alcance}
                                                        onChange={formik.handleChange}
                                                        helperText={formik.errors.alcance}
                                                        error={Boolean(formik.errors.alcance)}
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled={formik.values.tipo_ === "eliminar" || formik.values.tipo_ === "duplicar"}
                                                    />
                                                </Grid>

                                            </Grid>

                                        </Paper>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Paper variant="outlined" style={{ padding: "5px", marginBottom: "5px" }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography fontWeight="bold" variant="h6" style={{ color: "#731f1f" }}>
                                                        Sección: Archivos
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography fontWeight="bold">
                                                        Archivo de Procedimiento
                                                    </Typography>
                                                </Grid>
                                                <Grid id="mostrardatos" item xs={10}>
                                                    <Box display="flex">
                                                        <FileInput
                                                            inputName="file_ficha"
                                                            label="Ficha Archivo"
                                                            handleOnChange={(e) => formik.setFieldValue("file_ficha", e.target.files[0])}
                                                            meta={{ touched: formik.touched.file_ficha, error: formik.errors.file_ficha }}
                                                            accept="image/png, image/jpg, image/jpeg, application/pdf"
                                                            buttonColor="primary"
                                                            buttonVariant="outlined"
                                                            value={formik.values.fichaPDF}
                                                            textVariant="outlined"
                                                            disabled={formik.values.tipo_ === "eliminar" || formik.values.tipo_ === "duplicar"}

                                                        />
                                                        {formik.values.fichaPDF && (
                                                            <Box>
                                                                <Tooltip title="Ver Archivo">
                                                                    <IconButton component="a" href={formik.values.fichaPDF?.url} target="_blank" rel="noreferrer" disabled={!formik.values.fichaPDF?.url}>
                                                                        <Link />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={2}>
                                                    <Box display="flex" alignContent="center" style={{ height: "100%" }}>
                                                        <Button variant="contained" color="primary"
                                                            onClick={(event) => {
                                                                formik.setFieldValue("fichaPDF", null);
                                                                // $( "#mostrardatos" ).load(window.location.href + " #mostrardatos" );
                                                                console.log(formik.values.fichaPDF);

                                                            }}>
                                                            Limpiar
                                                        </Button>
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Typography fontWeight="bold">
                                                        Archivo de Flujograma
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={10}>
                                                    <Box display="flex">
                                                        <FileInput
                                                            inputName="file"
                                                            label="Flujograma"
                                                            handleOnChange={(e) => formik.setFieldValue("file", e.target.files[0])}
                                                            meta={{ touched: formik.touched.file, error: formik.errors.file }}
                                                            accept="image/png, image/jpg, image/jpeg, application/pdf"
                                                            buttonColor="primary"
                                                            buttonVariant="outlined"
                                                            value={formik.values.flujograma}
                                                            textVariant="outlined"
                                                            disabled={formik.values.tipo_ === "eliminar" || formik.values.tipo_ === "duplicar"}

                                                        />
                                                        {formik.values.flujograma && (
                                                            <Box paddingLeft={1} paddingRight={1}>
                                                                <Tooltip title="Ver Archivo">
                                                                    <IconButton component="a" href={formik.values.flujograma?.url} target="_blank" rel="noreferrer" disabled={!formik.values.flujograma?.url}>
                                                                        <Link />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                    {/* <TextField
                                            label="Flujograma (Link)"
                                            name="flujograma"
                                            value={formik.values.flujograma}
                                            onChange={formik.handleChange}
                                            helperText={formik.errors.flujograma}
                                            error={Boolean(formik.errors.flujograma)}
                                            variant="outlined"
                                            fullWidth
                                            disabled={formik.values.tipo_ === "eliminar"}
                                        /> */}
                                                </Grid>

                                                <Grid item xs={2}>
                                                    <Box display="flex" alignContent="center" style={{ height: "100%" }}>
                                                        <Button variant="contained" color="primary"
                                                            onClick={(event) => {
                                                                formik.setFieldValue("fichaPDF", null);
                                                                // document.getElementById("file_ficha").value = "";
                                                                console.log(formik.values.fichaPDF);

                                                            }}>
                                                            Limpiar
                                                        </Button>
                                                    </Box>
                                                </Grid>

                                            </Grid>
                                        </Paper>
                                    </Grid>



                                    <Grid item xs={12}>
                                        <Paper variant="outlined" style={{ padding: "5px", marginBottom: "5px" }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography fontWeight="bold" variant="h6" style={{ color: "#731f1f" }}>
                                                        Sección: Referencias
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Autocomplete
                                                        multiple
                                                        options={procedimientosAsociados ? procedimientosAsociados.data : []}
                                                        value={procedimientosAsociados ? Array.from(procedimientosAsociados.data).filter(p => formik.values._procedimientosAsociados_ref.includes(p._id)) : []}
                                                        getOptionLabel={(procedimientosAsociados) => procedimientosAsociados.codigo + " - " + procedimientosAsociados.nombre}
                                                        disabled={formik.values.tipo_ === "eliminar" || formik.values.tipo_ === "duplicar"}
                                                        onChange={(event, value) => {
                                                            formik.setFieldValue("_procedimientosAsociados_ref", value.map(v => v._id));
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                label="Procedimientos Asociados"
                                                                variant="outlined"
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h7" style={{ paddingLeft: "15px" }}>
                                                        <strong>Involucrados</strong>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Autocomplete
                                                        multiple
                                                        options={involucradosInternos ? involucradosInternos.data : []}
                                                        value={involucradosInternos ? Array.from(involucradosInternos.data).filter(p => formik.values._involucrado_asociado_interno_ref.includes(p._id)) : []}
                                                        getOptionLabel={(involucradosInternos) => involucradosInternos.cargo}
                                                        disabled={formik.values.tipo_ === "eliminar" || formik.values.tipo_ === "duplicar"}
                                                        onChange={(event, value) => {
                                                            formik.setFieldValue("_involucrado_asociado_interno_ref", value.map(v => v._id));
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                label="Involucrados Internos"
                                                                variant="outlined"
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Autocomplete
                                                        multiple
                                                        options={involucradosExternos ? involucradosExternos.data : []}
                                                        value={involucradosExternos ? Array.from(involucradosExternos.data).filter(p => formik.values._involucrado_asociado_externo_ref.includes(p._id)) : []}
                                                        getOptionLabel={(involucradosExternos) => involucradosExternos.cargo}
                                                        disabled={formik.values.tipo_ === "eliminar" || formik.values.tipo_ === "duplicar"}
                                                        onChange={(event, value) => {
                                                            formik.setFieldValue("_involucrado_asociado_externo_ref", value.map(v => v._id));
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                label="Involucrados Externos"
                                                                variant="outlined"
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Autocomplete
                                                        multiple
                                                        options={documentosAsociados ? documentosAsociados.data : []}
                                                        value={documentosAsociados ? Array.from(documentosAsociados.data).filter(p => formik.values._documentosAsociados_ref.includes(p._id)) : []}
                                                        getOptionLabel={(documentosAsociados) => documentosAsociados.documento.nombre}
                                                        disabled={formik.values.tipo_ === "eliminar" || formik.values.tipo_ === "duplicar"}
                                                        onChange={(event, value) => {
                                                            formik.setFieldValue("_documentosAsociados_ref", value.map(v => v._id));
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                label="Documentos Asociados"
                                                                variant="outlined"
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Autocomplete
                                                        multiple
                                                        options={fichas ? fichas.data : []}
                                                        value={formik.values._procesosAsociados_ref ? Array.from(fichas.data).filter(p => formik.values._procesosAsociados_ref.includes(p._id)) : []}
                                                        getOptionLabel={(procesosAsociados) => `[${procesosAsociados.codigo}] ${procesosAsociados.nombre}`}
                                                        disabled={formik.values.tipo_ === "eliminar" || formik.values.tipo_ === "duplicar"}
                                                        onChange={(event, value) => {
                                                            formik.setFieldValue("_procesosAsociados_ref", value.map(v => v._id));
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                label="Procesos Asociados"
                                                                variant="outlined"
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Autocomplete
                                                        multiple
                                                        options={proveedoresInformacion ? proveedoresInformacion.data : []}
                                                        value={proveedoresInformacion ? Array.from(proveedoresInformacion.data).filter(p => formik.values._proveedoresInformacion_ref.includes(p._id)) : []}
                                                        getOptionLabel={(proveedoresInformacion) => "[" + proveedoresInformacion.codigo + "] " + proveedoresInformacion.proveedor}
                                                        disabled={formik.values.tipo_ === "eliminar" || formik.values.tipo_ === "duplicar"}
                                                        onChange={(event, value) => {
                                                            formik.setFieldValue("_proveedoresInformacion_ref", value.map(v => v._id));
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                label="Proveedores de Información"
                                                                variant="outlined"
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Autocomplete
                                                        multiple
                                                        options={clientes ? clientes.data : []}
                                                        value={clientes ? Array.from(clientes.data).filter(p => formik.values._entregableCliente_ref.includes(p._id)) : []}
                                                        getOptionLabel={(clientes) => `${clientes.codigo} - ${clientes.cliente}`}
                                                        disabled={formik.values.tipo_ === "eliminar" || formik.values.tipo_ === "duplicar"}
                                                        onChange={(event, value) => {
                                                            formik.setFieldValue("_entregableCliente_ref", value.map(v => v._id));
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                label="Entregables/Cliente"
                                                                variant="outlined"
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </Grid>

                                            </Grid>
                                        </Paper>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Paper variant="outlined" style={{ padding: "5px", marginBottom: "5px" }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography fontWeight="bold" variant="h6" style={{ color: "#731f1f" }}>
                                                        Sección: KPI
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        label="Objetivo KPI"
                                                        name="objetivo_kpi"
                                                        value={formik.values.objetivo_kpi}
                                                        onChange={formik.handleChange}
                                                        variant="outlined"
                                                        fullWidth
                                                        disabled={formik.values.tipo_ === "eliminar" || formik.values.tipo_ === "duplicar"}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Autocomplete
                                                        multiple
                                                        options={kpi ? kpi.data : []}
                                                        value={kpi ? Array.from(kpi.data).filter(p => formik.values._kpi_ref.includes(p._id)) : []}
                                                        getOptionLabel={(kpis) => kpis.indicador}
                                                        disabled={formik.values.tipo_ === "eliminar" || formik.values.tipo_ === "duplicar"}
                                                        onChange={(event, value) => {
                                                            formik.setFieldValue("_kpi_ref", value.map(v => v._id));
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                label="KPI"
                                                                variant="outlined"
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </Grid>

                                            </Grid>
                                        </Paper>
                                    </Grid>
                                    {/* <Paper width="100%"> */}

                                    {/* </Paper> */}


                                </Grid>
                            </div>
                        ) : (
                            <div>
                                {/* <Grid container spacing={2}>
                                    <Grid xs={12} style={{ textAlign: "center", border: "1px solid grey" }}>
                                        <Typography variant="h4">
                                            Ficha de Proceso de {formik.values.nombre}
                                        </Typography>
                                    </Grid>
                                    <Grid xs={5} >
                                        <Grid container style={{ border: "1px solid grey" }}>
                                            <Grid item xs={12}>
                                                <Grid container style={{ border: "1px solid grey" }}>
                                                    <Grid item xs={5} ><Box><strong>MACROPROCESO: </strong></Box></Grid>
                                                    <Grid item xs={7}><Box>Heredado ...</Box></Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container style={{ border: "1px solid grey" }}>
                                                    <Grid item xs={5} ><Box><strong>TIPO: </strong></Box></Grid>
                                                    <Grid item xs={7}><Box>Heredado ...</Box></Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container style={{ border: "1px solid grey" }}>
                                                    <Grid item xs={5} ><Box><strong>CÓDIGO: </strong></Box></Grid>
                                                    <Grid item xs={7}><Box>{formik.values.codigo}</Box></Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container style={{ border: "1px solid grey" }}>
                                                    <Grid item xs={5} ><Box><strong>VERSIÓN: </strong></Box></Grid>
                                                    <Grid item xs={7}><Box>{formik.values.version}</Box></Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container style={{ border: "1px solid grey" }}>
                                                    <Grid item xs={5} ><Box style={{
                                                        height: "100%",
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'left',
                                                    }}><strong>RESPONSABLE:</strong> </Box></Grid>
                                                    <Grid item xs={7}><Box>{formik.values.responsable}</Box></Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container style={{ border: "1px solid grey" }}>
                                                    <Grid item xs={5} ><Box><strong>FECHA DE ACTUALIZACIÓN:</strong> </Box></Grid>
                                                    <Grid item xs={7}>
                                                        <Box style={{
                                                            height: "100%",
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'left',
                                                        }}>
                                                            {Moment(formik.values.fecha_actualizacion).format("DD/MM/YYYY HH:mm")}
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Box style={{ border: "1px solid grey", textAlign: "center" }}>
                                                    <strong>Involucrados Asociados: </strong>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container style={{ border: "1px solid grey" }}>
                                                    <Grid item xs={6} >
                                                        <Box style={{ textAlign: "center" }}>
                                                            <strong>Internos </strong>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6} >
                                                        <Box style={{ textAlign: "center" }}>
                                                            <strong>Externos </strong>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        {involucradosInternos && Array.from(involucradosInternos.data).filter(p => formik.values._involucrado_asociado_interno_ref.includes(p._id)).map((d, index) => (
                                                            <div>{d.cargo} </div>
                                                        ))}
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        {involucradosExternos && Array.from(involucradosExternos.data).filter(p => formik.values._involucrado_asociado_externo_ref.includes(p._id)).map((d, index) => (
                                                            <div>{d.cargo} </div>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Box style={{ border: "1px solid grey", textAlign: "center" }}>
                                                    <strong>Procedimientos Asociados </strong>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} style={{ border: "1px solid grey" }}>
                                                {procedimientosAsociados && Array.from(procedimientosAsociados.data).filter(p => formik.values._procedimientosAsociados_ref.includes(p._id)).map((d, index) => (
                                                    <div><strong>{d.codigo}</strong> - {d.nombre} </div>
                                                ))}
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Box style={{ border: "1px solid grey", textAlign: "center" }}>
                                                    <strong>Documentos Asociados </strong>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {documentosAsociados && Array.from(documentosAsociados.data).filter(p => formik.values._documentosAsociados_ref.includes(p._id)).map((d, index) => (

                                                    <div>{d.nombre}</div>
                                                ))}
                                            </Grid>

                                            <Grid item xs={12} style={{ border: "1px solid grey" }} >
                                                <Box style={{ textAlign: "center" }}>
                                                    <strong>Procesos Asociados </strong>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} style={{ border: "1px solid grey" }}>
                                                {fichas && Array.from(fichas.data).filter(p => formik.values._procesosAsociados_ref.includes(p._id)).map((d, index) => (

                                                    <div><strong>{d.codigo}</strong> - {d.nombre} </div>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={7} style={{ padding: "0px" }}>
                                        <Grid container style={{ border: "1px solid grey" }}>
                                            <Grid item xs={12} >
                                                <Box style={{ border: "1px solid grey", textAlign: "center", height: "100%" }}>
                                                    <strong>Alcance </strong>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {formik.values.alcance}
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Box style={{ border: "1px solid grey", textAlign: "center", height: "100%" }}>
                                                    <strong>Objetivo </strong>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {formik.values.objetivo}
                                            </Grid>

                                        </Grid>
                                        <Grid container style={{ border: "1px solid grey" }} >

                                            <Grid item xs={6} style={{ border: "1px solid grey" }}>
                                                <Box style={{ textAlign: "center", height: "100%" }}>
                                                    <strong>Proveedores de Información: </strong>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} style={{ border: "1px solid grey" }}>
                                                <Box style={{ textAlign: "center", height: "100%" }}>
                                                    <strong>Entradas: </strong>
                                                </Box>
                                            </Grid>
                                            {proveedoresInformacion && Array.from(proveedoresInformacion.data).filter(p => formik.values._proveedoresInformacion_ref.includes(p._id)).map((d, index) => (
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <Box style={{ border: "1px solid grey", textAlign: "center", height: "100%" }}>
                                                            {d.proveedor}
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box style={{ border: "1px solid grey", textAlign: "center", height: "100%" }}>
                                                            {entradas && Array.from(entradas.data).filter(q => d._entradas_ref.includes(q._id)).map((q, index_) => (

                                                                <div>{q.nombre}</div>
                                                            ))}
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            ))}

                                        </Grid>

                                        <Grid container style={{ border: "1px solid grey" }}>
                                            <Grid item xs={12} >
                                                <Box style={{ border: "1px solid grey", textAlign: "center", height: "100%" }}>
                                                    <strong>Flujograma </strong>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Box style={{ border: "1px solid grey", textAlign: "center", height: "100%", paddingTop: "1px" }}>
                                                    <img src="https://educarparaelcambio.files.wordpress.com/2017/04/parking-domc3b3tico-arduino-flujograma1.png?w=660&h=470"
                                                        style={{ maxHeight: "200px" }}
                                                    />
                                                </Box>
                                            </Grid>


                                        </Grid>


                                    </Grid>
                                    <Grid item xs={5} style={{ padding: "0px", border: "1px solid grey" }}>
                                        <Grid container >
                                            <Grid item xs={12} >
                                                <Box style={{ border: "1px solid grey", textAlign: "center", height: "100%" }}>
                                                    <strong>KPI </strong>
                                                </Box>
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs={3} >
                                                    <Box style={{ border: "1px solid grey", textAlign: "left", height: "100%", paddingTop: "1px" }}>
                                                        <strong>Objetivo:</strong>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={9} >
                                                    <Box style={{ border: "1px solid grey", textAlign: "left", height: "100%", paddingTop: "1px" }}>
                                                        {formik.values.objetivo_kpi}
                                                    </Box>
                                                </Grid>
                                                {kpi && Array.from(kpi.data).filter(p => formik.values._kpi_ref.includes(p._id)).map((d, index) => (
                                                    <Grid container style={{ border: "1px solid grey" }}>
                                                        <Grid item xs={3}>
                                                            <strong>Indicador:</strong>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            {d.indicador}
                                                        </Grid>

                                                        <Grid item xs={3}>

                                                            <strong>Criterio de aceptación:</strong>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            <Box style={{
                                                                height: "100%",
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'left',
                                                            }}>
                                                                {d.criterio}
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={3}>
                                                            <strong>Frecuencia:</strong>
                                                        </Grid>
                                                        <Grid item xs={9}>
                                                            {d.frecuencia}
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                    <Grid xs={7}>
                                        <Grid container style={{ border: "1px solid grey" }} >

                                            <Grid item xs={6} style={{ border: "1px solid grey" }}>
                                                <Box style={{ textAlign: "center", height: "100%" }}>
                                                    <strong>Clientes: </strong>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} style={{ border: "1px solid grey" }}>
                                                <Box style={{ textAlign: "center", height: "100%" }}>
                                                    <strong>Entregables: </strong>
                                                </Box>
                                            </Grid>
                                            {clientes && Array.from(clientes.data).filter(p => formik.values._entregableCliente_ref.includes(p._id)).map((d, index) => (
                                                <Grid container>
                                                    <Grid item xs={6}>
                                                        <Box style={{ border: "1px solid grey", textAlign: "center", height: "100%" }}>
                                                            {d.cliente}
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Box style={{ border: "1px solid grey", textAlign: "center", height: "100%" }}>
                                                            {entregables && Array.from(entregables.data).filter(q => d._entregable_ref.includes(q._id)).map((q, index_) => (

                                                                <div>{q.nombre}</div>
                                                            ))}
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            ))}

                                        </Grid>
                                    </Grid>

                                </Grid> */}


                            </div>)}

                </DialogContent>
                {formik.values.tipo_ === "actualizar" || formik.values.tipo_ == "agregar" || formik.values.tipo_ == "eliminar" || formik.values.tipo_ == "duplicar" ?
                    (
                        <DialogActions>
                            <Button onClick={handleCancelar} variant="outlined" color="primary">
                                Cancelar
                            </Button>
                            <Button onClick={formik.submitForm} variant="contained" color="primary">
                                Aceptar
                            </Button>
                        </DialogActions>
                    ) : (
                        <DialogActions>
                            <Button onClick={handleCancelar} variant="outlined" color="primary">
                                Cerrar
                            </Button>

                        </DialogActions>
                    )
                }
            </Dialog>

            <Dialog open={openAsignacion} fullWidth>
                <DialogAsignacion _id_={_id_} titulo="Ficha" setOpenAsignacion={setOpenAsignacion} mutate={mutateMacroprocesos} />
            </Dialog>


        </Fragment >
    );
}

export default TabPerfiles;