import React, { useState, createContext, useEffect } from "react";
import * as ROUTES from "./constants/routes";
import * as URLS from "./constants/urls";
import * as Permissions from "./constants/permissions";
import { Route, Switch, Redirect } from "react-router-dom";
import axios from "axios";
import {
  GetPersona,
  IniciarSession,
} from "./services/request/usuarios/personas";
import { ObtenerUsuarioPlataforma } from "./services/request/usuarios/usuariosPlataforma";
import { GetGerencia } from "./services/request/portafolio/gerencias";
import { makeStyles } from "@material-ui/styles";
import { auth, onAuthStateChange } from "./services/firebase";
import Error401 from "./container/401";
import HomeContainer from "./container/home/components/servicio";
// import HomeContainer from "./container/home";

import HomeSGI from "./container/Inicio";

import Servicio from "./container/home/components/servicio";
// import AgregarMapa from "./container/agregarMapa";
// import
import AppBar from "./components/appBar";
import { redirectToAccount } from "./services/auth";
import Loading from "./components/page_loading";
import Ajustes from "./container/ajustes/administracion";
import Permisos from "./container/ajustes/permisos";


// import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
// import DateFnsUtils from '@date-io/date-fns';
// import DateAdapter from "@material-ui/lab/AdapterMoment";
// import LocalizationProvider from "@material-ui/lab/LocalizationProvider";

import {
  obtenerDatosSesionUsuario,
  getUsuario_contrato,
} from "./services/request/requestUsuarios";

export const MainContext = createContext({
  usuarioSesion: null,
  contratoSeleccionado: null,
  setContratoSeleccionado: null,
  accessError: false,
  setAccessError: () => { },
  permisos: {},
  setPermisos: () => { },

  handleCheckProcesos:() => {},
  checkProcesos: true,

  handleCheckArchivos:() => {},
  checkArchivos: false,

  handleCheckContratos:() => {},
  checkContratos: false,

  handleCheckEtiquetas:() => {},
  checkEtiquetas: false,

  searchServicio: null,
  mostrarBuscar: false,
  setMostrarBuscar: null,
  filtroIso: null,
  setFiltroIso: null,
});

export default function App() {
  const classes = useStyles();

  const [usuarioSesion, setUsuarioSesion] = useState(null);
  const [permisos, setPermisos] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [accessError, setAccessError] = useState(true);
  const [searchServicio, setSearchServicio] = useState(null);
  const [contratoSeleccionado, setContratoSeleccionado] = useState(null);
  const [mostrarBuscar, setMostrarBuscar] = useState(null);
  const [filtroIso, setFiltroIso] = useState(null);

  const [checkProcesos, setCheckProcesos] = useState(true);
  const [checkArchivos, setCheckArchivos] = useState();
  const [checkContratos, setCheckContratos] = useState();
  const [checkEtiquetas, setCheckEtiquetas] = useState();





  const checkSession = (isLogged) => {
    try {
      if (isLogged && auth.currentUser) {
        const user = auth.currentUser;
        ObtenerDatosUsuario(user);
      } else {
        login();
      }
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Método encargado de cargar la información del usuario, TAGs, gerencia.
   * @param {*} user
   */
  async function ObtenerDatosUsuario(user) {
    try {
      // let response = await IniciarSession();

      let response = await obtenerDatosSesionUsuario();

      console.log(response);
      // console.log(response2);
      let persona = await getUsuario_contrato(response.uid);
      console.log(persona);
      // let gerencia = await getGerencia(persona.gerencia_ref);
      let usuarioPlataforma = await ObtenerUsuarioPlataforma(persona.auth_id);

      console.log(usuarioPlataforma);

      obtenerDatosSesionUsuario()
        .then((response) => {
          
          console.log(response);
          console.log(user.uid);
          getUsuario_contrato(user.uid)
            .then((usuario) => {
              console.log(usuario);
              console.log(usuario.data[0].auth_id);
              // console.log(usuarioPlataforma);
              ObtenerUsuarioPlataforma(usuario.data[0].auth_id)
              .then((userPlat) =>{
                console.log(userPlat);
                setUsuarioSesion({
                  _id: usuario.data[0]._id,
                  uid: user.uid,
                  photoUrl: user.photoURL,
                  nombre: user.displayName,
                  email: user.email,
                  ref: response.ref,
                  gerencia: usuario.data[0].gerencia_ref.sigla,
                  contrato: usuario.data[0].contratos_ref[0],
                  contrato_ref: usuario.data[0].contratos_ref[0].nombre,
                  contratos_visibles: usuario.data[0].contratos_visibles_ref,
                  proyectos_visibles: usuario.data[0].proyectos_visibles_ref,
                  gerencia_visualizar: userPlat ? userPlat.gerencia_sigla : usuario.data[0].gerencia_ref.sigla,
                  // gerencia_visualizar: usuario.data[0].gerencia_ref.sigla,

                });

              })
              
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((e) => {
          console.error(e);
        });
      if (response.permisos) {
        setPermisos(response.permisos);
      } else {
        setPermisos({});
      }
      setIsLoading(false);
      setAccessError(false);
      console.log(usuarioSesion);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const handleSearchServicio = (value) => {
    console.log(value);
    if (value == "") {
      setSearchServicio(null);
    } else {
      setSearchServicio(value);
      // setSearchServicio("value");


    }
  };

  /**
   * Cuqlquier tontera
   */
  const handleCheckProcesos = () => {
    setCheckProcesos(!checkProcesos);
  };

  const handleCheckArchivos = () => {
    setCheckArchivos(!checkArchivos);
  };

  const handleCheckContratos = () => {
    setCheckContratos(!checkContratos);
  };

  const handleCheckEtiquetas = () => {
    setCheckEtiquetas(!checkEtiquetas);
  };


  const handleFiltroIso = (value) => {
    if (value == "") {
      setFiltroIso(null);
    } else {
      setFiltroIso(value);

    }
  };

  const handleBuscarBox = (value) => {

  };

  useEffect(() => {
    const unsubscribe = onAuthStateChange(checkSession);
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    console.log(contratoSeleccionado?.codigo);
  }, [contratoSeleccionado]);

  // useEffect(() => {
  //   console.log(searchServicio);
  //   console.log(filtroIso);

  //   if (searchServicio == null) {
  //     console.log("es null");
  //   }
  // }, [searchServicio, filtroIso]);

  const privateRoute = ({
    component: Component,
    permission,
    path,
    ...rest
  }) => {
    return (
      <Route
        path={path}
        {...rest}
        render={(props) => {
          console.log(props);
          if (accessError) {
            return <Redirect to="/accessError" />;
          }
          if (!accessError && path === ROUTES.ACCESS_ERROR) {
            return <Redirect to="/" />;
          }
          if (permission && usuarioSesion && !permisos[permission]) {
            return <Redirect to="/" />;
          }
          return <Component {...props} />;
        }}
      />
    );
  };

  const mainAppNew = (
    <React.Fragment>
      {/* <LocalizationProvider dateAdapter={DateFnsUtils}> */}
      <MainContext.Provider
        value={{
          usuarioSesion,
          contratoSeleccionado,
          setContratoSeleccionado,
          accessError,
          setAccessError,
          permisos,
          setPermisos,
          searchServicio,
          setMostrarBuscar,          
          mostrarBuscar,
          filtroIso,
          setFiltroIso,

          handleCheckProcesos,
          checkProcesos: checkProcesos,

          handleCheckArchivos,
          checkArchivos: checkArchivos,

          handleCheckContratos,
          checkContratos: checkContratos,

          handleCheckEtiquetas,
          checkEtiquetas: checkEtiquetas,
        }}
      >
        <div
          style={{
            paddingTop: "80px",
            overflow: "hidden",
            backgroundColor: "white",
          }}
        >
          <AppBar nombreModulo=" BPM Cydocs" handleSearchServicio={handleSearchServicio} handleBuscarBox={handleBuscarBox}/>
          <div
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              paddingLeft: "3.5rem",
              marginBottom: "1rem",
              width: "-webkit-fill-available",
            }}
          >
            <Switch>

              {/* REAL HOME */}
              <Route
                exact={true}
                path={ROUTES.HOME}
                component={() => <HomeContainer handleCheckProcesos={handleCheckProcesos} checkProcesos={checkProcesos} handleFiltroIso={handleFiltroIso} handleSearchServicio={handleSearchServicio}/>}
              />
              <Route
                exact={true}
                path={ROUTES.SGI}
                component={() => <HomeSGI />}
              />
              <Route
                exact={true}
                path={ROUTES.MAPAS}
                component={() => <Servicio/>}
              />
              <Route
                exact={true}
                path={ROUTES.ACCESS_ERROR}
                component={() => <Error401 />}
              />
              {privateRoute({
                component: Ajustes,
                permission: Permissions.VER_AJUSTES,
                path: ROUTES.AJUSTES,
                exact: true,
              })}

              {/* <Route
                exact={true}
                path={ROUTES.AJUSTES}
                component={() => <Ajustes />}
              /> */}
              {privateRoute({
                component: Permisos,
                permission: Permissions.VER_PERMISOS,
                path: ROUTES.PERMISOS,
                exact: true,
              })}
              {/* <Route
                exact={true}
                path={ROUTES.PERMISOS}
                component={() => <Permisos />}
              /> */}
            </Switch>
          </div>
        </div>
      </MainContext.Provider>
      {/* </LocalizationProvider> */}
    </React.Fragment>
  );

  if (usuarioSesion && permisos && !isLoading && !accessError) {
    return mainAppNew;
  } else {
    return <Loading />;
  }
}

const login = async () => {
  let currenToken = getQueryVariable("csrfToken");
  if (!currenToken) {
    currenToken = localStorage.getItem("csrfToken");
  }
  localStorage.setItem("csrfToken", currenToken);
  if (currenToken && currenToken !== "null" && currenToken !== "undefined") {
    localStorage.setItem("contrato", "");
    localStorage.setItem("proyecto", "");
    await axios
      .post(
        `${URLS.REDIRECT_BASE}/verifySessionCookie`,
        {},
        {
          withCredentials: true,
          crossDomain: true,
          params: {
            _csrf: currenToken,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.token) {
          auth.signInWithCustomToken(response.data.token);
        }
      })
      .catch((error) => {
        console.log(error);
        redirectToAccount();
      });
  } else {
    redirectToAccount();
  }
};

const getQueryVariable = function (variable) {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
  },
  paper: {
    padding: theme.spacing(),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  footer: {
    marginTop: "1rem",
    padding: "1rem",
    position: "fixed",
    color: theme.palette.text.secondary,
    bottom: 0,
    left: 0,
    width: "100%",
  },
  appBar: {
    top: "auto",
    bottom: 0,
  },
}));
