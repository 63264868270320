import React, {useContext} from "react";
import Page from "./page";
import { MainContext } from "../../../../App";


function Index(props) {

  const { usuarioSesion } = useContext(MainContext);

  return (
    <Page
    usuarioSesion = {usuarioSesion} />
  );
}

export default Index;