import { BACKEND } from "../../../constants/urls";
import Axios from "axios";

/**
 * Método encargado de obtener un perfil, por su ID.
 * @param {*} entradaID ID del perfil.
 */
export async function ObtenerEntrada(entradaID) {
  try {
    let url = `${BACKEND}/entrada/${entradaID}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todos los perfiles.
 */
export async function ObtenerEntradas() {
  try {
    let url = `${BACKEND}/entrada`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de agregar un nuevo perfil.
 * @param {*} entrada Datos del perfil.
 */
export async function AgregarEntrada(entrada) {
  try {
    let url = `${BACKEND}/entrada`;
    let response = await Axios.post(url, entrada);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de actualizar un perfil.
 * @param {*} entrada Datos actualizados del perfil.
 */
export async function ActualizarEntrada(entrada) {
  try {
    let url = `${BACKEND}/entrada/${entrada._id}`;
    let response = await Axios.put(url, entrada);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de eliminar un perfil.
 * @param {*} entrada Datos del perfil.
 */
export async function EliminarEntrada(entrada) {
  try {
      
    let url = `${BACKEND}/entrada/${entrada._id}`;
    console.log(url);
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}