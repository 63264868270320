import { BACKEND } from "../../../constants/urls";
import Axios from "axios";

/**
 * Método encargado de obtener un perfil, por su ID.
 * @param {*} clienteID ID del perfil.
 */
export async function ObtenerCliente(clienteID) {
  try {
    let url = `${BACKEND}/cliente/${clienteID}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todos los perfiles.
 */
export async function ObtenerClientes() {
  try {
    let url = `${BACKEND}/cliente`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de agregar un nuevo perfil.
 * @param {*} cliente Datos del perfil.
 */
export async function AgregarCliente(cliente) {
  try {
    let url = `${BACKEND}/cliente`;
    let response = await Axios.post(url, cliente);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de actualizar un perfil.
 * @param {*} cliente Datos actualizados del perfil.
 */
export async function ActualizarCliente(cliente) {
  try {
    let url = `${BACKEND}/cliente/${cliente._id}`;
    let response = await Axios.put(url, cliente);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de eliminar un perfil.
 * @param {*} cliente Datos del perfil.
 */
export async function EliminarCliente(cliente) {
  try {
      
    let url = `${BACKEND}/cliente/${cliente._id}`;
    console.log(url);
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}