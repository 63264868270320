import React, { Fragment, Label, useEffect } from "react";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { DatePicker } from "@material-ui/pickers";
import { useFormik } from "formik";
import { object, string, array, date } from "yup";
import Moment from "moment";
import { Accordion,Typography, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, TextField } from "@material-ui/core";
import { Close, ExpandMore, FiberManualRecord, GetApp, Visibility } from "@material-ui/icons";
import { ObtenerProcAsoc } from "../../services/request/mapaProcesos/requestProcedimientoAsociado";
import { ObtenerDocumentos } from "../../services/request/mapaProcesos/requestDocumentos";
import MaterialTable from "../../components/materialTable";




function ProcesoAsociado(props) {

    const {
        idProceso,
        tipo_proceso,
    } = props;

    const [openDialog, setOpenDialog] = React.useState(false);
    const notistack = useSnackbar();

    const { data: procedimientoAsociado, error: errorProcedimientoAsociado, mutate: mutateProcedimientoAsociado } = useSWR("procedimiento_asociado", (key) => ObtenerProcAsoc(idProceso), { revalidateOnFocus: false });
    const { data: documentos, error: errorDocumentos, mutate: mutateDocumentos } = useSWR("documentos", (key) => ObtenerDocumentos(), { revalidateOnFocus: false });


    let columnsDoc = [
        {
            title: "Código",
            field: "codigo",
            defaultSort: 'asc'
        }, {
            title: "Tipo",
            field: "tipo_documento",
        }, {
            title: "Nombre",
            field: "nombre",
        },
    ];


    let actionsDoc = [
        {
            tooltip: "Ver",
            icon: () => <Visibility />,
            // onClick: handlePrev,
        }, {
            tooltip: "Descargar",
            icon: () => <GetApp />,
            // onClick: handleActualizar,
        },
    ];


    useEffect(() => {

        if (errorProcedimientoAsociado) {
            notistack.enqueueSnackbar("Error al intentar obtener las fichas.", {
                variant: "error",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        }

    }, [errorProcedimientoAsociado]);



    return (
        <Fragment>

            <Grid container spacing={2}>
                <Paper variant="outlined" style={{ width: "100%", margin: "5px" }}>
                    <Grid container>
                        <Grid xs={10} >
                            <Grid container>
                                <Grid item xs={2} >
                                    <Box>
                                        <Typography fontWeight="bold" color="#731f1f">
                                            TIPO:
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={10}><Box >{tipo_proceso}</Box></Grid>

                                <Grid item xs={2}>
                                    <Box>
                                        <Typography fontWeight="bold" color="#731f1f">
                                            NOMBRE:
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={10}><Box >{procedimientoAsociado && procedimientoAsociado.nombre}</Box></Grid>

                                <Grid item xs={2}>
                                    <Box>
                                        <Typography fontWeight="bold" color="#731f1f">
                                            CÓDIGO:
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={10}><Box >{procedimientoAsociado && procedimientoAsociado.codigo}</Box></Grid>

                                <Grid item xs={2}>
                                    <Box>
                                        <Typography fontWeight="bold" color="#731f1f">
                                            ÁREA:
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={10}><Box>{procedimientoAsociado && procedimientoAsociado.area}</Box></Grid>

                                <Grid item xs={2}>
                                    <Box>
                                        <Typography fontWeight="bold" color="#731f1f">
                                            VERSIÓN:
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={10}><Box >{procedimientoAsociado && procedimientoAsociado.version}</Box></Grid>
                            </Grid>

                        </Grid>
                        <Grid xs={1} style={{}}>
                            <Box style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: "98%",
                            }}>
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png" height="60px" />
                            </Box>

                        </Grid>

                    </Grid>
                </Paper>

                {/* </ Paper> */}
                <Paper variant="outlined" style={{ width: "100%", margin: "5px" }}>
                    <Grid item xs={12} style={{ textAlign: "center", marginBottom: "5px" }}>
                        <Box>
                            <Typography fontWeight="bold" color="#731f1f">
                                ALCANCE:
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "10px" }}>
                        {procedimientoAsociado && procedimientoAsociado.alcance}
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: "center", marginBottom: "5px" }}>
                        <Box>
                            <Typography fontWeight="bold" color="#731f1f">
                                OBJETIVO:
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: "10px" }}>
                        {procedimientoAsociado && procedimientoAsociado.objetivo}
                    </Grid>
                </Paper>
                <Grid item xs={12}>
                    <MaterialTable
                        // title="Documentos"
                        is_loading={!documentos}
                        data={documentos && procedimientoAsociado ? Array.from(documentos.data).filter(p => procedimientoAsociado._documentos_ref.includes(p._id)) : []}
                        columns={columnsDoc}
                        actions={actionsDoc}
                        showTitle={false}
                        toolbar={false}
                        columnsButton={false}
                        grouping={false}
                        filtering={false}
                    />
                </Grid>
            </Grid>

        </Fragment >
    );
}

export default ProcesoAsociado;