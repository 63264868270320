import React from "react";
import Page from "./page";

function Index(props) {

  const {
    handleSearchServicio,
    macroproceso,
    tipo_proceso,
    idFicha,
  } = props;

  return (
    <Page macroproceso={macroproceso} tipo_proceso={tipo_proceso} idFicha={idFicha} handleSearchServicio={handleSearchServicio}/>
  );
}

export default Index;