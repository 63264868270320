import React, { Fragment, Label, useContext, useEffect } from "react";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import Moment from "moment";
import { useFormik } from "formik";
import { object, string, array } from "yup";
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputLabel, FormControl, Grid, IconButton, Select, TextField } from "@material-ui/core";
import { Close, FileCopy, GetApp, Visibility, AssignmentReturn } from "@material-ui/icons";
import MaterialTable from "../../../../components/materialTable";
import { ObtenerMacroprocesos, AgregarMacroproceso, ActualizarMacroproceso, EliminarMacroproceso } from "../../../../services/request/mapaProcesos/requestMacroproceso";
import { ObtenerFichas } from "../../../../services/request/mapaProcesos/requestFicha";
import { Autocomplete } from "@material-ui/lab";
import { ObtenerGerencias } from "../../../../services/request/proyectos/requestGerencias";
import { ObtenerServicios } from "../../../../services/request/mapaProcesos/requestServicio";
import { MainContext } from "../../../../App";


import DialogAsignacion from "../../../../components/DialogAsignar";
import moment from "moment";

function TabPerfiles(props) {
    const { data: macroprocesos, error: errorMacroprocesos, mutate: mutateMacroprocesos } = useSWR("macroprocesos", (key) => ObtenerMacroprocesos(), { revalidateOnFocus: false });
    const { data: fichas, error: errorFichas, mutate: mutateFichas } = useSWR("fichas", (key) => ObtenerFichas(), { revalidateOnFocus: false });
    const { data: gerencias, error: errorGerencias, mutate: mutateGerencias } = useSWR("gerencias", (key) => ObtenerGerencias(), { revalidateOnFocus: false });
    const { data: servicios, error: errorServicios, mutate: mutateServicios } = useSWR("servicios", (key) => ObtenerServicios(), { revalidateOnFocus: false });


    const [openPrev, setOpenPrev] = React.useState(false);
    const notistack = useSnackbar();
    const [openAsignacion, setOpenAsignacion] = React.useState(false);
    const [_id_, set_id_] = React.useState(null);

    const { usuarioSesion } = useContext(MainContext);


    const handleAbrirAsignacion = (event, row) => {
        set_id_(row._id);
        setOpenAsignacion(true);

    }


    useEffect(() => {
        if (errorMacroprocesos) {
            notistack.enqueueSnackbar("Error al intentar obtener los procedimientos asociados.", {
                variant: "error",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        }
        if (errorFichas) {
            notistack.enqueueSnackbar("Error al intentar obtener los documentos.", {
                variant: "error",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        }
    }, [errorMacroprocesos, errorFichas]);

    const formik = useFormik({
        initialValues: {
            nombre: "",
            tipo_proceso: "",
            etiqueta: "",
            _fichas_ref: [],
            gerencia: [],
        },
        validationSchema: object().shape({
            nombre: string()
                .min(3, "El nombre debe tener al menos ${min} caracteres.")
                .max(150, "El nombre debe tener a los más ${max} caracteres.")
                .required("El nombre es requerido."),
            tipo_proceso: string()
                .min(3, "El tipo de proceso debe tener al menos ${min} caracteres.")
                .max(50, "El tipo de proceso debe tener a los más ${max} caracteres.")
                .required("El tipo de proceso es requerido."),
            etiqueta: string()
                .min(3, "La etiqueta debe tener al menos ${min} caracteres.")
                .max(50, "La etiqueta debe tener a los más ${max} caracteres."),
                // .required("La etiqueta es requerida."),
            _fichas_ref: array()
                .of(string())
                .optional(),
            gerencia: array()
                .of(string())
                .optional(),

        }),
        onSubmit: (values, helper) => handleAceptar(values),
        enableReinitialize: true,
    });

    /**
     * Handler para agregar un nuevo perfil.
     */
    const handleAgregar = () => {
        formik.setFieldValue("tipo_", "agregar");
    }

    /**
     * Handler para actualizar un perfil.
     * @param {*} event Evento.
     * @param {*} row Datos.
     */
    const handleActualizar = (event, row) => {
        formik.setValues(row);
        formik.setFieldValue("tipo_", "actualizar");
    }

    /**
     * Handler para eliminar un perfil.
     * @param {*} event Evento.
     * @param {*} row Datos.
     */
    const handleEliminar = (event, row) => {
        formik.setValues(row);
        formik.setFieldValue("tipo_", "eliminar");
    }

    const handleDuplicar = (event, row) => {


        console.log(row);
        formik.setValues(row);
        formik.setFieldValue("nombre", row.nombre + " copia")
        formik.setFieldValue("tipo_", "duplicar");
    }

    /**
     * Handler para aceptar la acción.
     * @param {*} values 
     */
    const handleAceptar = async (values) => {
        try {
            switch (values.tipo_) {
                case "agregar":
                    console.log(values);
                    await AgregarMacroproceso(values);
                    break;
                case "actualizar":
                    await ActualizarMacroproceso(values);
                    break;
                case "eliminar":
                    await EliminarMacroproceso(values);
                    break;
                case "duplicar":
                    console.log();
                    delete values.fecha_actualizacion;
                    delete values.fecha_creacion;
                    delete values.tableData;
                    delete values.__v;
                    delete values._id;
                    await AgregarMacroproceso(values);
                    break;
                default:
                    throw new Error("Acción no especificada.")
            }
            notistack.enqueueSnackbar("Acción realizada exitosamente.", {
                variant: "success",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        } catch (error) {
            console.error(error);
            notistack.enqueueSnackbar("Error al intentar realizar la acción.", {
                variant: "error",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        } finally {
            handleCancelar();
        }
    }

    const handleCancelar = () => {
        mutateMacroprocesos();
        mutateFichas();
        formik.resetForm();
    }


    const handlePrev = (event, row) => {
        formik.setValues(row);
        formik.setFieldValue("tipo_", "previsualizar");
        setOpenPrev(true);
    }

    let columns = [
        {
            title: "Nombre",
            field: "nombre",
            // defaultSort: 'asc'
        }, {
            title: "Tipo",
            align: 'center',
            field: "tipo_proceso",
            width: "10%",

        }, {
            title: "Gerencia",
            align: 'center',
            field: "gerencia",
            width: "15%",

            render: rowData => (
                <div>
                    {rowData.gerencia && rowData.gerencia.map(type => (
                        <Fragment>
                            {gerencias ? Array.from(gerencias).filter(p => type.includes(p.sigla)).map((d, index) => (
                                <Chip key={d.sigla}
                                    size="small"
                                    variant="default"
                                    color="primary"
                                    style={{ margin: 2 }}
                                    label={d.sigla} />
                            )) : <div></div>}
                        </Fragment>
                    ))}
                </div>
            ),
        },
        {
            title: "Asignado a",
            align: "center",
            width: "20%",
            render: rowData => (
                // <div>
                //     {rowData.gerencia.map(type => (
                <Fragment>
                    {/* {macroprocesos ? Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(rowData._id)).map((d, index) => ( */}
                    {servicios && Array.from(servicios.data).filter(p => p._macroprocesos_ref.includes(rowData._id)).map((p, index) => (
                        <div>
                            {/* <Tooltip title={
                                <div>
                                    Macroproceso asignado a:
                                    {servicios && Array.from(servicios.data).filter(p => p._macroprocesos_ref.includes(d._id)).map((p, index) => (
                                        <Box display="flex" alignItems="center" style={{ height: "100%" }}>
                                            <Check fontSize="small" /> {p.nombre}
                                        </Box>
                                    ))}
                                </div>
                            }> */}
                            <Chip
                                key={p.nombre}
                                size="small"
                                variant="default"
                                color="primary"
                                style={{ margin: 2 }}
                                label={p.nombre} />
                            {/* </Tooltip> */}
                        </div>
                    ))}

                </Fragment>
            ),

        }
        , {
            title: "Fecha Actualización",
            field: "fecha_actualizacion",
            align: 'center',
            defaultSort: "desc",
            width: "15%",
            render: (row) => Moment(row.fecha_actualizacion).format("DD/MM/YYYY HH:mm"),
        },
    ];

    let actions = [
        {
            tooltip: "Agregar",
            icon: "add",
            onClick: () => handleAgregar(),
            isFreeAction: true,
        },

        {
            tooltip: "Editar",
            icon: "edit",
            onClick: handleActualizar,
        },
        {
            tooltip: "Asignar a Contrato",
            icon: () => <AssignmentReturn />,
            onClick: handleAbrirAsignacion,
        },
        {
            tooltip: "Duplicar",
            icon: () => <FileCopy />,
            onClick: handleDuplicar,
        }, {
            tooltip: "Eliminar",
            icon: "delete",
            onClick: handleEliminar,
        }
    ];



    return (
        <Fragment>
            <MaterialTable
                title="Macroprocesos"
                is_loading={!macroprocesos}
                // data={macroprocesos && macroprocesos.data}
                data={macroprocesos && Array.from(macroprocesos.data).filter(g => g.gerencia.some(j => usuarioSesion.gerencia_visualizar.includes(j))).concat(Array.from(macroprocesos.data).filter(g => Array.from(g.gerencia).length == 0))}
                // data={macroprocesos && Array.from(macroprocesos.data).filter(g => Array.from(g.gerencia).length == 0) }


                columns={columns}
                actions={actions}
            />
            <Dialog open={Boolean(formik.values.tipo_)} maxWidth="md" fullWidth>
                <DialogTitle>
                    {formik.values.tipo_ === "agregar" && "Agregar Macroproceso"}
                    {formik.values.tipo_ === "actualizar" && "Actualizar Macroproceso"}
                    {formik.values.tipo_ === "duplicar" && "Duplicar Macroproceso"}
                    {formik.values.tipo_ === "eliminar" && "Eliminar Macroproceso"}
                    {formik.values.tipo_ === "previsualizar" && "Previsualización de Macroproceso"}
                </DialogTitle>
                <DialogContent dividers>
                    {formik.values.tipo_ == "actualizar" || formik.values.tipo_ == "agregar" || formik.values.tipo_ == "eliminar" || formik.values.tipo_ == "duplicar" ?
                        (
                            <div>
                                <DialogContentText>Formulario con información del Macroproceso.</DialogContentText>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <TextField
                                            label="Nombre"
                                            name="nombre"
                                            value={formik.values.nombre}
                                            onChange={formik.handleChange}
                                            helperText={formik.errors.nombre}
                                            error={Boolean(formik.errors.nombre)}
                                            variant="outlined"
                                            fullWidth
                                            disabled={formik.values.tipo_ === "eliminar"}
                                        />
                                    </Grid>
                                    
                                    <Grid item xs={4}>
                                        <FormControl style={{width:"100%"}}>
                                            <InputLabel>Tipo Proceso</InputLabel>
                                            <Select
                                                native
                                                value={formik.values.tipo_proceso}
                                                onChange={formik.handleChange}
                                                helperText={formik.errors.tipo_proceso}
                                                error={Boolean(formik.errors.tipo_proceso)}
                                                inputProps={{
                                                    name: 'tipo_proceso',
                                                    id: 'age-native-simple',
                                                }}
                                                disabled={formik.values.tipo_ === "eliminar" || formik.values.tipo_ === "duplicar"}
                                            >
                                                <option aria-label="None" value="" />
                                                <option value={"Estratégico"}>Estratégico</option>
                                                <option value={"Operativo"}>Operativo</option>
                                                <option value={"Soporte"}>Soporte</option>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            multiple
                                            options={fichas ? fichas.data : []}
                                            value={fichas ? Array.from(fichas.data).filter(p => formik.values._fichas_ref.includes(p._id)) : []}
                                            getOptionLabel={(fichas) => fichas.codigo + " - " + fichas.nombre}
                                            disabled={formik.values.tipo_ === "eliminar" || formik.values.tipo_ === "duplicar"}
                                            onChange={(event, value) => {
                                                formik.setFieldValue("_fichas_ref", value.map(v => v._id));
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    label="Proceso"
                                                    variant="outlined"
                                                    {...params}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>

                                        <Autocomplete
                                            multiple
                                            options={gerencias ? gerencias : []}
                                            value={formik.values.gerencia && gerencias ? Array.from(gerencias).filter(p => formik.values.gerencia.includes(p.sigla)) : []}
                                            getOptionLabel={(gerencia) => gerencia.sigla + " - " + gerencia.nombre}
                                            disabled={formik.values.tipo_ === "eliminar"}
                                            onChange={(event, value) => {
                                                formik.setFieldValue("gerencia", value.map(v => v.sigla));
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    label="Gerencias"
                                                    variant="outlined"
                                                    {...params}
                                                />
                                            )}
                                        />

                                    </Grid>
                                </Grid>
                            </div>
                        ) : (
                            <div>
                                <Grid container spacing={2}>
                                    EN CONSTRUCCIÓN
                                </Grid>
                            </div>)}

                </DialogContent>
                {formik.values.tipo_ === "actualizar" || formik.values.tipo_ == "agregar" || formik.values.tipo_ == "eliminar" || formik.values.tipo_ == "duplicar" ?
                    (
                        <DialogActions>
                            <Button onClick={handleCancelar} variant="outlined" color="primary">
                                Cancelar
                            </Button>
                            <Button onClick={formik.submitForm} variant="contained" color="primary">
                                Aceptar
                            </Button>
                        </DialogActions>
                    ) : (
                        <DialogActions>
                            <Button onClick={handleCancelar} variant="outlined" color="primary">
                                Cerrar
                            </Button>

                        </DialogActions>
                    )
                }
            </Dialog>

            <Dialog open={openAsignacion} fullWidth>
                <DialogAsignacion _id_={_id_} titulo="Macroproceso" setOpenAsignacion={setOpenAsignacion} mutate={mutateServicios} />
            </Dialog>


        </Fragment>
    );
}

export default TabPerfiles;