import React, { Fragment, Label, useEffect } from "react";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { DatePicker } from "@material-ui/pickers";
import { useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
import { object, string, array, date } from "yup";
import Moment from "moment";
import { Accordion, Tooltip, Typography, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, Paper, TextField, Toolbar, AppBar } from "@material-ui/core";
import { Check, Close, ExpandMore, FiberManualRecord, GetApp, InsertDriveFile, LocalOffer, Person, Stop, Visibility } from "@material-ui/icons";
import { ObtenerMacroprocesos } from "../../services/request/mapaProcesos/requestMacroproceso";
import { ObtenerServicios } from "../../services/request/mapaProcesos/requestServicio";
import { ObtenerFicha, ObtenerFichas } from "../../services/request/mapaProcesos/requestFicha";
import { ObtenerInvolucradosInternos } from "../../services/request/mapaProcesos/requestInvolucradoInterno";
import { ObtenerInvolucradosExternos } from "../../services/request/mapaProcesos/requestInvolucradoExterno";
import { ObtenerProcAsocs } from "../../services/request/mapaProcesos/requestProcedimientoAsociado";
import { ObtenerDocsAsociados } from "../../services/request/mapaProcesos/requestDocumentosAsociados";
import { ObtenerProveedores } from "../../services/request/mapaProcesos/requestProveedoresInformacion";
import { ObtenerEntradas } from "../../services/request/mapaProcesos/requestEntrada";
import { ObtenerKPIs } from "../../services/request/mapaProcesos/requestKPI";
import { ObtenerClientes } from "../../services/request/mapaProcesos/requestClientes";
import { ObtenerEntregables } from "../../services/request/mapaProcesos/requestEntregables";
import ProcesoAsociado from "./procesoAsociado";
import { ObtenerEtiquetas } from "../../services/request/mapaProcesos/requestEtiqueta";




function Ficha(props) {

  const {
    handleDialogClose,
    macroproceso,
    tipo_proceso,
    idFicha,
  } = props;

  const [openDialog, setOpenDialog] = React.useState(false);
  const notistack = useSnackbar();
  const [idProceso, setIdProceso] = React.useState(null);


  const { data: ficha, error: errorFicha, mutate: mutateFicha } = useSWR("ficha", (key) => ObtenerFicha(idFicha), { revalidateOnFocus: false });
  const { data: fichas, error: errorFichas, mutate: mutateFichas } = useSWR("fichas", (key) => ObtenerFichas(), { revalidateOnFocus: false });

  const { data: involucradosInternos, error: errorInvolucradosInternos, mutate: mutateInvolucradosInternos } = useSWR("involucrados_internos", (key) => ObtenerInvolucradosInternos(), { revalidateOnFocus: false });
  const { data: involucradosExternos, error: errorInvoludracosExternos, mutate: mutateInvolucradosExternos } = useSWR("involucrados_externos", (key) => ObtenerInvolucradosExternos(), { revalidateOnFocus: false });
  const { data: procedimientosAsociados, error: errorProcedimientosAsociados, mutate: mutateProcedimientosAsociados } = useSWR("procedimientos_asociados", (key) => ObtenerProcAsocs(), { revalidateOnFocus: false });
  const { data: documentosAsociados, error: errorDocumentosAsociados, mutate: mutateDocumentosAsociados } = useSWR("documentos_asociados", (key) => ObtenerDocsAsociados(), { revalidateOnFocus: false });
  const { data: proveedoresInformacion, error: errorProveedoresInformacion, mutate: mutateProveedoresInformacion } = useSWR("proveedores_informacion", (key) => ObtenerProveedores(), { revalidateOnFocus: false });
  const { data: entradas, error: errorEntradas, mutate: mutateEntradas } = useSWR("entradas", (key) => ObtenerEntradas(), { revalidateOnFocus: false });
  const { data: kpi, error: errorKpi, mutate: mutateKpi } = useSWR("kpi", (key) => ObtenerKPIs(), { revalidateOnFocus: false });
  const { data: clientes, error: errorClientes, mutate: mutateClientes } = useSWR("clientes", (key) => ObtenerClientes(), { revalidateOnFocus: false });
  const { data: entregables, error: errorEntregables, mutate: mutateEntregables } = useSWR("entregables", (key) => ObtenerEntregables(), { revalidateOnFocus: false });
  const { data: etiquetas, error: errorEtiquetas, mutate: mutateEtiquetas } = useSWR("etiquetas", (key) => ObtenerEtiquetas(), { revalidateOnFocus: false });



  // const { data: servicios, error: errorServicios, mutate: mutateServicios } = useSWR("servicios", (key) => ObtenerServicios(), { revalidateOnFocus: false });
  // const { data: fichas, error: errorFichas, mutate: mutateFichas } = useSWR("fichas", (key) => ObtenerFichas(), { revalidateOnFocus: false });

  useEffect(() => {

    if (errorFichas) {
      notistack.enqueueSnackbar("Error al intentar obtener las fichas.", {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
      });
    }

  }, [errorFichas]);



  const abrirPopUpFicha_ = (proceso) => {

    setIdProceso(proceso);
    handleDialogOpen_();
  }

  function handleDialogClose_() {
    setOpenDialog(false);
  }

  const handleDialogOpen_ = () => {
    setOpenDialog(true);
  };

  return (
    <Fragment  >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>

          <Grid container>
            <Grid xs={1} style={{ textAlign: "left" }}>
              <Box display="flex" style={{ alignItems: "center", justifyContent: "left", height: "100%", paddingLeft: "15px" }}>
                <Tooltip title={
                  <div>

                    {/* {fichas && Array.from(fichas.data).map((d, index) => ( */}
                    {/* <Fragment> */}
                    {etiquetas && Array.from(etiquetas.data).filter(f => ficha && ficha.etiquetas_ref && ficha.etiquetas_ref.includes(f._id)).length > 0 ?
                      <div>
                        Esta ficha posee las siguientes etiquetas:
                        {etiquetas && Array.from(etiquetas.data).filter(f => ficha && ficha.etiquetas_ref && ficha.etiquetas_ref.includes(f._id)).map((k, index) =>
                          <Box display="flex" style={{ alignItems: "center", justifyContent: "left" }}>
                            <Check style={{ fontSize: "15px" }} />&nbsp;{k.nombre}
                          </Box>
                        )}
                      </div>
                      :
                      <div>
                        No tiene etiquetas asignadas
                      </div>
                    }
                    {/* </Fragment> */}
                    {/* ))} */}
                  </div>
                }>
                  <LocalOffer fontSize="large" style={{ marginRight: "8px", color: "lightgray" }} />
                  {/* </Button> */}
                </Tooltip>
              </Box>
            </Grid>
            <Grid xs={9} style={{ textAlign: "center" }}>
              <Box style={{ display: "flex", height: "100%", width: "100%", alignItems: "center", justifyContent: "center" }}>
                <Typography variant="h5">
                  Ficha: <strong>{ficha && ficha.nombre}</strong>
                </Typography>
              </Box>
            </Grid>

            <Grid xs={2} style={{ textAlign: "right" }}>
              <Tooltip title="Documento del Proceso">
                {/* <Button
                  component="a"
                  href={ficha && ficha.fichaPDF && ficha.fichaPDF.url}
                  target="_blank"
                  style={{
                    color: "#731f1f",
                    textDecoration: "none",
                    textTransform: "none",
                    // width: "89%",
                    justifyContent: "right",
                  }}
                > */}

                <Button
                  component="a"
                  href={ficha && ficha.fichaPDF && ficha.fichaPDF.url}
                  target="_blank"
                  // disabled={true}
                  style={{
                    color: "#731f1f",
                    textDecoration: "none",
                    textTransform: "none",
                    // width: "89%",
                    justifyContent: "right",
                  }}
                >

                  {/* <InsertDriveFile fontSize="large" style={{ marginRight: "8px", color: "#731f1f" }} />
                   */}
                  {ficha && ficha.normativa_iso == "Sistemas de Gestión de Calidad" ?
                    <InsertDriveFile fontSize="large" style={{ marginRight: "8px", color: "#2196f3" }} />
                    :
                    ficha && ficha.normativa_iso == "Seguridad y Salud en el Trabajo" ?

                      <InsertDriveFile fontSize="large" style={{ marginRight: "8px", color: "#fdd835" }} />
                      :
                      ficha && ficha.normativa_iso == "Medio Ambiente" ?
                        <InsertDriveFile fontSize="large" style={{ marginRight: "8px", color: "#4caf50" }} />
                        :
                        // ficha.normativa_iso == "Liderazgo" ?
                        // <InsertDriveFile fontSize="large" style={{ marginRight: "8px",color: "#731f1f" }} />
                        //   :
                        //   ficha.normativa_iso == "Evaluación de Desempeño" ?Ff
                        //   <InsertDriveFile fontSize="large" style={{ marginRight: "8px",color: "gray" }} />
                        //     :
                        ficha && ficha.normativa_iso == "SGI" ?
                          <InsertDriveFile fontSize="large" style={{ marginRight: "8px", color: "#731f1f" }} />
                          :
                          <InsertDriveFile fontSize="large" style={{ marginRight: "8px", color: "lightgray" }} />
                  }
                  {/* <InsertDriveFile fontSize="large" style={{ marginRight: "8px", color: "gray" }} /> */}


                </Button>
              </Tooltip>
              {/* <Tooltip title="Cerrar Ficha">

                  <Button onClick={(event) => { handleDialogClose() }} variant="contained" color="primary" style={{ marginRight: "5px" }}>
                    <Close />
                  </Button>
                </Tooltip> */}
              <IconButton
                edge="end"
                // color="lightgray"
                onClick={handleDialogClose}
                aria-label="close"
                style={{color:"lightgray"}}
              >
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid container style={{ marginTop: "50px" }}>
        {/* TITULO */}
       
        {/* INFORMACIÓN */}
        <Grid xs={5} style={{ maxHeight: "210px" }}>
          <Paper variant="outlined" style={{ marginTop: "6px", marginRight: "3px", padding: "10px", borderRadius: "10px", height: "170px", maxHeight: "170px", overflowY: "scroll" }}>
            <Grid item xs={12} >
              <Grid container>
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={4} >

                  <Typography variant="h7" fontWeight="bold" color="#731f1f">
                    Macroproceso
                  </Typography>

                </Grid>
                <Grid item xs={7}><Box><strong>:&nbsp;{macroproceso}</strong></Box></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={1}>
                  <Box style={{
                    height: "100%",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: "0px"
                  }}>
                    {tipo_proceso == "Operativo" ?
                      <Stop fontSize="small" style={{ color: "#86b25f" }} />
                      :
                      tipo_proceso == "Soporte" ?

                        <Stop fontSize="small" style={{ color: "#fddf5d" }} />
                        :
                        tipo_proceso == "Estratégico" ?
                          <Stop fontSize="small" style={{ color: "#f28933" }} />
                          :
                          <Stop fontSize="small" style={{ color: "white" }} />
                    }
                  </Box>
                </Grid>
                <Grid item xs={4} >
                  <Typography variant="h7" fontWeight="bold" color="#731f1f">
                    Tipo
                  </Typography>
                </Grid>
                <Grid item xs={7}><Box>:&nbsp;{tipo_proceso}</Box></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={1}>
                  <Box style={{
                    height: "100%",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: "0px"
                  }}>
                    {ficha && ficha.normativa_iso == "Sistemas de Gestión de Calidad" ?
                      <Stop fontSize="small" style={{ color: "#2196f3" }} />
                      :
                      ficha && ficha.normativa_iso == "Seguridad y Salud en el Trabajo" ?

                        <Stop fontSize="small" style={{ color: "#fdd835" }} />
                        :
                        ficha && ficha.normativa_iso == "Medio Ambiente" ?
                          <Stop fontSize="small" style={{ color: "#4caf50" }} />
                          :
                          // ficha.normativa_iso == "Liderazgo" ?
                          //   <Stop fontSize="small" style={{ color: "#731f1f" }} />
                          //   :
                          //   ficha.normativa_iso == "Evaluación de Desempeño" ?
                          //     <Stop fontSize="small" style={{ color: "gray" }} />
                          //     :
                          ficha && ficha.normativa_iso == "SGI" ?
                            <Stop fontSize="small" style={{ color: "#731f1f" }} />
                            :
                            <Stop fontSize="small" style={{ color: "white" }} />
                    }
                  </Box>
                </Grid>
                <Grid item xs={4} >
                  <Typography variant="h7" fontWeight="bold" color="#731f1f">
                    Normativa ISO
                  </Typography>
                </Grid>
                <Grid item xs={7}><Box>:&nbsp;{ficha && ficha.normativa_iso}</Box></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container >
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={4} >
                  <Typography variant="h7" fontWeight="bold" color="#731f1f">
                    Código
                  </Typography></Grid>
                <Grid item xs={7}><Box>:&nbsp;{ficha && ficha.codigo}</Box></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={4} >
                  <Typography variant="h7" fontWeight="bold" color="#731f1f">
                    Versión
                  </Typography></Grid>
                <Grid item xs={7}><Box>:&nbsp;{ficha && ficha.version}</Box></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={4} ><Box style={{
                  height: "100%",
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                }}><Typography variant="h7" fontWeight="bold" color="#731f1f">
                    Responsable
                  </Typography> </Box></Grid>
                <Grid item xs={7}><Box>:&nbsp;{ficha && ficha.responsable}</Box></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={4} >
                  <Typography variant="h7" fontWeight="bold" color="#731f1f">
                    Última Actualización
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Box style={{
                    height: "100%",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'left',
                  }}>
                    :&nbsp;{Moment(ficha && ficha.fecha_actualizacion).format("DD/MM/YYYY HH:mm")}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* ALCANCE OBJETIVO */}
        <Grid xs={6} style={{ maxHeight: "210px" }}>
          <Paper variant="outlined" style={{ marginTop: "6px", marginLeft: "3px", marginRight: "3px", padding: "10px", borderRadius: "10px", height: "170px", maxHeight: "170px", overflowY: "scroll" }}>
            <Grid container>
              <Grid item xs={12} >
                <Box style={{ textAlign: "left", height: "100%" }}>
                  <Typography fontWeight="bold" color="#731f1f" style={{ marginLeft: "10px" }}>
                    <strong>Alcance</strong>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} style={{ padding: "5px" }}>
                <Typography variant="h7">
                  {ficha && ficha.alcance}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <Box style={{ textAlign: "left", height: "100%" }}>
                  <Typography fontWeight="bold" color="#731f1f" style={{ marginLeft: "10px" }}>
                    <strong>Objetivo</strong>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} style={{ padding: "5px" }}>
                {ficha && ficha.objetivo}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* FICHA */}
        <Grid item xs={1} style={{ maxHeight: "210px" }}>
          {ficha && ficha.normativa_iso == "Sistemas de Gestión de Calidad" ?
            <Paper variant="outlined" style={{ marginTop: "6px", marginLeft: "3px", padding: "10px", borderRadius: "10px", height: "170px", maxHeight: "170px", overflowY: "scroll", backgroundColor: "#2196f3" }}>
              <Grid container style={{ height: "100%" }}>
                <Grid item xs={12}>
                  <Box display="flex" style={{ alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                    <strong>Flujo</strong>
                  </Box>
                </Grid>
                {/* <Grid item xs={12}>
                &nbsp;
              </Grid> */}
                <Grid item xs={12}>
                  <Box display="flex" style={{ alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                    <Button variant="contained"
                      component="a"
                      // disabled={!(ficha && ficha.flujograma && ficha.flujograma.url)}
                      href={ficha && ficha.flujograma && ficha.flujograma.url}
                      target="_blank"
                      style={{ width: "60%", backgroundColor: "lightgray" }}>
                      <img src="https://cdn-icons-png.flaticon.com/512/2145/2145691.png" style={{ maxWidth: "100%", maxHeight: "100%" }} ></img>
                    </Button>
                  </Box>
                </Grid>
              </Grid>


            </Paper>
            // <InsertDriveFile fontSize="large" style={{ marginRight: "8px", color: "#2196f3" }} />
            :
            ficha && ficha.normativa_iso == "Seguridad y Salud en el Trabajo" ?
              <Paper variant="outlined" style={{ marginTop: "6px", marginLeft: "3px", padding: "10px", borderRadius: "10px", height: "170px", maxHeight: "170px", overflowY: "scroll", backgroundColor: "#fdd835" }}>
                <Grid container style={{ height: "100%" }}>
                  <Grid item xs={12}>
                    <Box display="flex" style={{ alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                      <strong>Flujo</strong>
                    </Box>
                  </Grid>
                  {/* <Grid item xs={12}>
                &nbsp;
              </Grid> */}
                  <Grid item xs={12}>
                    <Box display="flex" style={{ alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                      <Button variant="contained"
                        component="a"
                        href={ficha && ficha.flujograma && ficha.flujograma.url}
                        target="_blank"
                        style={{ width: "60%", backgroundColor: "lightgray" }}>
                        <img src="https://cdn-icons-png.flaticon.com/512/2145/2145691.png" style={{ maxWidth: "100%", maxHeight: "100%" }} ></img>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>

              </Paper>
              // <InsertDriveFile fontSize="large" style={{ marginRight: "8px", color: "#fdd835" }} />
              :
              ficha && ficha.normativa_iso == "Medio Ambiente" ?
                <Paper variant="outlined" style={{ marginTop: "6px", marginLeft: "3px", padding: "10px", borderRadius: "10px", height: "170px", maxHeight: "170px", overflowY: "scroll", backgroundColor: "#4caf50" }}>
                  <Grid container style={{ height: "100%" }}>
                    <Grid item xs={12}>
                      <Box display="flex" style={{ alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                        <strong>Flujo</strong>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={12}>
             &nbsp;
           </Grid> */}
                    <Grid item xs={12}>
                      <Box display="flex"
                        component="a"
                        href={ficha && ficha.flujograma && ficha.flujograma.url}
                        target="_blank"
                        style={{ alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                        <Button variant="contained" style={{ width: "60%", backgroundColor: "lightgray" }}>
                          <img src="https://cdn-icons-png.flaticon.com/512/2145/2145691.png" style={{ maxWidth: "100%", maxHeight: "100%" }} ></img>
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>


                </Paper>
                // <InsertDriveFile fontSize="large" style={{ marginRight: "8px", color: "#4caf50" }} />
                :
                // ficha.normativa_iso == "Liderazgo" ?
                // <InsertDriveFile fontSize="large" style={{ marginRight: "8px",color: "#731f1f" }} />
                //   :
                //   ficha.normativa_iso == "Evaluación de Desempeño" ?
                //   <InsertDriveFile fontSize="large" style={{ marginRight: "8px",color: "gray" }} />
                //     :
                ficha && ficha.normativa_iso == "SGI" ?
                  <Paper variant="outlined" style={{ marginTop: "6px", marginLeft: "3px", padding: "10px", borderRadius: "10px", height: "170px", maxHeight: "170px", overflowY: "scroll", backgroundColor: "#731f1f" }}>
                    <Grid container style={{ height: "100%" }}>
                      <Grid item xs={12}>
                        <Box display="flex" style={{ alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                          <Typography style={{ color: "white" }}>
                            <strong>Flujo</strong>
                          </Typography>
                        </Box>
                      </Grid>
                      {/* <Grid item xs={12}>
                   &nbsp;
                 </Grid> */}
                      <Grid item xs={12}>
                        <Box display="flex" style={{ alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                          <Button
                            component="a"
                            href={ficha && ficha.flujograma && ficha.flujograma.url}
                            target="_blank"
                            variant="contained" style={{ width: "60%", backgroundColor: "lightgray" }}>
                            <img src="https://cdn-icons-png.flaticon.com/512/2145/2145691.png" style={{ maxWidth: "100%", maxHeight: "100%" }} ></img>
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>


                  </Paper>
                  // <InsertDriveFile fontSize="large" style={{ marginRight: "8px", color: "#757575" }} />
                  :
                  <Paper variant="outlined" style={{ marginTop: "6px", marginLeft: "3px", padding: "10px", borderRadius: "10px", height: "170px", maxHeight: "170px", overflowY: "scroll", backgroundColor: "white" }}>
                    <Grid container style={{ height: "100%" }}>
                      <Grid item xs={12}>
                        <Box display="flex" style={{ alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                          <strong>Flujo</strong>
                        </Box>
                      </Grid>
                      {/* <Grid item xs={12}>
                 &nbsp;
               </Grid> */}
                      <Grid item xs={12}>
                        <Box display="flex" style={{ alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
                          <Button
                            component="a"
                            href={ficha && ficha.flujograma && ficha.flujograma.url}
                            target="_blank"
                            variant="contained" style={{ width: "60%", backgroundColor: "lightgray" }}>
                            <img src="https://cdn-icons-png.flaticon.com/512/2145/2145691.png" style={{ maxWidth: "100%", maxHeight: "100%" }} ></img>
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>


                  </Paper>
            // <InsertDriveFile fontSize="large" style={{ marginRight: "8px", color: "black" }} />
          }

        </Grid>
        {/* INVOLUCRADOS */}
        <Grid xs={5}>
          <Paper variant="outlined" style={{ marginTop: "6px", marginRight: "3px", padding: "10px", borderRadius: "10px" }}>
            <Grid container>
              <Grid item xs={12} style={{ height: "19px" }}>
                <Box style={{ textAlign: "center" }}>
                  <Typography fontWeight="bold" color="#731f1f">
                    <strong>Involucrados</strong>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6} style={{ height: "19px" }}>
                    <Box style={{ textAlign: "center" }}>
                      <strong>Internos </strong>
                    </Box>
                  </Grid>
                  <Grid item xs={6} >
                    <Box style={{ textAlign: "center" }}>
                      <strong>Externos </strong>
                    </Box>
                  </Grid>

                  <Grid item xs={6} style={{ height: "231px", overflowY: "scroll" }}>
                    {involucradosInternos && ficha && Array.from(involucradosInternos.data).filter(p => ficha._involucrado_asociado_interno_ref.includes(p._id)).map((d, index) => (

                      <div>
                        {index % 2 == 0 ?
                          <Box style={{
                            // height: "100%",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'left',
                            width: "100%",

                          }}>
                            <Person fontSize="small" style={{ marginRight: "8px", color: "#731f1f" }} />
                            {d.cargo}
                          </Box>
                          :
                          <Box style={{
                            // height: "100%",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'left',
                            width: "100%",
                            backgroundColor: "#f5f5f5",
                          }}>
                            <Person fontSize="small" style={{ marginRight: "8px", color: "#731f1f" }} />
                            {d.cargo}
                          </Box>
                        }
                      </div>
                    ))}
                  </Grid>
                  <Grid item xs={6} style={{ height: "230px", overflowY: "scroll" }}>
                    {involucradosExternos && ficha && Array.from(involucradosExternos.data).filter(p => ficha._involucrado_asociado_externo_ref.includes(p._id)).map((d, index) => (
                      <Box style={{
                        // height: "100%",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                      }}>
                        <Person fontSize="small" style={{ marginRight: "8px", color: "#731f1f" }} />
                        {d.cargo}
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              </Grid>


              {/* <Paper variant="outlined" style={{ width: "100%", margin: "5px" }}>
              <Grid item xs={12} >
                <Box style={{ textAlign: "center" }}>
                  <Typography fontWeight="bold" color="#731f1f">
                    Procedimientos Asociados
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {procedimientosAsociados && ficha && Array.from(procedimientosAsociados.data).filter(p => ficha._procedimientosAsociados_ref.includes(p._id)).map((d, index) => (
                  <Typography display="flex" alignItems="center" onClick={abrirPopUpFicha_.bind(null, d._id)} style={{ cursor: "pointer" }}>
                    <FiberManualRecord fontSize="small" style={{ marginRight: "8px" }} /> <strong>{d.codigo}</strong> - {d.nombre}
                  </Typography>

                ))}
              </Grid>
            </Paper> */}




            </Grid>
          </Paper>
        </Grid>
        {/* PROVEEDORES INFORMACIÓN/CLIENTES ENTREGABLES */}
        <Grid item xs={7}>
          <Paper variant="outlined" style={{ marginTop: "6px", marginLeft: "3px", padding: "10px", borderRadius: "10px" }}>
            {/* PROVEEDORES DE INFORMACIÓN Y ENTRADAS */}
            <Grid container >
              <Grid item xs={4} style={{ height: "19px" }}>
                <Box style={{ textAlign: "center", height: "100%" }}>
                  <Typography fontWeight="bold" color="#731f1f">
                    <strong>Proveedores de Información:</strong>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box style={{ textAlign: "center", height: "100%" }}>
                  <Typography fontWeight="bold" color="#731f1f">
                    <strong>Entradas:</strong>
                  </Typography>
                </Box>
              </Grid>
              <Grid container>
                <Grid xs={12} style={{ height: "113px", overflowY: "scroll" }}>
                  {proveedoresInformacion && ficha && Array.from(proveedoresInformacion.data).filter(p => ficha._proveedoresInformacion_ref.includes(p._id)).map((d, index) => (
                    <Grid container >

                      <Grid item xs={4}>
                        {/* <Tooltip title={d.proveedor}> */}
                        <Box style={{ textAlign: "center", height: "100%" }}>
                          <Tooltip title={d.proveedor}>
                            <div>{d.codigo}</div>
                          </Tooltip>
                        </Box>

                      </Grid>
                      <Grid item xs={8}>
                        <Box style={{ textAlign: "left", height: "100%" }}>
                          {entradas && ficha && Array.from(entradas.data).filter(q => d._entradas_ref.includes(q._id)).map((q, index_) => (
                            <Button
                              // component={Link}
                              href={q.entrada.url}
                              target="_blank"
                              style={{
                                color: "black",
                                textDecoration: "none",
                                textTransform: "none",
                                width: "100%",
                                textAlign: "left",
                                justifyContent: "left"

                              }}
                            >
                              <strong>{index_ + 1}.</strong>&nbsp;{q.entrada.nombre}
                            </Button>
                          ))}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider style={{ backgroundColor: "#731f1f" }} />
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            {/* CLIENTES Y ENTREGABLES */}
            <Grid container>
              <Grid item xs={4} style={{ height: "19px" }}>
                <Box style={{ textAlign: "center", height: "100%" }}>
                  <Typography fontWeight="bold" color="#731f1f">
                    <strong>Clientes:</strong>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box style={{ textAlign: "center", height: "100%" }}>
                  <Typography fontWeight="bold" color="#731f1f">
                    <strong>Entregables:</strong>
                  </Typography>
                </Box>
              </Grid>
              <Grid container>
                <Grid xs={12} style={{ height: "113px", overflowY: "scroll" }}>
                  {clientes && ficha && Array.from(clientes.data).filter(p => ficha._entregableCliente_ref.includes(p._id)).map((d, index) => (

                    <Grid container>

                      <Grid item xs={4}>
                        <Box style={{ textAlign: "center", height: "100%" }}>
                          <Tooltip title={d.cliente}>
                            <Box>
                              <Person fontSize="small" style={{ marginRight: "8px" }} />
                              {d.codigo}
                            </Box>
                          </Tooltip>
                        </Box>
                      </Grid>
                      <Grid item xs={8}>
                        <Box style={{ textAlign: "center", height: "100%" }}>
                          {entregables && ficha && Array.from(entregables.data).filter(q => d._entregable_ref.includes(q._id)).map((q, index__) => (
                            <Box style={{ textAlign: "left" }}>
                              {/* <InsertDriveFile fontSize="small" style={{ marginRight: "8px" }} /> */}
                              <Button
                                // component={Link}
                                href={q.documento.url}
                                target="_blank"
                                style={{
                                  color: "black",
                                  textDecoration: "none",
                                  textTransform: "none",
                                  width: "100%",
                                  textAlign: "left",
                                  justifyContent: "left"
                                }}
                              >
                                <strong>{index__ + 1}.</strong>&nbsp;{q.documento.nombre}
                              </Button>
                            </Box>

                          ))}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider style={{ backgroundColor: "#731f1f" }} />
                      </Grid>
                    </Grid>

                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* PROCESOS ASOCIADOS */}
        <Grid item xs={5} style={{ marginTop: "6px" }}>
          <Paper variant="outlined" style={{ marginRight: "3px", padding: "10px", borderRadius: "10px", height: "270px" }}>
            <Grid item xs={12}>
              <Box style={{ textAlign: "center" }}>
                <Typography fontWeight="bold" color="#731f1f">
                  <strong>Procesos Asociados</strong>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} style={{ maxHeight: "250px", overflowY: "scroll" }}>
              {fichas && ficha && Array.from(fichas.data).filter(p => ficha._procesosAsociados_ref.includes(p._id)).map((d, index) => (
                <div>
                  {index % 2 == 0 ?
                    <Typography display="flex" alignItems="center">
                      {/* <FiberManualRecord fontSize="small" style={{ marginRight: "8px" }} /> <strong>{d.codigo}</strong> - {d.nombre} */}
                      <strong>{index + 1}. &nbsp;</strong> <strong>{d.codigo}</strong> - {d.nombre}

                    </Typography>
                    :
                    <Box style={{ width: "100%", backgroundColor: "#f5f5f5" }}>
                      <Typography display="flex" alignItems="center">
                        {/* <FiberManualRecord fontSize="small" style={{ marginRight: "8px" }} /> <strong>{d.codigo}</strong> - {d.nombre} */}
                        <strong>{index + 1}. &nbsp;</strong> <strong>{d.codigo}</strong> - {d.nombre}

                      </Typography>
                    </Box>
                  }
                </div>

              ))}
            </Grid>

          </Paper>
        </Grid>
        {/* DOCUMENTOS ASOCIADOS */}
        <Grid item xs={4} style={{ marginTop: "6px" }}>
          <Paper variant="outlined" style={{ marginRight: "3px", padding: "10px", marginLeft: "3px", borderRadius: "10px", height: "270px" }}>
            <Grid item xs={12} >
              <Box style={{ textAlign: "center" }}>
                <Typography fontWeight="bold" color="#731f1f">
                  <strong>Documentos Asociados</strong>
                </Typography>

              </Box>
            </Grid>
            <Grid item xs={12} style={{ maxHeight: "250px", overflowY: "scroll" }}>
              {documentosAsociados && ficha && Array.from(documentosAsociados.data).filter(p => ficha._documentosAsociados_ref.includes(p._id)).map((d, index) => (

                <Box style={{
                  height: "100%",
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                }}>
                  <strong>{index + 1}. &nbsp;</strong>
                  <Button
                    // component={Link}
                    href={d.documento.url}
                    target="_blank"
                    style={{
                      color: "black",
                      textDecoration: "none",
                      textTransform: "none",
                    }}
                  >
                    {d.documento.nombre}
                  </Button>
                </Box>
              ))}
            </Grid>
          </Paper>
        </Grid>
        {/* KPI */}
        <Grid item xs={3} style={{ marginTop: "6px" }}>
          <Paper variant="outlined" style={{ marginLeft: "3px", borderRadius: "10px", padding: "10px", height: "270px" }}>
            <Grid container>
              <Grid item xs={12} >
                <Box style={{ textAlign: "center" }}>
                  <Typography fontWeight="bold" color="#731f1f">
                    <strong>KPI</strong>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} >
                <Box style={{ textAlign: "left", marginLeft: "10px" }}>
                  <strong>Objetivo:</strong>
                </Box>
              </Grid>
              <Grid item xs={12} style={{ maxHeight: "80px", overflowY: "scroll" }} >
                <Box style={{ textAlign: "left", paddingTop: "1px", marginLeft: "5px" }}>
                  {ficha && ficha.objetivo_kpi}
                </Box>
              </Grid>
              <Grid container style={{ maxHeight: "150px", overflowY: "scroll" }}>
                {kpi && ficha && Array.from(kpi.data).filter(p => ficha._kpi_ref.includes(p._id)).map((d, index) => (
                  <Grid xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Divider style={{ backgroundColor: "#731f1f" }} />
                      </Grid>
                      <Grid item xs={4}>
                        <strong>Indicador:</strong>
                      </Grid>
                      <Grid item xs={8} style={{ paddingLeft: "5px" }}>
                        {d.indicador}
                      </Grid>
                      <Grid item xs={4}>
                        <strong>Criterio de aceptación:</strong>
                      </Grid>
                      <Grid item xs={8} style={{ paddingLeft: "5px" }}>
                        <Box style={{
                          height: "100%",
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'left',
                        }}>
                          {d.criterio}
                        </Box>
                      </Grid>

                      <Grid item xs={4}>
                        <strong>Frecuencia:</strong>
                      </Grid>
                      <Grid item xs={8} style={{ paddingLeft: "5px" }}>
                        {d.frecuencia}
                      </Grid>

                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>

          </Paper>
        </Grid>
      </Grid>

      <Dialog open={openDialog} maxWidth="md" fullWidth>
        {/* <DialogTitle>
          asdasdasdasdasd
        </DialogTitle> */}
        <DialogContent dividers>

          {/* <Ficha macroproceso={macroproceso_name} tipo_proceso={tipo_proceso} idFicha={idFicha}/> */}
          <ProcesoAsociado tipo_proceso={tipo_proceso} idProceso={idProceso} />

        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleDialogClose_} variant="outlined" color="primary">
            Cerrar
          </Button>
        </DialogActions> */}
      </Dialog>

    </Fragment >
  );
}

export default Ficha;