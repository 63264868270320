import { BACKEND } from "../../../constants/urls";
import Axios from "axios";

/**
 * Método encargado de obtener un perfil, por su ID.
 * @param {*} perfilID ID del perfil.
 */
export async function ObtenerFicha(procAsocID) {
  try {
    let url = `${BACKEND}/ficha/${procAsocID}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todos los perfiles.
 */
export async function ObtenerFichas() {
  try {
    let url = `${BACKEND}/ficha`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de agregar un nuevo perfil.
 * @param {*} perfil Datos del perfil.
 */
export async function AgregarFicha(procAsoc) {
  try {
    let url = `${BACKEND}/ficha`;
    console.log(procAsoc);
    let response = await Axios.post(url, procAsoc);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de actualizar un perfil.
 * @param {*} perfil Datos actualizados del perfil.
 */
export async function ActualizarFicha(procAsoc) {
  try {
    let url = `${BACKEND}/ficha/${procAsoc._id}`;
    let response = await Axios.put(url, procAsoc);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de eliminar un perfil.
 * @param {*} perfil Datos del perfil.
 */
export async function EliminarFicha(procAsoc) {
  try {
    let url = `${BACKEND}/ficha/${procAsoc._id}`;
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}