export const VER_AJUSTES = "verAjustes";
export const VER_PERMISOS = "verPermisos";

export const VER_AJUSTES_NOTICIA = "verAjustesNoticias";
export const AGREGAR_NOTICIA = "agregarNoticia";
export const EDITAR_NOTICIA = "editarNoticia";
export const VER_ACCESOS_GERENTES = "verAccesosGerentes";
export const VER_ACCESOS_RECLUTADORES = "verAccesosReclutadores";
export const AJUSTES_VER = "verAjustes"

//Permisos en Sección GERENTES
// GAF
export const VER_GAF_GGN = "verGAFGGN"
export const VER_GAF_GEI = "verGAFGEI"
export const VER_GAF_GMI = "verGAFGMI"
export const VER_GAF_GIN = "verGAFGIN"
export const VER_GAF_GZS = "verGAFGZS"
export const VER_GAF_GOP = "verGAFGOP"
export const VER_GAF_GPT = "verGAFGPT"
export const VER_GAF_PSI = "verGAFPSI"
