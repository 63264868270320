import React, { useContext, useEffect, useState } from "react";
import { AppBar, Box, makeStyles, Tab, Tabs } from "@material-ui/core";
// import TabUsuariosPlataforma from "./tabUsuariosPlataforma";
// import TabRoles from "./tabRoles";
// import TabPerfiles from "./tabPerfiles";
import TabProcedimientosAsociados from "./tabProcedimientosAsociados";
import TabDocumentos from "./tabDocumentos";
import TabFicha from "./tabFichas";
import TabMacroproceso from "./macroproceso/tabMacroproceso";
import TabServicios from "./servicio/tabServicio";

import { MainContext } from "../../../App";


function Page(props) {
  const [TabIndex, SetTabIndex] = useState(0);
  const { usuarioSesion, permisos, setFiltroIso, setMostrarBuscar } = useContext(MainContext);

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    SetTabIndex(newValue);
  };

  // useEffect(() => {

  //   // <ListItem
  //   //               button
  //   //               component={Link}
  //   //               to={Routes.BENEFICIOS}
  //   //               selected={itemSelected === 4}
  //   //               onClick={() => {
  //   //                 setItemSelected(4);
  //   //                 analytics.logEvent("Menú_Lateral_Beneficios", {
  //   //                   usuarioCorreo: claimUser.email,
  //   //                   usuarioNombre: claimUser.name,
  //   //                   usuarioContrato: claimUser.proyecto,
  //   //                 });
  //   //               }}
  //   //             ></ListItem>
  //   setMostrarBuscar(true);



  // }, []);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={TabIndex} onChange={handleChange}>
          <Tab label="Contratos" />
          <Tab label="Macroprocesos" />
          <Tab label="Procesos" />
          {/* <Tab label="Procedimientos Asociados" /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={TabIndex} index={0}>
        <TabServicios />
      </TabPanel>
      <TabPanel value={TabIndex} index={1}>
        <TabMacroproceso />
      </TabPanel>
      <TabPanel value={TabIndex} index={2}>
        <TabFicha />
      </TabPanel>
      {/* <TabPanel value={TabIndex} index={3}>
        <TabProcedimientosAsociados />
      </TabPanel> */}
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,

  },
}));

export default Page;