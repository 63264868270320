import React, { Fragment, useContext, useEffect } from "react";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import { object, string, array } from "yup";
import { Button,Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Close } from "@material-ui/icons";
import MaterialTable from "../../../components/materialTable";
import { ObtenerUsuariosPlataforma, AgregarUsuarioPlataforma, ActualizarUsuarioPlataforma, EliminarUsuarioPlataforma } from "./requestUsuariosPlataforma";
import { ObtenerPerfiles } from "./requestPerfiles";
import { ObtenerUsuarios } from "./requestUsuarios";
import { ObtenerGerencias } from "./requestGerencias";
import { MainContext } from "../../../App";

function TabUsuariosPlataforma(props) {
	const { data: usuariosPlataforma, error: errorUsuariosPlataforma, mutate: mutateUsuariosPlataforma } = useSWR("usuariosPlataforma", (key) => ObtenerUsuariosPlataforma(), { revalidateOnFocus: false });
	const { data: usuarios, error: errorUsuarios, mutate: mutateUsuarios } = useSWR("usuarios", (key) => ObtenerUsuarios(), { revalidateOnFocus: false });
	const { data: perfiles, error: errorPerfiles, mutate: mutatePerfiles } = useSWR("perfiles", (key) => ObtenerPerfiles(), { revalidateOnFocus: false });
	const { data: gerencias, error: errorGerencias, mutate: mutateGerencias } = useSWR("gerencias", (key) => ObtenerGerencias(), { revalidateOnFocus: false });

	const { usuarioSesion } = useContext(MainContext);

	const notistack = useSnackbar();

	useEffect(() => {
		if (errorUsuariosPlataforma) {
			notistack.enqueueSnackbar("Error al intentar obtener los usuarios de plataforma.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><Close /></IconButton>
			});
		}
		if (errorUsuarios) {
			notistack.enqueueSnackbar("Error al intentar obtener los usuarios.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><Close /></IconButton>
			});
		}
		if (errorPerfiles) {
			notistack.enqueueSnackbar("Error al intentar obtener los perfiles.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><Close /></IconButton>
			});
		}
		if (errorGerencias) {
			notistack.enqueueSnackbar("Error al intentar obtener las gerencias.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><Close /></IconButton>
			});
		}
	}, [errorUsuariosPlataforma, errorUsuarios, errorPerfiles, errorGerencias]);

	const formik = useFormik({
		initialValues: {
			nombre: "",
			rut: "",
			uid: "",
			gerencia_sigla: [],
			_perfiles_ref: [],
			_preferencias_ref: [],
		},
		validationSchema: object().shape({
			nombre: string()
				.min(3, "El nombre debe tener al menos ${min} caracteres.")
				.max(50, "El nombre debe tener a los más ${max} caracteres.")
				.required("El nombre es requerido."),
			rut: string()
				.min(7, "El RUT debe tener al menos ${min} caracteres.")
				.max(10, "El RUT debe tener a los más ${max} caracteres.")
				.required("El RUT es requerido."),
			uid: string()
				.min(7, "El UID debe tener al menos ${min} caracteres.")
				.required("El UID es requerido."),
			gerencia_sigla: array()
				.of(string()),

			// .min(3, "La gerencia debe tener al menos ${min} caracteres.")
			// .max(5, "La gerencia debe tener a los más ${max} caracteres.")
			// .required("La gerencia es requerida."),
			_perfiles_ref: array()
				.of(string())
				.min(1, "Se debe incluir al menos ${min} perfil.")
				.required("El perfil es requerido."),
			_preferencias_ref: array()
				.of(string())
				.optional(),
		}),
		onSubmit: (values, helper) => handleAceptar(values),
		enableReinitialize: true,
	});

	/**
	 * Handler para agregar un nuevo rol.
	 */
	const handleAgregar = () => {
		formik.setFieldValue("tipo", "agregar");
	}

	/**
	 * Handler para actualizar un rol.
	 * @param {*} event Evento.
	 * @param {*} row Datos.
	 */
	const handleActualizar = (event, row) => {
		formik.setValues(row);
		formik.setFieldValue("tipo", "actualizar");
	}

	/**
	 * Handler para eliminar un rol.
	 * @param {*} event Evento.
	 * @param {*} row Datos.
	 */
	const handleEliminar = (event, row) => {
		formik.setValues(row);
		formik.setFieldValue("tipo", "eliminar");
	}

	/**
	 * Handler para aceptar la acción.
	 * @param {*} values 
	 */
	const handleAceptar = async (values) => {
		try {
			switch (values.tipo) {
				case "agregar":
					await AgregarUsuarioPlataforma(values);
					break;
				case "actualizar":
					await ActualizarUsuarioPlataforma(values);
					break;
				case "eliminar":
					await EliminarUsuarioPlataforma(values);
					break;
				default:
					throw new Error("Acción no especificada.")
			}
			notistack.enqueueSnackbar("Acción realizada exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} catch (error) {
			console.error(error);
			notistack.enqueueSnackbar("Error al intentar realizar la acción.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} finally {
			handleCancelar();
		}
	}

	const handleCancelar = () => {
		mutateUsuariosPlataforma();
		mutateUsuarios();
		formik.resetForm();
	}

	let columns = [
		{
			title: "Nombre",
			field: "nombre",
			defaultSort: 'asc'
		}, {
			title: "RUT",
			field: "rut",
		}, {
			title: "Gerencia Autorizada",
			field: "gerencia_sigla",
			align: "center",
			render: rowData => (
                <div>
                    {rowData.gerencia_sigla && rowData.gerencia_sigla.map(type => (
                        <Fragment>
                            {/* {gerencias ? Array.from(gerencias).filter(p => type.includes(p._id)).map((d, index) => ( */}
                                <Chip key={type}
                                    size="small"
                                    variant="default"
                                    color="primary"
                                    style={{ margin: 2 }}
                                    label={type} />
                            {/* )) : <div></div>} */}
                        </Fragment>
                    ))}
                </div>
            ),
		}
	];

	let actions = [
		{
			tooltip: "Agregar",
			icon: "add",
			onClick: () => handleAgregar(),
			isFreeAction: true,
		}, {
			tooltip: "Editar",
			icon: "edit",
			onClick: handleActualizar,
		}, {
			tooltip: "Eliminar",
			icon: "delete",
			onClick: handleEliminar,
		}
	];

	return (
		<Fragment>
			<MaterialTable
				title="Usuario de Plataforma"
				is_loading={!usuariosPlataforma}
				data={usuariosPlataforma && usuariosPlataforma.data}
				columns={columns}
				actions={actions}
			/>
			<Dialog open={Boolean(formik.values.tipo)} maxWidth="md" fullWidth>
				<DialogTitle>
					{formik.values.tipo === "agregar" && "Agregar Usuario de Plataforma"}
					{formik.values.tipo === "actualizar" && "Actualizar Usuario de Plataforma"}
					{formik.values.tipo === "eliminar" && "Eliminar Usuario de Plataforma"}
				</DialogTitle>
				<DialogContent dividers>
					<DialogContentText>Formulario con información del Usuario de Plataforma.</DialogContentText>
					<Grid container spacing={2}>
						{/* USUARIO */}
						<Grid item xs={6}>
							<Autocomplete
								options={usuarios || []}
								value={usuarios ? Array.from(usuarios).find(u => u.usuario_id === formik.values.uid) : null}
								getOptionLabel={(usuario) => `${usuario.nombre_completo} (${usuario.contacto.email})`}
								disabled={formik.values.tipo !== "agregar"}
								onChange={(event, value) => {
									if (value) {
										formik.setFieldValue("nombre", value.nombre_completo);
										formik.setFieldValue("rut", value.run);
										formik.setFieldValue("uid", value.usuario_id);
										formik.setFieldValue("gerencia_sigla", [value.gerencia_ref.sigla]);
									} else {
										formik.setFieldValue("nombre", "");
										formik.setFieldValue("rut", "");
										formik.setFieldValue("uid", "");
										formik.setFieldValue("gerencia_sigla", "");
									}
								}}
								noOptionsText={"Sin personas"}
								renderInput={(params) => (
									<TextField
										name="nombre"
										label="Usuario"
										variant="outlined"
										fullWidth
										error={formik.touched.nombre && Boolean(formik.errors.nombre)}
										helperText={formik.touched.nombre ? formik.errors.nombre : ""}
										{...params}
									/>
								)}
							/>
						</Grid>
						{/* GERENCIA */}
						<Grid item xs={6}>
							<Autocomplete
								multiple
								options={gerencias || []}
								// value={gerencias ? Array.from(gerencias).filter(g => g.sigla === formik.values.gerencia_sigla) : null}
								value={gerencias ? Array.from(gerencias).filter(g => formik.values.gerencia_sigla.includes(g.sigla)) : null}


								// value={gerencias ? Array.from(gerencias).filter(
								// function (currentElement) {
								// 	// the current value is an object, so you can check on its properties
								// 	return currentElement.country === "America" && currentElement.age < 25;
								//   }) : []}

								getOptionLabel={(g) => g.sigla}
								disabled={formik.values.tipo === "eliminar"}
								onChange={(event, value) => {
									if (value) {
										formik.setFieldValue("gerencia_sigla", value.map(v => v.sigla));
									} else {
										formik.setFieldValue("gerencia_sigla", []);
									}
								}}
								noOptionsText={"Sin gerencias"}
								renderInput={(params) => (
									<TextField
										name="gerencia_sigla"
										label="Gerencias Habilitadas"
										variant="outlined"
										fullWidth
										error={formik.touched.gerencia_sigla && Boolean(formik.errors.gerencia_sigla)}
										helperText={formik.touched.gerencia_sigla ? formik.errors.gerencia_sigla : ""}
										{...params}
									/>
								)}
							/>
						</Grid>
						{/* PERFILES */}
						<Grid item xs={12}>
							<Autocomplete
								multiple
								options={perfiles ? perfiles.data : []}
								value={perfiles ? Array.from(perfiles.data).filter(p => formik.values._perfiles_ref.includes(p._id)) : []}
								getOptionLabel={(perfil) => perfil.nombre}
								disabled={formik.values.tipo === "eliminar"}
								onChange={(event, value) => {
									if (value) {
										formik.setFieldValue("_perfiles_ref", value.map(v => v._id));
									} else {
										formik.setFieldValue("_perfiles_ref", []);
									}
								}}
								renderInput={(params) => (
									<TextField
										label="Perfiles"
										variant="outlined"
										fullWidth
										error={formik.touched._perfiles_ref && Boolean(formik.errors._perfiles_ref)}
										helperText={formik.touched._perfiles_ref ? formik.errors._perfiles_ref : ""}
										{...params}
									/>
								)}
							/>
						</Grid>

					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCancelar} variant="outlined" color="primary">
						Cancelar
					</Button>
					<Button onClick={formik.submitForm} variant="contained" color="primary">
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
}

export default TabUsuariosPlataforma;