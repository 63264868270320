import React, {
  Fragment,
  useState,
  useContext,
  createContext,
} from "react";
import clsx from "clsx";
import { isBrowser, isMobile } from "react-device-detect";
import { Link, useLocation } from "react-router-dom";
import useSWR from "swr";
import InputAdornment from '@material-ui/core/InputAdornment';


import {
  AppBar as MaterialAppBar,
  Avatar,
  Box,
  Drawer,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  TextField,
  Divider,
  Button,
  useTheme,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  CardTravelTwoTone as CardTravelTwoToneIcon,
  Tune as TuneIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Settings as SettingsIcon,
  AccountTree,
  Search,
  Home,
  PostAdd,
  HowToReg,
} from "@material-ui/icons";
import { makeStyles, withStyles } from "@material-ui/styles";
import * as Routes from "../../constants/routes";
import * as Permissions from "../../constants/permissions";

import { cerrarSesion } from "../../services/auth";
import { MainContext } from "../../App";
import "./style.css";
import "./../../App.css";
import { ObtenerFichas, AgregarFicha, ActualizarFicha, EliminarFicha } from "../../services/request/mapaProcesos/requestFicha";


import { auth } from "../../services/firebase";
import { Autocomplete } from "@material-ui/lab";

const logo_cydocs_color = "https://storage.googleapis.com/bpm-cyd/site-files/Logo%20SGI%20CyD.png";

export const DrawerContext = createContext({
  setOpenMainDrawer: () => { },
  openMainDrawer: true,
});

export default function AppBar(props) {

  const {
    handleSearchServicio,
    handleBuscarBox,
    text,

  } = props;

  const { data: fichas, error: errorFichas, mutate: mutateFichas } = useSWR("fichas", (key) => ObtenerFichas(), { revalidateOnFocus: false });


  const { usuarioSesion, permisos, mostrarBuscar } = useContext(MainContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const menuId = "primary-search-account-menu";
  const isMenuOpen = Boolean(anchorEl);
  // const [open, setOpen] = useState(null);
  const theme = useTheme();

  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const location = useLocation();

  //analytics
  const [claimUser, setClaimUser] = useState(null);
  const loadUsuarioClaims = async () => {
    let resultToken = auth.currentUser
      .getIdTokenResult()
      .then((getIdTokenResult) => {
        setClaimUser(getIdTokenResult.claims);
      });
  };

  const [open, setOpen] = useState(isBrowser ? false : false);

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }
  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }
  async function handleCerrarSession() {
    setAnchorEl(null);
    await cerrarSesion();
    handleMobileMenuClose();
  }

  const handleDrawerOpen = () => {
    setOpen(!open);
    // if (openAccesos === true) {
    //   setOpenAccesos(false);
    // }
    // if (openAccesosAdministracion === true) {
    //   setOpenAccesosAdministracion(false);
    // }
    // if (openNoticas === true) {
    //   setOpenNoticias(false);
    // }
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  function cerrarMenuConTodo() {
    handleDrawerClose();
    // handleClickClose();
    // handleClickCloseAdministracion();
  }

  React.useEffect(() => {
    loadUsuarioClaims();
  }, []);

  React.useEffect(() => {
    console.log(fichas);
  }, [fichas]);

  const renderMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={menuId}
        transformOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleCerrarSession}>Cerrar Sesión</MenuItem>
      </Menu>
    );
  };

  const GlobalCss = withStyles({
    // @global is handled by jss-plugin-global.
    "@global": {
      // You should target [class*="MuiButton-root"] instead if you nest themes.
      ".Mui-selected": {
        borderRadius: "0 25px 25px 0",
      },
      ".MuiListItem-button:hover ": {
        borderRadius: "0 25px 25px 0",
      },
    },
  })(() => null);

  const renderAppMobile = () => {
    return (
      <Fragment>
        <GlobalCss />
        <div className={classes.root}>
          <MaterialAppBar
            position="fixed"
            elevation={0}
            className={clsx(classes.appBar, { [classes.appBarShift]: open })}
            width="100%"
          >
            <Box boxShadow={0}>
              <Toolbar
                variant="dense"
                style={{ backgroundColor: "#FFFFFF", paddingLeft: "18px" }}
              >
                <IconButton
                  // color="black"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton)}
                >
                  <MenuIcon />
                </IconButton>
                <div>
                  <Link to="/">
                    <img
                      className={classes.img_cyd}
                      src={logo_cydocs_color}
                      alt="CyDocs"
                    />
                  </Link>
                </div>
                <TextField
                  id="filled-name"
                  className="inputRounded"
                  style={{ borderRadius: "50px", backgroundColor: "white", marginLeft: "10px", width: "500px" }}
                  label="Buscar"
                  size="small"
                  // button
                  // component={Link}
                  // to={Routes.HOME}
                  // className={classes.nested}

                  // style={{ marginLeft: "10px", borderRadius: "50px" }}
                  variant="outlined"
                  // disabled={mostrarBuscar}
                  // value={name}
                  onChange={(event) => {
                    handleSearchServicio(event.target.value.toLocaleLowerCase());
                  }}
                />



                {/* ESPACIO DE SEPARACIÓN */}
                <div className={classes.grow} />

                <Grid
                  container
                  direction="row"
                  justify={"flex-end"}
                  alignItems="center"
                  style={{
                    paddingRight: 10,
                  }}
                >
                  <Typography
                    // className={classes.titleNoMargin}

                    variant="caption"
                    gutterBottom
                    color="inherit"
                    style={{
                      fontSize: "0.75rem",
                      color: "black",
                      paddingTop: "10px",
                    }}
                  >
                    {/* Formatos */}
                    <a
                      href="https://drive.google.com/drive/u/0/folders/0AAvhz91idy1aUk9PVA"
                      target="_blank"
                      style={{ color: "#731f1f", textDecoration: "none" }}

                    >
                      <strong>Formatos</strong>
                    </a>
                    &nbsp; &nbsp;|&nbsp; &nbsp;
                    {/* MiCYD */}
                    <a
                      href="https://micyd.cl/"
                      target="_blank"
                      style={{ color: "#731f1f", textDecoration: "none" }}

                    >
                      <strong>MiCyD </strong>
                    </a>
                    &nbsp; &nbsp;|&nbsp; &nbsp;


                    {/* Web CyD */}
                    <a
                      href="https://cydingenieria.cl/"
                      target="_blank"
                      style={{
                        color: "#731f1f",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    // onClick={handleClickLinkedIn}
                    >
                      <strong>Web CyD</strong>
                    </a>

                    &nbsp; &nbsp;|&nbsp; &nbsp;
                    {/* CUASIACCIDENTE */}
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLScas67IjKmML3FSUovKetPrh9Wm83kmuxXncaPkhZu7xPb90Q/viewform"
                      target="_blank"
                      style={{
                        color: "#731f1f",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}

                    >
                      <strong>Reporta Cuasi-Accidentes</strong>
                    </a>

                    &nbsp; &nbsp;|&nbsp; &nbsp;

                    {/* CANAL DE DENUNCIAS */}
                    <a
                      href="https://docs.google.com/forms/d/e/1FAIpQLSe57v4pb4QeORTVTnoH6EUs8YEw1vglMJ8CeNScs6aqWJKILA/viewform"
                      target="_blank"
                      style={{
                        color: "#731f1f",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}

                    >
                      <strong>Formulario de Ayuda</strong>
                    </a>
                  </Typography>
                </Grid>



                {/* BOTÓN PARA CREAR MAPA DE PROCESOS */}
                {/* <Button component={Link} to={Routes.CREARMAPA} disabled={location.pathname === Routes.CREARMAPA} variant="contained" color="primary" style={{ paddingLeft: 10, paddingRight: 10 }}>
                  Crear Mapa de Procesos
                </Button> */}
                {/* 
                <div style={{ paddingLeft: "10px", marginTop:"10px" }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Gerencia</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={age}
                      label="Age"
                      onChange={handleChange}
                    >a
                      <MenuItem value={10}>GMI</MenuItem>
                      <MenuItem value={20}>GOP</MenuItem>
                      <MenuItem value={30}>GAF</MenuItem>
                    </Select>
                  </FormControl>
                </div> */}

                <div className={classes.sectionDesktop}>
                  {/* <Divider
                    orientation="vertical"
                    style={{
                      height: "3rem",
                      marginTop: "0.5rem",
                      marginRight: "1rem",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  /> */}
                  {isBrowser && (
                    <Grid container direction="column">
                      <div className={classes.contenedorUser}>
                        <Grid item xs>
                          <Typography
                            className={classes.titleNoMargin}
                            variant="body2"
                            gutterBottom
                            color="inherit"
                            style={{ color: "black" }}
                          >
                            Bienvenido
                          </Typography>
                          <div style={{ whiteSpace: "nowrap" }}>
                            <Box
                              component="div"
                              textOverflow="ellipsis"
                              overflow="hidden"
                            >
                              <Typography
                                className={classes.titleBold}
                                variant="body1"
                                color="inherit"
                                style={{ color: "black" }}
                              >
                                {usuarioSesion ? usuarioSesion.nombre : ""}
                              </Typography>
                            </Box>
                          </div>
                        </Grid>
                      </div>
                    </Grid>
                  )}
                  <IconButton
                    edge="end"
                    aria-label="Account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                  >
                    <Avatar
                      alt="Remy Sharp"
                      src={usuarioSesion ? usuarioSesion.photoUrl : ""}
                      className={classes.bigAvatar}
                    />
                  </IconButton>
                </div>
              </Toolbar>
            </Box>
          </MaterialAppBar>
          {renderMenu()}
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div className={classes.toolbar}>
              <IconButton onClick={cerrarMenuConTodo}>
                {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </div>

            <ListItem
              button
              component={Link}
              to={Routes.SGI}
              className={classes.nested}
            >
              <ListItemIcon>
                <Tooltip title="Home SGI" placement="right">
                  <Home color="inherit" />
                </Tooltip>
              </ListItemIcon>
              <ListItemText>
                Home SGI
              </ListItemText>
            </ListItem>

            <ListItem
              button
              component={Link}
              to={Routes.HOME}
              className={classes.nested}
            >
              <ListItemIcon>
                <Tooltip title="Mapas BPM" placement="right">
                  <AccountTree color="inherit" />
                </Tooltip>
              </ListItemIcon>
              <ListItemText>
                Mapas
              </ListItemText>
            </ListItem>




            <Divider />
            {permisos && permisos[Permissions.VER_AJUSTES] && (
              <ListItem
                button
                component={Link}
                to={Routes.AJUSTES}
                className={classes.nested}
              // onClick={handleSearchServicio(null)}
              >
                <ListItemIcon>
                  <Tooltip title="Editar Mapas" placement="right">
                    <PostAdd color="inherit" />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText>
                  Editar Mapas
                </ListItemText>
              </ListItem>
            )}
            {permisos && permisos[Permissions.VER_PERMISOS] && (
              <ListItem
                button
                component={Link}
                to={Routes.PERMISOS}
                className={classes.nested}
              // onClick={handleSearchServicio(null)}
              >
                <ListItemIcon>
                  <Tooltip title="Permisos" placement="right">

                    <HowToReg color="inherit" />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText>
                  Permisos
                </ListItemText>
              </ListItem>
            )}
            {/* AJUSTES */}
            {/* {permisos.includes(Permissions.AJUSTES_VER) && (
              <ListItem button>
                <ListItemIcon>
                  <SettingsIcon color="inherit" />
                </ListItemIcon>
                <ListItemText>
                  Ajustes
                </ListItemText>
              </ListItem>
            )} */}
          </Drawer>

        </div>
      </Fragment>
    );
  };

  return renderAppMobile();
}

const drawerWidth = 265;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer,
  },
  appBarShift: {
    width: `calc(100%px)`,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: "200",
  },
  drawerOpen: {
    backgroundColor: "#FFFFFF",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: "#FFFFFF",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  sectionDesktop: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  contenedorUser: {
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: "20px",
  },
  titleNoMargin: {
    margin: 0,
    textAlign: "end",
    color: "black",
  },
  titleBold: {
    color: "black",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  button: {
    zIndex: theme.zIndex.drawer + 2,
    margin: theme.spacing(1),
  },
  button_mapa_proceso: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  img: {
    height: "40px",
    marginTop: "12px",
    marginBottom: "12px",
    marginRight: theme.spacing(2),
  },
  img_cyd: {
    height: "60px",
    marginLeft: "0.5rem"
  },
}));