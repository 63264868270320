import React, { Fragment, useEffect } from "react";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import { object, string, array } from "yup";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Close } from "@material-ui/icons";
import MaterialTable from "../../../components/materialTable";
import { ObtenerRoles, AgregarRol, ActualizarRol, EliminarRol } from "./requestRoles";
import { ObtenerPerfiles } from "./requestPerfiles";

function TabRoles(props) {
  const { data: roles, error: errorRoles, mutate: mutateRoles } = useSWR("roles", (key) => ObtenerRoles(), { revalidateOnFocus: false });
  const { data: perfiles, error: errorPerfiles, mutate: mutatePerfiles } = useSWR("perfiles", (key) => ObtenerPerfiles(), { revalidateOnFocus: false });

  const notistack = useSnackbar();

  useEffect(() => {
    if (errorRoles) {
      notistack.enqueueSnackbar("Error al intentar obtener los roles.", {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
      });
    }
    if (errorPerfiles) {
      notistack.enqueueSnackbar("Error al intentar obtener los perfiles.", {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
      });
    }
  }, [errorRoles, errorPerfiles]);

  const formik = useFormik({
    initialValues: {
      nombre: "",
      descripcion: "",
      _perfiles_ref: [],
    },
    validationSchema: object().shape({
      nombre: string()
        .min(3, "El nombre debe tener al menos ${min} caracteres.")
        .max(50, "El nombre debe tener a los más ${max} caracteres.")
        .required("El nombre es requerido."),
      descripcion: string()
        .min(3, "La descripción debe tener al menos ${min} caracteres.")
        .max(50, "La descripción debe tener a los más ${max} caracteres.")
        .required("La descripción es requerida."),
      _perfiles_ref: array()
        .of(string())
        .optional(),
    }),
    onSubmit: (values, helper) => handleAceptar(values),
    enableReinitialize: true,
  });

  /**
   * Handler para agregar un nuevo rol.
   */
  const handleAgregar = () => {
    formik.setFieldValue("tipo", "agregar");
  }

  /**
   * Handler para actualizar un rol.
   * @param {*} event Evento.
   * @param {*} row Datos.
   */
  const handleActualizar = (event, row) => {
    formik.setValues(row);
    formik.setFieldValue("tipo", "actualizar");
  }

  /**
   * Handler para eliminar un rol.
   * @param {*} event Evento.
   * @param {*} row Datos.
   */
  const handleEliminar = (event, row) => {
    formik.setValues(row);
    formik.setFieldValue("tipo", "eliminar");
  }

  /**
   * Handler para aceptar la acción.
   * @param {*} values 
   */
  const handleAceptar = async (values) => {
    try {
      switch (values.tipo) {
        case "agregar":
          await AgregarRol(values);
          break;
        case "actualizar":
          await ActualizarRol(values);
          break;
        case "eliminar":
          await EliminarRol(values);
          break;
        default:
          throw new Error("Acción no especificada.")
      }
      notistack.enqueueSnackbar("Acción realizada exitosamente.", {
        variant: "success",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
      });
    } catch (error) {
      console.error(error);
      notistack.enqueueSnackbar("Error al intentar realizar la acción.", {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
      });
    } finally {
      handleCancelar();
    }
  }

  const handleCancelar = () => {
    mutateRoles();
    mutatePerfiles();
    formik.resetForm();
  }

  let columns = [
    {
      title: "Nombre",
      field: "nombre",
      defaultSort: 'asc'
    }, {
      title: "Descripción",
      field: "descripcion",
    }
  ];

  let actions = [
    {
      tooltip: "Agregar",
      icon: "add",
      onClick: () => handleAgregar(),
      isFreeAction: true,
    }, {
      tooltip: "Editar",
      icon: "edit",
      onClick: handleActualizar,
    }, {
      tooltip: "Eliminar",
      icon: "delete",
      onClick: handleEliminar,
    }
  ];

  return (
    <Fragment>
      <MaterialTable
        title="Roles"
        is_loading={!roles}
        data={roles && roles.data}
        columns={columns}
        actions={actions}
      />
      <Dialog open={Boolean(formik.values.tipo)} maxWidth="md" fullWidth>
        <DialogTitle>
          {formik.values.tipo === "agregar" && "Agregar Rol"}
          {formik.values.tipo === "actualizar" && "Actualizar Rol"}
          {formik.values.tipo === "eliminar" && "Eliminar Rol"}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>Formulario con información del Rol.</DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Nombre"
                name="nombre"
                value={formik.values.nombre}
                onChange={formik.handleChange}
                variant="outlined"
                fullWidth
                disabled={formik.values.tipo === "eliminar"}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Descripción"
                name="descripcion"
                value={formik.values.descripcion}
                onChange={formik.handleChange}
                variant="outlined"
                fullWidth
                disabled={formik.values.tipo === "eliminar"}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={perfiles ? perfiles.data : []}
                value={perfiles ? Array.from(perfiles.data).filter(p => formik.values._perfiles_ref.includes(p._id)) : []}
                getOptionLabel={(perfil) => perfil.nombre}
                disabled={formik.values.tipo === "eliminar"}
                onChange={(event, value) => {
                  formik.setFieldValue("_perfiles_ref", value.map(v => v._id));
                }}
                renderInput={(params) => (
                  <TextField
                    label="Perfiles"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelar} variant="outlined" color="primary">
            Cancelar
          </Button>
          <Button onClick={formik.submitForm} variant="contained" color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default TabRoles;