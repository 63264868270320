import React, { Fragment, Label, useEffect } from "react";
import useSWR from "swr";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import { object, string, array } from "yup";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, TextField } from "@material-ui/core";
import { Close, GetApp, Visibility } from "@material-ui/icons";
// import { ObtenerClientes, AgregarCliente, ActualizarCliente, EliminarCliente } from "../../../../services/request/mapaProcesos/requestClientes";
// import { ObtenerEntregables } from "../../../../services/request/mapaProcesos/requestEntregables";
import { ObtenerServicios, ActualizarServicio } from "../../services/request/mapaProcesos/requestServicio";
import { ObtenerMacroprocesos, ActualizarMacroproceso } from "../../services/request/mapaProcesos/requestMacroproceso";
import { ObtenerFichas, ActualizarFicha } from "../../services/request/mapaProcesos/requestFicha";
import { ObtenerProveedores, ActualizarProveedor } from "../../services/request/mapaProcesos/requestProveedoresInformacion";
import { ObtenerProcAsocs, ActualizarProcAsoc } from "../../services/request/mapaProcesos/requestProcedimientoAsociado";
import { ObtenerClientes, ActualizarCliente } from "../../services/request/mapaProcesos/requestClientes";



// import { ObtenerEntradas, ActualizarEntrada } from "../../services/request/mapaProcesos/requestEntrada";


import { Autocomplete } from "@material-ui/lab";
// import { ObtenerGerencias } from "../../../../services/request/proyectos/requestGerencias"

function DialogAsignacion(props) {

    const {
        titulo,
        _id_,
        setOpenAsignacion,
        mutate,

    } = props;

    const { data: servicios, error: errorServicios, mutate: mutateServicios } = useSWR("servicios", (key) => ObtenerServicios(), { revalidateOnFocus: false });
    const { data: macroprocesos, error: errorMacroprocesos, mutate: mutateMacroprocesos } = useSWR("macroprocesos", (key) => ObtenerMacroprocesos(), { revalidateOnFocus: false });
    const { data: fichas, error: errorFichas, mutate: mutateFichas } = useSWR("fichas", (key) => ObtenerFichas(), { revalidateOnFocus: false });
    const { data: proveedoresInformacion, error: errorProveedores, mutate: mutateProveedores } = useSWR("proveedores_informacion", (key) => ObtenerProveedores(), { revalidateOnFocus: false });
    // const { data: entradas, error: errorEntradas, mutate: mutateEntradas } = useSWR("entradas", (key) => ObtenerEntradas(), { revalidateOnFocus: false });
    const { data: procedimientosAsociados, error: errorProcAsoc, mutate: mutateProcAsoc } = useSWR("procedimientos_asociados", (key) => ObtenerProcAsocs(), { revalidateOnFocus: false });
    const { data: clientes, error: errorClientes, mutate: mutateClientes } = useSWR("clientes", (key) => ObtenerClientes(), { revalidateOnFocus: false });


    // const { data: entregables, error: errorEntregables, mutate: mutateEntregables } = useSWR("entregables", (key) => ObtenerEntregables(), { revalidateOnFocus: false });
    // const { data: gerencias, error: errorGerencias, mutate: mutateGerencias } = useSWR("gerencias", (key) => ObtenerGerencias(), { revalidateOnFocus: false });
    const [openPrev, setOpenPrev] = React.useState(false);
    const [listaAsignados, setListaAsignados] = React.useState(null);
    const [arrayServiciosFiltrados, setArrayServiciosFiltrados] = React.useState(null);
    const [listadoAutocomplete, setListadoAutocomplete] = React.useState(null);
    const [tituloHijo, setTituloHijo] = React.useState(null);
    const [tituloPadre, setTituloPadre] = React.useState(null);
    const [habilitarBoton, setHabilitarBoton] = React.useState(true);

    const notistack = useSnackbar();

    useEffect(() => {
        if (errorServicios) {
            notistack.enqueueSnackbar("Error al intentar obtener los Contratos.", {
                variant: "error",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        }
        // if (errorEntregables) {
        //     notistack.enqueueSnackbar("Error al intentar obtener los entregables.", {
        //         variant: "error",
        //         anchorOrigin: {
        //             horizontal: "center",
        //             vertical: "bottom"
        //         },
        //         action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
        //     });
        // }
    }, [errorServicios]);

    const formik = useFormik({
        initialValues: {
            _id: "",
            _macroprocesos_ref: [],
            tipo_: "actualizar",
        },
        validationSchema: object().shape({
            _id: string(),
            _macroprocesos_ref: array()
                .of(string())
                .optional(),

        }),
        onSubmit: (values, helper) => handleAceptar(values),
        enableReinitialize: true,
    });


    /**
     * Handler para aceptar la acción.
     * @param {*} values 
     */
    const handleAceptar = async (values) => {
        console.log(values);
        const arrayContenido = [];
        const arraySeleccionado = [];
        const arrayContenidoObjetos = [];

        // handleActualizar(values);
        try {
            switch (values.tipo_) {

                case "actualizar":

                    switch (titulo) {
                        case "Ficha":
                            macroprocesos.data.forEach(element => {
                                if (Boolean(Array.from(element._fichas_ref).find((v) => v === _id_))) {
                                    arrayContenido.push(element._id);
                                    arrayContenidoObjetos.push(element);
                                }
                            });
                            if (listaAsignados != null) {
                                listaAsignados.forEach(element => {
                                    arraySeleccionado.push(element._id)
                                    if (arrayContenido.includes(element._id)) {
                                        console.log("encontrado");
                                    } else {
                                        const array = [...element._fichas_ref, _id_]
                                        const valores = [{ "_id": element._id, "_fichas_ref": array }];
                                        ActualizarMacroproceso(valores[0]);
                                    }
                                });
                                arrayContenidoObjetos.forEach(element => {
                                    if (arraySeleccionado.includes(element._id)) {
                                    } else {
                                        const arrayDatoEliminado = _.remove(Array.from(element._fichas_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        const valores_ = [{ "_id": element._id, "_fichas_ref": arrayDatoEliminado }];
                                        ActualizarMacroproceso(valores_[0]);
                                    }
                                });
                            }else{
                                arrayContenidoObjetos.forEach(element => {                                  
                                        const arrayDatoEliminado = _.remove(Array.from(element._fichas_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        const valores_ = [{ "_id": element._id, "_fichas_ref": arrayDatoEliminado }];
                                        ActualizarMacroproceso(valores_[0]);                                    
                                });
                            }
                            break;

                        case "Macroproceso":

                            servicios.data.forEach(element => {
                                if (Boolean(Array.from(element._macroprocesos_ref).find((v) => v === _id_))) {
                                    arrayContenido.push(element._id);
                                    arrayContenidoObjetos.push(element);
                                }
                            });

                            if (listaAsignados != null) {
                                listaAsignados.forEach(element => {

                                    arraySeleccionado.push(element._id)

                                    if (arrayContenido.includes(element._id)) {

                                        console.log("encontrado");

                                    } else {

                                        const array = [...element._macroprocesos_ref, _id_]
                                        const valores = [{ "_id": element._id, "_macroprocesos_ref": array }];
                                        ActualizarServicio(valores[0]);

                                    }
                                });

                                arrayContenidoObjetos.forEach(element => {
                                    if (arraySeleccionado.includes(element._id)) {

                                    } else {
                                        console.log(element._macroprocesos_ref);
                                        const arrayDatoEliminado = _.remove(Array.from(element._macroprocesos_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        console.log(arrayDatoEliminado);

                                        const valores_ = [{ "_id": element._id, "_macroprocesos_ref": arrayDatoEliminado }];
                                        ActualizarServicio(valores_[0]);

                                    }
                                });
                            }else{

                                arrayContenidoObjetos.forEach(element => {
                                  
                                        const arrayDatoEliminado = _.remove(Array.from(element._macroprocesos_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        const valores_ = [{ "_id": element._id, "_macroprocesos_ref": arrayDatoEliminado }];
                                        ActualizarServicio(valores_[0]);
                                    
                                });
                            }
                            break;

                        case "ProveedorInformacion":
                            fichas.data.forEach(element => {
                                if (Boolean(Array.from(element._proveedoresInformacion_ref).find((v) => v === _id_))) {
                                    arrayContenido.push(element._id);
                                    arrayContenidoObjetos.push(element);
                                }
                            });
                            if (listaAsignados != null) {
                                listaAsignados.forEach(element => {
                                    arraySeleccionado.push(element._id)
                                    if (arrayContenido.includes(element._id)) {
                                        console.log("encontrado");
                                    } else {
                                        const array = [...element._proveedoresInformacion_ref, _id_]
                                        const valores = [{ "_id": element._id, "_proveedoresInformacion_ref": array }];
                                        ActualizarFicha(valores[0]);
                                    }
                                });
                                arrayContenidoObjetos.forEach(element => {
                                    if (arraySeleccionado.includes(element._id)) {
                                    } else {
                                        const arrayDatoEliminado = _.remove(Array.from(element._proveedoresInformacion_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        const valores_ = [{ "_id": element._id, "_proveedoresInformacion_ref": arrayDatoEliminado }];
                                        ActualizarFicha(valores_[0]);
                                    }
                                });
                            }else{
                                arrayContenidoObjetos.forEach(element => {                                  
                                        const arrayDatoEliminado = _.remove(Array.from(element._proveedoresInformacion_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        const valores_ = [{ "_id": element._id, "_proveedoresInformacion_ref": arrayDatoEliminado }];
                                        ActualizarFicha(valores_[0]);                                    
                                });
                            }
                            break;                           

                        case "Entrada_ProveedorInformacion":

                            proveedoresInformacion.data.forEach(element => {
                                if (Boolean(Array.from(element._entradas_ref).find((v) => v === _id_))) {
                                    arrayContenido.push(element._id);
                                    arrayContenidoObjetos.push(element);
                                }
                            });
                            if (listaAsignados != null) {
                                listaAsignados.forEach(element => {
                                    arraySeleccionado.push(element._id)
                                    if (arrayContenido.includes(element._id)) {
                                        console.log("encontrado");
                                    } else {
                                        const array = [...element._entradas_ref, _id_]
                                        const valores = [{ "_id": element._id, "_entradas_ref": array }];
                                        ActualizarProveedor(valores[0]);
                                    }
                                });
                                arrayContenidoObjetos.forEach(element => {
                                    if (arraySeleccionado.includes(element._id)) {
                                    } else {
                                        const arrayDatoEliminado = _.remove(Array.from(element._entradas_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        const valores_ = [{ "_id": element._id, "_entradas_ref": arrayDatoEliminado }];
                                        ActualizarProveedor(valores_[0]);
                                    }
                                });
                            }else{
                                arrayContenidoObjetos.forEach(element => {                                  
                                        const arrayDatoEliminado = _.remove(Array.from(element._entradas_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        const valores_ = [{ "_id": element._id, "_entradas_ref": arrayDatoEliminado }];
                                        ActualizarProveedor(valores_[0]);                                    
                                });
                            }
                            break;                               

                        case "InvolucradoInterno":

                            fichas.data.forEach(element => {
                                if (Boolean(Array.from(element._involucrado_asociado_interno_ref).find((v) => v === _id_))) {
                                    arrayContenido.push(element._id);
                                    arrayContenidoObjetos.push(element);
                                }
                            });
                            if (listaAsignados != null) {
                                listaAsignados.forEach(element => {
                                    arraySeleccionado.push(element._id)
                                    if (arrayContenido.includes(element._id)) {
                                        console.log("encontrado");
                                    } else {
                                        const array = [...element._involucrado_asociado_interno_ref, _id_]
                                        const valores = [{ "_id": element._id, "_involucrado_asociado_interno_ref": array }];
                                        ActualizarFicha(valores[0]);
                                    }
                                });
                                arrayContenidoObjetos.forEach(element => {
                                    if (arraySeleccionado.includes(element._id)) {
                                    } else {
                                        const arrayDatoEliminado = _.remove(Array.from(element._involucrado_asociado_interno_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        const valores_ = [{ "_id": element._id, "_involucrado_asociado_interno_ref": arrayDatoEliminado }];
                                        ActualizarFicha(valores_[0]);
                                    }
                                });
                            }else{
                                arrayContenidoObjetos.forEach(element => {                                  
                                        const arrayDatoEliminado = _.remove(Array.from(element._involucrado_asociado_interno_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        const valores_ = [{ "_id": element._id, "_involucrado_asociado_interno_ref": arrayDatoEliminado }];
                                        ActualizarFicha(valores_[0]);                                    
                                });
                            }
                            break;                            

                        case "InvolucradoExterno":

                            fichas.data.forEach(element => {
                                if (Boolean(Array.from(element._involucrado_asociado_externo_ref).find((v) => v === _id_))) {
                                    arrayContenido.push(element._id);
                                    arrayContenidoObjetos.push(element);
                                }
                            });
                            if (listaAsignados != null) {
                                listaAsignados.forEach(element => {
                                    arraySeleccionado.push(element._id)
                                    if (arrayContenido.includes(element._id)) {
                                        console.log("encontrado");
                                    } else {
                                        const array = [...element._involucrado_asociado_externo_ref, _id_]
                                        const valores = [{ "_id": element._id, "_involucrado_asociado_externo_ref": array }];
                                        ActualizarFicha(valores[0]);
                                    }
                                });
                                arrayContenidoObjetos.forEach(element => {
                                    if (arraySeleccionado.includes(element._id)) {
                                    } else {
                                        const arrayDatoEliminado = _.remove(Array.from(element._involucrado_asociado_externo_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        const valores_ = [{ "_id": element._id, "_involucrado_asociado_externo_ref": arrayDatoEliminado }];
                                        ActualizarFicha(valores_[0]);
                                    }
                                });
                            }else{
                                arrayContenidoObjetos.forEach(element => {                                  
                                        const arrayDatoEliminado = _.remove(Array.from(element._involucrado_asociado_externo_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        const valores_ = [{ "_id": element._id, "_involucrado_asociado_externo_ref": arrayDatoEliminado }];
                                        ActualizarFicha(valores_[0]);                                    
                                });
                            }
                            break;                               

                        case "Documentos_Asociados":

                            fichas.data.forEach(element => {
                                if (Boolean(Array.from(element._documentosAsociados_ref).find((v) => v === _id_))) {
                                    arrayContenido.push(element._id);
                                    arrayContenidoObjetos.push(element);
                                }
                            });
                            if (listaAsignados != null) {
                                listaAsignados.forEach(element => {
                                    arraySeleccionado.push(element._id)
                                    if (arrayContenido.includes(element._id)) {
                                        console.log("encontrado");
                                    } else {
                                        const array = [...element._documentosAsociados_ref, _id_]
                                        const valores = [{ "_id": element._id, "_documentosAsociados_ref": array }];
                                        ActualizarFicha(valores[0]);
                                    }
                                });
                                arrayContenidoObjetos.forEach(element => {
                                    if (arraySeleccionado.includes(element._id)) {
                                    } else {
                                        const arrayDatoEliminado = _.remove(Array.from(element._documentosAsociados_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        const valores_ = [{ "_id": element._id, "_documentosAsociados_ref": arrayDatoEliminado }];
                                        ActualizarFicha(valores_[0]);
                                    }
                                });
                            }else{
                                arrayContenidoObjetos.forEach(element => {                                  
                                        const arrayDatoEliminado = _.remove(Array.from(element._documentosAsociados_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        const valores_ = [{ "_id": element._id, "_documentosAsociados_ref": arrayDatoEliminado }];
                                        ActualizarFicha(valores_[0]);                                    
                                });
                            }
                            break;     
                            
                        case "ProcedimientoAsociado":

                            fichas.data.forEach(element => {
                                if (Boolean(Array.from(element._procedimientosAsociados_ref).find((v) => v === _id_))) {
                                    arrayContenido.push(element._id);
                                    arrayContenidoObjetos.push(element);
                                }
                            });
                            if (listaAsignados != null) {
                                listaAsignados.forEach(element => {
                                    arraySeleccionado.push(element._id)
                                    if (arrayContenido.includes(element._id)) {
                                        console.log("encontrado");
                                    } else {
                                        const array = [...element._procedimientosAsociados_ref, _id_]
                                        const valores = [{ "_id": element._id, "_procedimientosAsociados_ref": array }];
                                        ActualizarFicha(valores[0]);
                                    }
                                });
                                arrayContenidoObjetos.forEach(element => {
                                    if (arraySeleccionado.includes(element._id)) {
                                    } else {
                                        const arrayDatoEliminado = _.remove(Array.from(element._procedimientosAsociados_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        const valores_ = [{ "_id": element._id, "_procedimientosAsociados_ref": arrayDatoEliminado }];
                                        ActualizarFicha(valores_[0]);
                                    }
                                });
                            }else{
                                arrayContenidoObjetos.forEach(element => {                                  
                                        const arrayDatoEliminado = _.remove(Array.from(element._procedimientosAsociados_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        const valores_ = [{ "_id": element._id, "_procedimientosAsociados_ref": arrayDatoEliminado }];
                                        ActualizarFicha(valores_[0]);                                    
                                });
                            }
                            break;   
                            
                        case "Documentos_ProcedimientoAsociado":


                            procedimientosAsociados.data.forEach(element => {
                                if (Boolean(Array.from(element._documentos_ref).find((v) => v === _id_))) {
                                    arrayContenido.push(element._id);
                                    arrayContenidoObjetos.push(element);
                                }
                            });
                            if (listaAsignados != null) {
                                listaAsignados.forEach(element => {
                                    arraySeleccionado.push(element._id)
                                    if (arrayContenido.includes(element._id)) {
                                        console.log("encontrado");
                                    } else {
                                        const array = [...element._documentos_ref, _id_]
                                        const valores = [{ "_id": element._id, "_documentos_ref": array }];
                                        ActualizarProcAsoc(valores[0]);
                                    }
                                });
                                arrayContenidoObjetos.forEach(element => {
                                    if (arraySeleccionado.includes(element._id)) {
                                    } else {
                                        const arrayDatoEliminado = _.remove(Array.from(element._documentos_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        const valores_ = [{ "_id": element._id, "_documentos_ref": arrayDatoEliminado }];
                                        ActualizarProcAsoc(valores_[0]);
                                    }
                                });
                            }else{
                                arrayContenidoObjetos.forEach(element => {                                  
                                        const arrayDatoEliminado = _.remove(Array.from(element._documentos_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        const valores_ = [{ "_id": element._id, "_documentos_ref": arrayDatoEliminado }];
                                        ActualizarProcAsoc(valores_[0]);                                    
                                });
                            }
                            break;                              

                        case "KPI":

                            fichas.data.forEach(element => {
                                if (Boolean(Array.from(element._kpi_ref).find((v) => v === _id_))) {
                                    arrayContenido.push(element._id);
                                    arrayContenidoObjetos.push(element);
                                }
                            });
                            if (listaAsignados != null) {
                                listaAsignados.forEach(element => {
                                    arraySeleccionado.push(element._id)
                                    if (arrayContenido.includes(element._id)) {
                                        console.log("encontrado");
                                    } else {
                                        const array = [...element._kpi_ref, _id_]
                                        const valores = [{ "_id": element._id, "_kpi_ref": array }];
                                        ActualizarFicha(valores[0]);
                                    }
                                });
                                arrayContenidoObjetos.forEach(element => {
                                    if (arraySeleccionado.includes(element._id)) {
                                    } else {
                                        const arrayDatoEliminado = _.remove(Array.from(element._kpi_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        const valores_ = [{ "_id": element._id, "_kpi_ref": arrayDatoEliminado }];
                                        ActualizarFicha(valores_[0]);
                                    }
                                });
                            }else{
                                arrayContenidoObjetos.forEach(element => {                                  
                                        const arrayDatoEliminado = _.remove(Array.from(element._kpi_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        const valores_ = [{ "_id": element._id, "_kpi_ref": arrayDatoEliminado }];
                                        ActualizarFicha(valores_[0]);                                    
                                });
                            }
                            break;                             

                        case "Entregable_Clientes":

                            fichas.data.forEach(element => {
                                if (Boolean(Array.from(element._entregableCliente_ref).find((v) => v === _id_))) {
                                    arrayContenido.push(element._id);
                                    arrayContenidoObjetos.push(element);
                                }
                            });
                            if (listaAsignados != null) {
                                listaAsignados.forEach(element => {
                                    arraySeleccionado.push(element._id)
                                    if (arrayContenido.includes(element._id)) {
                                        console.log("encontrado");
                                    } else {
                                        const array = [...element._entregableCliente_ref, _id_]
                                        const valores = [{ "_id": element._id, "_entregableCliente_ref": array }];
                                        ActualizarFicha(valores[0]);
                                    }
                                });
                                arrayContenidoObjetos.forEach(element => {
                                    if (arraySeleccionado.includes(element._id)) {
                                    } else {
                                        const arrayDatoEliminado = _.remove(Array.from(element._entregableCliente_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        const valores_ = [{ "_id": element._id, "_entregableCliente_ref": arrayDatoEliminado }];
                                        ActualizarFicha(valores_[0]);
                                    }
                                });
                            }else{
                                arrayContenidoObjetos.forEach(element => {                                  
                                        const arrayDatoEliminado = _.remove(Array.from(element._entregableCliente_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        const valores_ = [{ "_id": element._id, "_entregableCliente_ref": arrayDatoEliminado }];
                                        ActualizarFicha(valores_[0]);                                    
                                });
                            }
                            break; 

                        case "Entregable_cliente_documento":

                            clientes.data.forEach(element => {
                                if (Boolean(Array.from(element._entregable_ref).find((v) => v === _id_))) {
                                    arrayContenido.push(element._id);
                                    arrayContenidoObjetos.push(element);
                                }
                            });
                            if (listaAsignados != null) {
                                listaAsignados.forEach(element => {
                                    arraySeleccionado.push(element._id)
                                    if (arrayContenido.includes(element._id)) {
                                        console.log("encontrado");
                                    } else {
                                        const array = [...element._entregable_ref, _id_]
                                        const valores = [{ "_id": element._id, "_entregable_ref": array }];
                                        ActualizarCliente(valores[0]);
                                    }
                                });
                                arrayContenidoObjetos.forEach(element => {
                                    if (arraySeleccionado.includes(element._id)) {
                                    } else {
                                        const arrayDatoEliminado = _.remove(Array.from(element._entregable_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        const valores_ = [{ "_id": element._id, "_entregable_ref": arrayDatoEliminado }];
                                        ActualizarCliente(valores_[0]);
                                    }
                                });
                            }else{
                                arrayContenidoObjetos.forEach(element => {                                  
                                        const arrayDatoEliminado = _.remove(Array.from(element._entregable_ref), function (serv) {
                                            return serv != _id_;
                                        })
                                        const valores_ = [{ "_id": element._id, "_entregable_ref": arrayDatoEliminado }];
                                        ActualizarCliente(valores_[0]);                                    
                                });
                            }
                            break;                             

                        default:
                            throw new Error("Acción no especificada.")
                    }
                    break;

                default:
                    throw new Error("Acción no especificada.")
            }
            notistack.enqueueSnackbar("Elemento asignado exitosamente.", {
                variant: "success",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        } catch (error) {
            console.error(error);
            notistack.enqueueSnackbar("Error al intentar realizar la acción.", {
                variant: "error",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        } finally {
            mutate();
            handleCancelar();
        }
    }

    const handleCancelar = () => {
        mutateServicios();
        mutate();
        // mutateEntregables();
        setOpenAsignacion(false);
        formik.resetForm();
    }

    useEffect(() => {
        switch (titulo) {
            case "Macroproceso":
                console.log(titulo);

                if (servicios) {
                    setTituloHijo("Macroprocesos");
                    setTituloPadre("Contrato");
                    setListadoAutocomplete(servicios.data);
                    setArrayServiciosFiltrados(Array.from(servicios.data).filter(p => p._macroprocesos_ref.includes(_id_)))
                }
                break;

            case "Ficha":
                console.log(titulo);

                if (macroprocesos) {
                    setTituloHijo("Fichas");
                    setTituloPadre("Macroproceso");
                    setListadoAutocomplete(macroprocesos.data);
                    setArrayServiciosFiltrados(Array.from(macroprocesos.data).filter(p => p._fichas_ref.includes(_id_)))
                }
                break;

            case "ProveedorInformacion":
                console.log(titulo);

                if (fichas) {
                    setTituloHijo("Proveedores de Información");
                    setTituloPadre("Ficha");
                    setListadoAutocomplete(fichas.data);
                    setArrayServiciosFiltrados(Array.from(fichas.data).filter(p => p._proveedoresInformacion_ref.includes(_id_)))
                }
                break;

            case "Entrada_ProveedorInformacion":
                console.log(titulo);

                if (proveedoresInformacion) {
                    setTituloHijo("Entradas");
                    setTituloPadre("Proveedor de Información");
                    setListadoAutocomplete(proveedoresInformacion.data);
                    setArrayServiciosFiltrados(Array.from(proveedoresInformacion.data).filter(p => p._entradas_ref.includes(_id_)))
                }
                break;

            case "InvolucradoInterno":
                console.log(titulo);

                if (fichas) {

                    setTituloHijo("Involucrados Internos");
                    setTituloPadre("Ficha");
                    setListadoAutocomplete(fichas.data);
                    setArrayServiciosFiltrados(Array.from(fichas.data).filter(p => p._involucrado_asociado_interno_ref.includes(_id_)))
                }
                break;

            case "InvolucradoExterno":
                console.log(titulo);

                if (fichas) {
                    setTituloHijo("Involucrados Externos");
                    setTituloPadre("Ficha");
                    setListadoAutocomplete(fichas.data);
                    setArrayServiciosFiltrados(Array.from(fichas.data).filter(p => p._involucrado_asociado_externo_ref.includes(_id_)))
                }
                break;

            case "Documentos_Asociados":
                console.log(titulo);

                if (fichas) {

                    setTituloHijo("Documentos Asociados");
                    setTituloPadre("Ficha");
                    setListadoAutocomplete(fichas.data);
                    setArrayServiciosFiltrados(Array.from(fichas.data).filter(p => p._documentosAsociados_ref.includes(_id_)))
                }
                break;

            case "ProcedimientoAsociado":
                console.log(titulo);

                if (fichas) {

                    setTituloHijo("Procedimientos Asociados");
                    setTituloPadre("Ficha");
                    setListadoAutocomplete(fichas.data);
                    setArrayServiciosFiltrados(Array.from(fichas.data).filter(p => p._procedimientosAsociados_ref.includes(_id_)))
                }
                break;

            case "Documentos_ProcedimientoAsociado":
                console.log(titulo);

                if (procedimientosAsociados) {
                    setTituloHijo("Documentos");
                    setTituloPadre("Procedimiento Asociado");
                    setListadoAutocomplete(procedimientosAsociados.data);
                    setArrayServiciosFiltrados(Array.from(procedimientosAsociados.data).filter(p => p._documentos_ref.includes(_id_)))
                }
                break;

            case "KPI":
                console.log(titulo);

                if (fichas) {

                    setTituloHijo("Indicadores KPI");
                    setTituloPadre("Ficha");
                    setListadoAutocomplete(fichas.data);
                    setArrayServiciosFiltrados(Array.from(fichas.data).filter(p => p._kpi_ref.includes(_id_)))
                }
                break;

            case "Entregable_Clientes":
                console.log(titulo);

                if (fichas) {
                    setTituloHijo("Entregable/Cliente");
                    setTituloPadre("Ficha");
                    setListadoAutocomplete(fichas.data);
                    setArrayServiciosFiltrados(Array.from(fichas.data).filter(p => p._entregableCliente_ref.includes(_id_)))
                }
                break;

            case "Entregable_cliente_documento":
                console.log(titulo);

                if (clientes) {
                    setTituloHijo("Documentos");
                    setTituloPadre("Cliente");
                    setListadoAutocomplete(clientes.data);
                    setArrayServiciosFiltrados(Array.from(clientes.data).filter(p => p._entregable_ref.includes(_id_)))
                }
                break;
        }


    }, [servicios, macroprocesos, fichas, procedimientosAsociados, proveedoresInformacion, clientes]);

    return (
        <Fragment>

            <Dialog open={Boolean(formik.values.tipo_)} maxWidth="md" fullWidth>
                <DialogTitle>
                    {/* {formik.values.tipo_ === "agregar" && "Agregar Cliente"} */}
                    {formik.values.tipo_ === "actualizar" && `Asignar ${tituloHijo} a ${tituloPadre}`}
                    {/* {formik.values.tipo_ === "eliminar" && "Eliminar Cliente"}
                    {formik.values.tipo_ === "previsualizar" && "Previsualización de Cliente"} */}
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>Seleccione el/la {tituloPadre}.</DialogContentText>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                options={listadoAutocomplete ? listadoAutocomplete : []}
                                value={arrayServiciosFiltrados ? arrayServiciosFiltrados : []}
                                getOptionLabel={(servicio) =>
                                (servicio.nombre && servicio.codigo ?
                                    `[${servicio.codigo}] - ${servicio.nombre}` :
                                    servicio.nombre && servicio.tipo_proceso ?
                                        `[${servicio.tipo_proceso}] - ${servicio.nombre}` :
                                        servicio.cliente && servicio.entregable ?
                                            `[${servicio.cliente}] - ${servicio.entregable}` :
                                            servicio.cargo && servicio.descripcion ?
                                                `[${servicio.cargo}] - ${servicio.descripcion}` :
                                                servicio.codigo && servicio.cliente ?
                                                    `[${servicio.codigo}] - ${servicio.cliente}` :
                                                    servicio.nombre ?
                                                        `${servicio.nombre}` :
                                                        servicio.codigo ?
                                                        `${servicio.codigo}` :
                                                        servicio.codigo && servicio.proveedor ?
                                                        `[${servicio.codigo}] - ${servicio.proveedor}`:
                                                        "No Definido"
                                                        )

                                }
                                // disabled={formik.values.tipo_ === "eliminar"}
                                onChange={(event, value) => {
                                    // formik.setFieldValue("_id", value._id);
                                    setArrayServiciosFiltrados(value && value);
                                    setListaAsignados(value && value);
                                    setHabilitarBoton(false);

                                    // handleActualizar(value && value.map(v => v._id));
                                    // handleActualizar(value && value);
                                    // console.log(value.map(v => v._id));
                                    console.log(value && value);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        label={tituloPadre}
                                        variant="outlined"
                                        {...params}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                </DialogContent>
                {formik.values.tipo_ === "actualizar" ?
                    (
                        <DialogActions>
                            <Button onClick={handleCancelar} variant="outlined" color="primary">
                                Cancelar
                            </Button>
                            <Button onClick={formik.submitForm} variant="contained" color="primary" disabled={habilitarBoton}>
                                Asignar
                            </Button>
                        </DialogActions>
                    ) : (
                        <DialogActions>
                            <Button onClick={handleCancelar} variant="outlined" color="primary" disabled={habilitarBoton}>
                                Cerrar
                            </Button>

                        </DialogActions>
                    )
                }
            </Dialog>


        </Fragment>
    );
}

export default DialogAsignacion;