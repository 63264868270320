import { BACKEND } from "../../../constants/urls";
import Axios from "axios";

/**
 * Método encargado de obtener un perfil, por su ID.
 * @param {*} perfilID ID del perfil.
 */
export async function ObtenerDocumento(perfilID) {
  try {
    let url = `${BACKEND}/documentos/${perfilID}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todos los perfiles.
 */
export async function ObtenerDocumentos() {
  try {
    let url = `${BACKEND}/documentos`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de agregar un nuevo perfil.
 * @param {*} perfil Datos del perfil.
 */
export async function AgregarDocumentos(perfil) {
  try {
    let url = `${BACKEND}/documentos`;
    let response = await Axios.post(url, perfil);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de actualizar un perfil.
 * @param {*} perfil Datos actualizados del perfil.
 */
export async function ActualizarDocumentos(perfil) {
  try {
    let url = `${BACKEND}/documentos/${perfil._id}`;
    let response = await Axios.put(url, perfil);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de eliminar un perfil.
 * @param {*} perfil Datos del perfil.
 */
export async function EliminarDocumentos(perfil) {
  try {
      
    let url = `${BACKEND}/documentos/${perfil._id}`;
    console.log(url);
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}