export const LANDING = '/';
export const HOME = "/";
export const CREARMAPA ="/CrearMapa";
export const AJUSTES="/ajustes";
export const PERMISOS="/permisos";
export const MAPAS = "/mapas-bpm";
export const SGI = "/clasificacion-sgi";

export const GERENTES="/gerentes";

// Noticia de prueba


export const ACCESS_ERROR = '/accessError';