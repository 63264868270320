import axios from 'axios';
import { ADMINISTRACION_USUARIOS } from '../../constants/urls'
import { BACKEND } from "../../constants/urls";
import { auth } from '../../services/firebase';

/**
 * Obtener datos de usuario
 * @param {*} uid filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getUsuarioByUid(uid, cancelToken = null) {
  return axios.get(`${ADMINISTRACION_USUARIOS}/personas/uid/${uid}`,
    {
      cancelToken: cancelToken
    }).then(res => res.data);
}

/**
 * Obtener datos de usuarios. Se incluye filtro para obtener solo usuarios que están en Talana.
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getUsuarios(filter, cancelToken = null) {
  return axios.get(`${ADMINISTRACION_USUARIOS}/personas`,
    {
      params: filter,
      cancelToken: cancelToken
    }).then(res => res.data);
}

/**
 * Obtener datos de usuario
 * @param {*} id 
 * @param {*} cancelToken token para cancelacion
 */
export function getUsuario(id, cancelToken = null) {
  // return axios.get(`${ADMINISTRACION_USUARIOS}/personas/${id}`,

    return axios.get(`${ADMINISTRACION_USUARIOS}/usuarios/?usuario_id=${id}`,

    {
      cancelToken: cancelToken
    }).then(res => res.data);
}

export function getUsuario_contrato(id, cancelToken = null) {
  // return axios.get(`${ADMINISTRACION_USUARIOS}/personas/${id}`,
  console.log(id);

    return axios.get(`https://api-usuarios.cydocs.cl/personas/?usuario_id=${id}`,

    {
      cancelToken: cancelToken
    }).then(res => res);
}

/**
 * Obtener datos de usuario
 * @param {*} uid filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function obtenerDatosSesionUsuario(uid, cancelToken = null) {
  return auth.currentUser.getIdToken(true).then((idToken) => {
    return axios.post(`${BACKEND}/usuarios-plataforma/sesion`,
      {},
      {
        headers: {
          "authorization": `Bearer ${idToken}`
        },
        cancelToken: cancelToken
      }
    );
  })
    .then(res => res.data ? res.data.data : null);
}

/**
 * Método encargado de obtener un usuario desde API Usuarios, por su ID.
 * @param {*} usuarioID ID del usuario.
 */
export async function ObtenerUsuario(usuarioID) {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/personas/${usuarioID}`;
    let response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}