import React, { Fragment, useContext, useEffect } from "react";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import Moment from "moment";
import { useFormik } from "formik";
import { array, object, string } from "yup";
import { Button, Box, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Link, TextField, Tooltip, Typography } from "@material-ui/core";
import { AssignmentReturn, Check, Close, FiberManualRecord } from "@material-ui/icons";
import MaterialTable from "../../../../components/materialTable";
import { ObtenerEntregables, AgregarEntregable, ActualizarEntregable, EliminarEntregable } from "../../../../services/request/mapaProcesos/requestEntregables";
import { Autocomplete } from "@material-ui/lab";
import { ObtenerGerencias } from "../../../../services/request/proyectos/requestGerencias";
import { ObtenerFichas } from "../../../../services/request/mapaProcesos/requestFicha";
import { ObtenerClientes } from "../../../../services/request/mapaProcesos/requestClientes";
import { MainContext } from "../../../../App";

import FileInput from "../../../../components/fileInput";
import { uploadFileToCloudStorage } from "../../../../services/fileUpload";
import DialogAsignacion from "../../../../components/DialogAsignar";


function TabEntradas(props) {
    const { data, error, mutate } = useSWR("entregables", (key) => ObtenerEntregables(), { revalidateOnFocus: false });
    const { data: gerencias, error: errorGerencias, mutate: mutateGerencias } = useSWR("gerencias", (key) => ObtenerGerencias(), { revalidateOnFocus: false });
    const { data: fichas, error: errorFichas, mutate: mutateFichas } = useSWR("fichas", (key) => ObtenerFichas(), { revalidateOnFocus: false });
    const { data: clientes, error: errorClientes, mutate: mutateClientes } = useSWR("clientes", (key) => ObtenerClientes(), { revalidateOnFocus: false });

    const { usuarioSesion } = useContext(MainContext);

    const notistack = useSnackbar();

    const [openAsignacion, setOpenAsignacion] = React.useState(false);
    const [_id_, set_id_] = React.useState(null);

    const handleAbrirAsignacion = (event, row) => {
        set_id_(row._id);
        setOpenAsignacion(true);

    }

    useEffect(() => {
        if (error) {
            notistack.enqueueSnackbar("Error al intentar obtener los permisos.", {
                variant: "error",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        }
    }, [error]);

    const formik = useFormik({
        initialValues: {
            gerencia: "",
            file_documento: null,
            documento: null,
        },
        validationSchema: object().shape({
            gerencia: array()
                .of(string())
                .optional(),
            file_documento: object()
                .nullable()
                .optional(),
            documento: object()
                .nullable()
                .optional(),

        }),
        onSubmit: async function (values, helper) {

            if (values.file_documento) {
                notistack.enqueueSnackbar("Subiendo documento entregable a la nube...", {
                    anchorOrigin: {
                        horizontal: "center",
                        vertical: "bottom"
                    },
                });
                const archivo = await uploadFileToCloudStorage(values.file_documento, `entregable_cliente`, values.file_documento.name);
                values.documento = archivo;
            }
            handleAceptar(values)
        },
        enableReinitialize: true,
    });

    /**
     * Handler para agregar un nuevo rol.
     */
    const handleAgregar = () => {
        formik.setFieldValue("tipo", "agregar");
    }

    /**
     * Handler para actualizar un rol.
     * @param {*} event Evento.
     * @param {*} row Datos.
     */
    const handleActualizar = (event, row) => {
        formik.setValues(row);
        formik.setFieldValue("tipo", "actualizar");
    }

    /**
     * Handler para eliminar un rol.
     * @param {*} event Evento.
     * @param {*} row Datos.
     */
    const handleEliminar = (event, row) => {
        formik.setValues(row);
        console.log(row);
        formik.setFieldValue("tipo", "eliminar");
    }

    /**
     * Handler para aceptar la acción.
     * @param {*} values 
     */
    const handleAceptar = async (values) => {
        try {
            switch (values.tipo) {
                case "agregar":
                    await AgregarEntregable(values);
                    break;
                case "actualizar":
                    await ActualizarEntregable(values);
                    break;
                case "eliminar":
                    await EliminarEntregable(values);
                    break;
                default:
                    throw new Error("Acción no especificada.")
            }
            notistack.enqueueSnackbar("Acción realizada exitosamente.", {
                variant: "success",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        } catch (error) {
            console.error(error);
            notistack.enqueueSnackbar("Error al intentar realizar la acción.", {
                variant: "error",
                anchorOrigin: {
                    horizontal: "center",
                    vertical: "bottom"
                },
                action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
            });
        } finally {
            handleCancelar();
        }
    }

    const handleCancelar = () => {
        mutate();
        formik.resetForm();
    }

    let columns = [
        {
            title: "Nombre",
            field: "documento.nombre",
            // defaultSort: 'asc'
        }, {
            title: "URL",
            field: "documento.url",
            width: "10%",

            align: 'center',
            render: (row) =>
                <Button
                    variant="contained"
                    color="primary"
                    href={row.documento.url}
                    target="_blank"
                >
                    <Typography variant="h7" style={{ flexBasis: "100%" }}>
                        Link
                    </Typography>
                </Button>
        }, {
            title: "Gerencia",
            align: 'center',
            field: "gerencia",
            width: "12%",


            render: rowData => (
                <div>
                    {rowData.gerencia && rowData.gerencia.map(type => (
                        <Fragment>
                            {gerencias ? Array.from(gerencias).filter(p => type.includes(p.sigla)).map((d, index) => (
                                <Chip key={d.sigla}
                                    size="small"
                                    variant="default"
                                    color="primary"
                                    style={{ margin: 2 }}
                                    label={d.sigla} />
                            )) : <div></div>}
                        </Fragment>
                    ))}
                </div>
            ),
        }, {
            title: "Asignado a",
            // field: "version", 
            align: "center",

            render: rowData => (
                // <div>
                //     {rowData.gerencia.map(type => (
                <Fragment>
                    {clientes ? Array.from(clientes.data).filter(p => p._entregable_ref.includes(rowData._id)).map((d, index) => (
                        <div>
                            <Tooltip title={
                                <div>
                                    Nombre del cliente:
                                    <Box display="flex" alignItems="center" style={{ height: "100%" }}>
                                        <br />
                                        <FiberManualRecord style={{ fontSize: "10px" }} /> {d.cliente}
                                        <br />
                                    </Box>
                                    Cliente asignado a:
                                    {fichas && Array.from(fichas.data).filter(p => p._entregableCliente_ref.includes(d._id)).map((p, index) => (
                                        <Box display="flex" alignItems="center" style={{ height: "100%" }}>
                                            <Check fontSize="small" /> [{p.codigo}]{p.nombre}
                                        </Box>
                                    ))}
                                </div>
                            }>
                                <Chip
                                    key={d.codigo}
                                    size="small"
                                    variant="default"
                                    color="primary"
                                    style={{ margin: 2 }}
                                    label={d.codigo} />
                            </Tooltip>
                        </div>
                    )) : <div></div>}

                </Fragment>
            ),
        },
        {
            title: "Fecha Actualización",
            field: "fecha_actualizacion",
            align: 'center',
            defaultSort: "desc",
            width: "15%",

            render: (row) => Moment(row.fecha_actualizacion).format("DD/MM/YYYY HH:mm"),
        },

    ];

    let actions = [
        {
            tooltip: "Agregar",
            icon: "add",
            onClick: () => handleAgregar(),
            isFreeAction: true,
        }, {
            tooltip: "Editar",
            icon: "edit",
            onClick: handleActualizar,
        },

        {
            tooltip: "Asignar a Cliente",
            icon: () => <AssignmentReturn />,
            onClick: handleAbrirAsignacion,
        },
        {
            tooltip: "Eliminar",
            icon: "delete",
            onClick: handleEliminar,

        }
    ];

    return (
        <Fragment>
            <MaterialTable
                title="Entregables"
                is_loading={!data}
                // data={data && data.data}
                data={data && Array.from(data.data).filter(g => g.gerencia.some(j => usuarioSesion.gerencia_visualizar.includes(j)))
                    .concat(Array.from(data.data).filter(g => Array.from(g.gerencia).length == 0))
                    .concat(Array.from(data.data).filter(g => g.gerencia[0] == ""))}

                columns={columns}
                actions={actions}
            />
            <Dialog open={Boolean(formik.values.tipo)} maxWidth="md" fullWidth>
                <DialogTitle>
                    {formik.values.tipo === "actualizar" && "Actualizar Entregable"}
                    {formik.values.tipo === "agregar" && "Agregar Entregable"}
                    {formik.values.tipo === "eliminar" && "Eliminar Entregable"}
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>Formulario con información del Entregable.</DialogContentText>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <Box display="flex">
                                <FileInput
                                    inputName="file_documento"
                                    label="Documento Entregable"
                                    handleOnChange={(e) => formik.setFieldValue("file_documento", e.target.files[0])}
                                    meta={{ touched: formik.touched.file_documento, error: formik.errors.file_documento }}
                                    accept="image/png, image/jpg, image/jpeg, application/pdf"
                                    buttonColor="primary"
                                    buttonVariant="outlined"
                                    value={formik.values.file_documento}
                                    textVariant="outlined"
                                />
                                {formik.values.flujograma && (
                                    <Box paddingLeft={1} paddingRight={1}>
                                        <Tooltip title="Ver Archivo">
                                            <IconButton component="a" href={formik.values.documento?.url} target="_blank" rel="noreferrer" disabled={!formik.values.documento?.url}>
                                                <Link />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                )}
                            </Box>
                        </Grid>

                        <Grid item xs={12}>

                            <Autocomplete
                                multiple
                                options={gerencias ? gerencias : []}
                                value={formik.values.gerencia && gerencias ? Array.from(gerencias).filter(p => formik.values.gerencia.includes(p.sigla)) : []}
                                getOptionLabel={(gerencia) => gerencia.sigla + " - " + gerencia.nombre}
                                disabled={formik.values.tipo_ === "eliminar"}
                                onChange={(event, value) => {
                                    formik.setFieldValue("gerencia", value.map(v => v.sigla));
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        label="Gerencias"
                                        variant="outlined"
                                        {...params}
                                    />
                                )}
                            />

                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelar} variant="outlined" color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={formik.submitForm} variant="contained" color="primary">
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAsignacion} fullWidth>
                <DialogAsignacion _id_={_id_} titulo="Entregable_cliente_documento" setOpenAsignacion={setOpenAsignacion} mutate={mutateClientes} />
            </Dialog>
        </Fragment>
    );
}

export default TabEntradas;