import React, { useState } from "react";
import { AppBar, Box, makeStyles, Tab, Tabs } from "@material-ui/core";
// import TabUsuariosPlataforma from "./tabUsuariosPlataforma";
// import TabRoles from "./tabRoles";
// import TabPerfiles from "./tabPerfiles";
import TabClientes from "./tabCliente";
import TabEntregables from "./tabEntregable";

function Page(props) {
  const [TabIndex3, SetTabIndex3] = useState(0);

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    SetTabIndex3(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{background:"lightgray"}}>
        <Tabs value={TabIndex3} onChange={handleChange} style={{color:"#731f1f"}}>
          {/* <Tab label="Usuarios Plataforma" />
          <Tab label="Roles" />
          <Tab label="Perfiles" /> */}
          {/* <Tab label="Fichas" /> */}
          <Tab label="Clientes"/>
          <Tab label="Entregables" />
        </Tabs>
      </AppBar>
      {/* <TabPanel value={TabIndex} index={0}>
        <TabUsuariosPlataforma />
      </TabPanel>
      <TabPanel value={TabIndex} index={1}>
        <TabRoles />
      </TabPanel>
      <TabPanel value={TabIndex} index={2}>
        <TabPerfiles />
      </TabPanel> */}
      {/* <TabPanel value={TabIndex} index={0}>
        <TabFicha />
      </TabPanel> */}
      <TabPanel value={TabIndex3} index={0}>
        <TabClientes />
      </TabPanel>
      <TabPanel value={TabIndex3} index={1}>
        <TabEntregables />
      </TabPanel>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,

  },
}));

export default Page;