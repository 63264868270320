import { BACKEND } from "../../../constants/urls";
import Axios from "axios";

/**
 * Método encargado de obtener un perfil, por su ID.
 * @param {*} procAsocID ID del perfil.
 */
export async function ObtenerProcesoAsociado(procAsocID) {
  try {
    let url = `${BACKEND}/proceso-asociado/${procAsocID}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todos los perfiles.
 */
export async function ObtenerProcesosAsociados() {
  try {
    let url = `${BACKEND}/proceso-asociado`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de agregar un nuevo perfil.
 * @param {*} procAsoc Datos del perfil.
 */
export async function AgregarProcesosAsociados(procAsoc) {
  try {
    let url = `${BACKEND}/proceso-asociado`;
    console.log(procAsoc);
    let response = await Axios.post(url, procAsoc);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de actualizar un perfil.
 * @param {*} procAsoc Datos actualizados del perfil.
 */
export async function ActualizarProcesosAsociados(procAsoc) {
  try {
    let url = `${BACKEND}/proceso-asociado/${procAsoc._id}`;
    let response = await Axios.put(url, procAsoc);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de eliminar un perfil.
 * @param {*} procAsoc Datos del perfil.
 */
export async function EliminarProcesosAsociados(procAsoc) {
  try {
    let url = `${BACKEND}/proceso-asociado/${procAsoc._id}`;
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}