import { BACKEND } from "../../../constants/urls";
import Axios from "axios";

/**
 * Método encargado de obtener un perfil, por su ID.
 * @param {*} perfilID ID del perfil.
 */
export async function ObtenerEtiqueta(etiquetaID) {
  try {
    let url = `${BACKEND}/etiqueta/${etiquetaID}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todos los perfiles.
 */
export async function ObtenerEtiquetas() {
  try {
    let url = `${BACKEND}/etiqueta`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de agregar un nuevo perfil.
 * @param {*} perfil Datos del perfil.
 */
export async function AgregarEtiqueta(etiqueta) {
  try {
    let url = `${BACKEND}/etiqueta`;
    console.log(etiqueta);
    let response = await Axios.post(url, etiqueta);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de actualizar un perfil.
 * @param {*} perfil Datos actualizados del perfil.
 */
export async function ActualizarEtiqueta(etiqueta) {
  try {
    let url = `${BACKEND}/etiqueta/${etiqueta._id}`;
    let response = await Axios.put(url, etiqueta);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de eliminar un perfil.
 * @param {*} perfil Datos del perfil.
 */
export async function EliminarEtiqueta(etiqueta) {
  try {
    let url = `${BACKEND}/etiqueta/${etiqueta._id}`;
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}